import { forwardRef, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { Button, closePopup, Popup, showPopup, Switch, Text, TextField, ToastMessage, Winicon } from "wini-web-components"
import { randomGID } from "../../../Utils"
import { Select1Form, TextFieldForm } from "../../../project-component/component-form"
import { TableController } from "../../wini/table/controller"
import { EventStep, FEDataType } from "../../wini/table/da"
import { Reducers } from "../da"

export default function SettingReportColumn({ relatives = [], cols = [], reducers = [], methods, onAdd, onDelete, onChangeReducer }) {
    const popupRef = useRef()
    const [editTitleId, setEditTitleId] = useState()

    const onMouseDown = (ev) => {
        ev.preventDefault()
        const _colTile = ev.target.closest(`.setting-prop-tile`)
        const _tmp = [..._colTile.parentElement.children].sort((a, b) => parseInt(window.getComputedStyle(a).order ?? 0) - parseInt(window.getComputedStyle(b).order ?? 0))
        function onDrag(event) {
            if (_colTile) {
                _colTile.style.display = "none"
                let _demo = document.body.querySelector(`.demo-sort`)
                if (!_demo) {
                    _demo = document.createElement("div")
                    _demo.className = 'demo-sort'
                }
                const _children = _tmp.filter(e => e.id !== _colTile.id && !e.classList.contains('demo-sort'))
                let _order = 0
                let _distance = 0
                let closestHTML = [..._children].sort((aHTML, bHTML) => {
                    let aRect = aHTML.getBoundingClientRect()
                    let bRect = bHTML.getBoundingClientRect()
                    let a_center_oy = Math.abs(event.pageY - (aRect.y + aRect.height / 2))
                    let b_center_oy = Math.abs(event.pageY - (bRect.y + bRect.height / 2))
                    return a_center_oy - b_center_oy
                })[0]
                if (closestHTML) {
                    let htmlRect = closestHTML.getBoundingClientRect()
                    _order = parseInt(window.getComputedStyle(closestHTML).order)
                    _distance = event.pageY - (htmlRect.y + htmlRect.height / 2)
                    if (_distance < 0) _order--
                } else _order = _children.length - 1
                if (_order < 0) _order = 0
                _demo.style.order = _order
                _colTile.style.order = _order
                if (_demo.parentElement !== _colTile.parentElement) _colTile.parentElement.appendChild(_demo)
            }
        }
        document.body.addEventListener('mousemove', onDrag)
        document.body.onmouseup = () => {
            if (_colTile) {
                document.body.querySelector(`.demo-sort`)?.remove()
                _colTile.style.removeProperty("display")
                const _childrenId = [..._colTile.parentElement.children].sort((a, b) => parseInt(window.getComputedStyle(a).order ?? 0) - parseInt(window.getComputedStyle(b).order ?? 0)).map(e => e.id)
                let listProps = [...cols, ...reducers].map(e => {
                    let _index = _childrenId.findIndex(id => e.Id === id)
                    return { key: e.Name, index: _index }
                })
                listProps = listProps.sort((a, b) => a.index - b.index)
                let _oldProps = methods.getValues("Columns")
                let _newProps = {}
                listProps.forEach(e => {
                    _newProps[e.key] = _oldProps[e.key] < 0 ? -1 : e.index
                })
                methods.setValue("Columns", _newProps)
            }
            document.body.removeEventListener('mousemove', onDrag)
            document.body.onmouseup = undefined
        }
    }

    const showAddColGroupPopup = (item) => {
        showPopup({
            ref: popupRef,
            style: { width: "56%" },
            hideButtonClose: true,
            content: <AddColGroup
                ref={popupRef}
                item={item}
                relatives={relatives}
                onSubmit={onAdd}
                existNames={[...cols, ...reducers].map(e => e.Name.toLowerCase())}
            />
        })
    }

    return <div className="col" style={{ gap: 8 }}>
        <Popup ref={popupRef} />
        <Button
            label="Add column"
            className="button-infor border"
            prefix={<Winicon src={"fill/user interface/e-add"} size={"1.4rem"} />}
            onClick={() => { showAddColGroupPopup() }}
        />
        <div className="col">
            {cols.map((_col, i) => {
                return <div key={_col.Id} id={_col.Id} className="row setting-prop-tile" style={{ gap: 8, padding: "8px 2px", order: Object.keys(methods.watch("Columns")).indexOf(_col.Name) }}>
                    <div onMouseDown={onMouseDown} style={{ pointerEvents: i ? "auto" : "none" }} className='row icon-button24'><Winicon src='fill/development/apps' size={"1.2rem"} /></div>
                    <Text className="label-3" style={{ flex: 1, padding: 5 }}>{_col.Setting?.Title ?? _col.Name}</Text>
                    <Switch
                        disabled={_col.Name === "Name"}
                        value={methods.watch("Columns")[_col.Name] >= 0}
                        onChange={(v) => {
                            let _columns = methods.getValues("Columns")
                            if (v) methods.setValue("Columns", { ..._columns, [_col.Name]: i })
                            else methods.setValue("Columns", { ..._columns, [_col.Name]: -1 })
                        }}
                    />
                </div>
            })}
            {reducers.map((_red) => {
                return <div key={_red.Id} id={_red.Id} className="row setting-prop-tile" style={{ gap: 4, padding: '8px 2px', order: Object.keys(methods.watch("Columns")).indexOf(_red.Name) }}>
                    <div onMouseDown={onMouseDown} className='row icon-button24'><Winicon src='fill/development/apps' size={"1.2rem"} /></div>
                    {editTitleId === _red.Id ?
                        <TextField
                            autoFocus
                            className="label-3"
                            defaultValue={_red.Title ?? _red.Name}
                            style={{ padding: 4, flex: 1 }}
                            onComplete={(ev) => { ev.target.blur() }}
                            onBlur={(ev) => {
                                if (ev.target.value.trim().length) onChangeReducer({ ..._red, Title: ev.target.value.trim() })
                                setEditTitleId(undefined)
                            }}
                        />
                        : <Text className="label-3" style={{ flex: 1, cursor: "pointer", padding: 5 }} onClick={() => { setEditTitleId(_red.Id) }}>{_red.Title ?? _red.Name}</Text>}
                    <button type="button" className="row icon-button28" onClick={() => { showAddColGroupPopup(_red) }}>
                        <Winicon src={"fill/user interface/edit"} />
                    </button>
                    <button type="button" className="row icon-button28" onClick={() => { onDelete(_red) }}>
                        <Winicon src={"fill/user interface/trash-can"} />
                    </button>
                </div>
            })}
        </div>
    </div>
}

// #region Popup Add Column
const AddColGroup = forwardRef(function AddColGroup(data, ref) {
    const methods = useForm({ shouldFocusError: false })
    const [fkKeys, setFkKeys] = useState([])

    const _onSubmit = (ev) => {
        if (!data.item && data.existNames.includes(ev.Name.trim().toLowerCase()))
            return methods.setError("Name", { message: "Name already exists!" })
        const newItem = {
            ...ev,
            Id: randomGID(),
        }
        data.onSubmit(newItem)
        closePopup(ref)
    }

    useEffect(() => {
        if (data.item) Object.keys(data.item).forEach(prop => {
            methods.setValue(prop, data.item[prop])
        })
    }, [data.item])

    return <div className="col" style={{ flex: 1, height: '100%' }}>
        <div className="row popup-header">
            <Text className="heading-6" style={{ flex: 1 }}>Create data column</Text>
            <div className="row" style={{ gap: 4 }}>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }}>
                    <Winicon src="fill/user interface/menu-dots" />
                </button>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }} onClick={() => { closePopup(ref) }}>
                    <Winicon src="fill/user interface/e-remove" />
                </button>
            </div>
        </div>
        <div className="col" style={{ flex: 1, height: '100%', padding: '1.6rem 2.4rem', gap: '1.6rem' }}>
            {data.item ? null : <TextFieldForm
                required
                label="Key name"
                name="Name"
                style={{ gap: '0.8rem' }}
                errors={methods.formState.errors}
                register={methods.register}
            />}
            <TextFieldForm
                required
                label="Title"
                name="Title"
                style={{ gap: '0.8rem' }}
                errors={methods.formState.errors}
                register={methods.register}
            />
            <div className="row" style={{ gap: '1.6rem' }}>
                <Select1Form
                    required
                    readonly
                    label="TableFK"
                    name="RelativeId"
                    style={{ gap: '0.8rem', flex: 1 }}
                    control={methods.control}
                    errors={methods.formState.errors}
                    options={data.relatives.map((el) => {
                        return {
                            id: el.Id,
                            name: el.TableFK
                        }
                    })}
                    onChange={(v) => {
                        if (v) {
                            const _colController = new TableController("column")
                            _colController.getListSimple(
                                {
                                    page: 1,
                                    size: 50,
                                    query: `@TableName:{${v.name}}`,
                                    returns: ['Id', 'Name', 'DataType']
                                }
                            ).then((res) => {
                                if (res.code === 200) setFkKeys(res.data)
                                else {
                                    setFkKeys([])
                                    ToastMessage.errors(res.message)
                                }
                            })
                        }
                    }}
                />
                <Select1Form
                    required
                    readonly
                    label="Reducer"
                    name="Reducer"
                    style={{ gap: '0.8rem', flex: 1 }}
                    control={methods.control}
                    errors={methods.formState.errors}
                    options={EventStep.REDUCE.EXPRESSION.map((el) => {
                        return {
                            id: el,
                            name: el
                        }
                    })}
                    onChange={(v) => { if (v && v.id === Reducers.COUNT) methods.setValue("DataType", FEDataType.NUMBER) }}
                />
                {methods.watch("Reducer") && methods.watch("Reducer") !== Reducers.COUNT ?
                    <Select1Form
                        required
                        readonly
                        label="By"
                        name="ReducerBy"
                        style={{ gap: '0.8rem', flex: 1 }}
                        control={methods.control}
                        errors={methods.formState.errors}
                        options={fkKeys.map((el) => {
                            return {
                                id: `${el.Name}`,
                                name: el.Name,
                                DataType: el.DataType
                            }
                        })}
                        onChange={(v) => { if (v) methods.setValue("DataType", v.DataType) }}
                    /> : <div style={{ flex: 1 }} />}
            </div>
            {methods.watch("RelativeId") && <TextFieldForm
                label="Query"
                name="Query"
                style={{ gap: '0.8rem' }}
                errors={methods.formState.errors}
                register={methods.register}
            />}
        </div>
        <div className="row popup-footer">
            <Text className="button-text-3" style={{ color: "var(--neutral-text-subtitle-color)" }} onClick={() => { closePopup(ref) }}>Cancel</Text>
            <div style={{ flex: 1 }} />
            <button type="button" className="row button-primary" onClick={methods.handleSubmit(_onSubmit)}>
                <Text className="button-text-3">Submit</Text>
            </button>
        </div>
    </div>
})