import { forwardRef, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { Button, Checkbox, closePopup, Popup, showPopup, Text, Winicon } from "wini-web-components"
import { Select1Form, TextFieldForm } from "../../../../project-component/component-form"

export default function SettingSectionForm({ formItem, fields = [], showPopupSelectForm = () => { } }) {
    const [isOpen, setIsOpen] = useState(true)
    const [formFields, setFormFields] = useState([])

    useEffect(() => {
        if (fields.length) setFormFields(fields.filter((e) => (e.type === "col" && formItem.Props[e.Name] >= 0) || (e.type === "rel" && formItem.Props[e.Column] >= 0)))
    }, [fields.length])

    return <div className="col" style={{ borderRadius: "0.8rem", backgroundColor: "var(--neutral-lighter-background-color)", border: "var(--neutral-bolder-border-color)" }}>
        <div className="row" style={{ padding: "1.2rem 1.6rem", cursor: "pointer" }} onClick={() => setIsOpen(!isOpen)} >
            <Button
                label={formItem.Name}
                className="button-text-6 button-infor border"
                style={{ gap: "0.6rem", padding: "0.4rem 0.8rem", borderRadius: "10rem", borderColor: "var(--primary-border-color) !important", height: "100%" }}
                prefix={<Winicon src={"outline/files/file-text"} size={"1rem"} />}
                onClick={(ev) => {
                    ev.stopPropagation()
                    showPopupSelectForm()
                }}
            />
            <div style={{ flex: 1 }} />
            <Winicon src={`fill/${isOpen ? "arrows/down-arrow" : "arrows/up-arrow"}`} size={"1.2rem"} />
        </div>
        <div className="col" style={{ borderTop: "var(--neutral-bolder-border-color)", display: isOpen ? "flex" : "none" }}>
            {formFields.map((_field => <FieldSettingCondition key={_field.Id} field={_field} props={formItem.Props} />))}
        </div>
    </div>
}

// #region Field condition
const FieldSettingCondition = ({ field, props }) => {
    const methods = useForm({ shouldFocusError: false })
    const [isOpen, setIsopen] = useState(false)
    const popupRef = useRef()

    const showPopupAddCondition = (ev) => {
        const _rect = ev.target.closest("div").getBoundingClientRect()
        showPopup({
            ref: popupRef,
            hideButtonClose: true,
            clickOverlayClosePopup: true,
            style: { position: "absolute", left: _rect.right - 120, top: _rect.bottom },
            content: <div className="popup-actions col">
                <button className='row' style={{ gap: '1.2rem', padding: '1rem 1.6rem' }} onClick={() => {
                    closePopup(popupRef)
                    showDrawer()
                }}>
                    <Winicon src='outline/user interface/edit' />
                    <Text className='label-4'>Set condition</Text>
                </button>
                <button className='row' style={{ gap: '1.2rem', padding: '1rem 1.6rem' }}>
                    <Winicon src='outline/user interface/trash-can' />
                    <Text className='label-4'>Remove all</Text>
                </button>
            </div>
        })
    }

    const showDrawer = () => {
        showPopup({
            ref: popupRef,
            hideButtonClose: true,
            style: { position: "absolute", width: "64rem", height: "100dvh", maxHeight: "100dvh", right: 0, top: 0, borderRadius: 0 },
            content: <DrawerSettingCondition ref={popupRef} field={field} />
        })
    }

    return <div key={field.Id} className="col" style={{ order: props[field.Column ?? field.Name] }}>
        <Popup ref={popupRef} />
        <div className="row" style={{ gap: 10, padding: "10px 1.6rem" }}>
            <Winicon src={`fill/arrows/triangle-${isOpen ? "down" : "right"}`} size={"1.2rem"} onClick={() => { setIsopen(!isOpen) }} />
            <Text className="label-4" style={{ flex: 1, paddingLeft: 2 }}>{field.Column ?? field.Name}</Text>
            <Winicon src={`fill/text/menu-dots`} size={"1.4rem"} onClick={showPopupAddCondition} />
        </div>
        {/* <Text style={{ padding: "0.5rem" }}>Setting condition</Text> */}
        <div className="col">

        </div>
    </div>
}

// #region Drawer Set Condition
const DrawerSettingCondition = forwardRef(function DrawerSettingCondition({ field }, ref) {
    const methods = useForm({ shouldFocusError: false })

    const onSubmit = () => { }

    return <div className="col" style={{ flex: 1, height: "100%" }}>
        <div className="popup-header row" style={{ gap: "0.8rem" }}>
            <Text className='heading-6' style={{ flex: 1 }}>Set condition for {field.Column ?? field.Name}</Text>
            <div className="row" style={{ gap: 4 }}>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }}>
                    <Winicon src="fill/user interface/menu-dots" />
                </button>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }}>
                    <Winicon src="fill/arrows/arrows-maximize" />
                </button>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }}>
                    <Winicon src="fill/arrows/refresh" />
                </button>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }} onClick={() => { closePopup(ref) }}>
                    <Winicon src="fill/user interface/e-remove" />
                </button>
            </div>
        </div>
        <div className="col" style={{ flex: 1, padding: "2.4rem", gap: "2rem", overflow: "hidden auto" }}>
            <PathItem />
            <Button
                prefix={<Winicon src={`fill/user interface/e-add`} size={"1.2rem"} />}
                label="Add condition"
                className="neutral-button"
                style={{ width: "fit-content", backgroundColor: "transparent", border: "var(--neutral-bolder-border-color)" }}
            />
        </div>
        <div className="row popup-footer">
            <Button
                label="Clear"
                style={{ width: "7.2rem", borderRadius: '0.8rem', backgroundColor: "var(--neutral-main-background-color)", color: "var(--neutral-text-subtitle-color)" }}
                onClick={() => { methods.reset() }}
            />
            <div style={{ flex: 1 }} />
            <Button
                label="Cancel"
                style={{ width: "7.2rem", borderRadius: '0.8rem', backgroundColor: "var(--neutral-main-background-color)", color: "var(--neutral-text-subtitle-color)" }}
                onClick={() => { closePopup(ref) }}
            />
            <Button
                label="Save"
                className="button-primary"
                style={{ width: "5.8rem", borderRadius: '0.8rem' }}
                onClick={methods.handleSubmit(onSubmit)}
            />
        </div>
    </div>
})

// #region PathItem 
const PathItem = ({ path }) => {
    const methods = useForm({ shouldFocusError: false, defaultValues: { Condition: ">=", Value: "100000" } })

    return <div className="col" style={{ gap: "1.2rem" }}>
        <Text className="heading-7">Path 1</Text>
        <div className="col" style={{ gap: "1.2rem", padding: "1.6rem", borderRadius: "0.8rem", border: "var(--neutral-bolder-border-color)" }}>
            <div className="row" style={{ gap: '1.2rem' }}>
                <Winicon src="outline/arrows/down-arrow" size={"1.6rem"} />
                <div className="row" style={{ flex: 1, gap: 4 }}>
                    <Text className="label-3">Name</Text>
                    <Text className="heading-8" style={{ fontStyle: "italic" }}>{methods.watch("Condition")}</Text>
                    <Text className="label-3">{methods.watch("Value")}</Text>
                </div>
                <Winicon src="outline/user interface/trash-can" size={"1.6rem"} />
                <button type="button" className="icon-button24 row">
                    <Winicon src="fill/development/apps" />
                </button>
            </div>
            <div className="row" style={{ gap: 8 }}>
                <Text className="label-3" style={{ width: '6rem' }}>When</Text>
                <Select1Form
                    readonly
                    required
                    control={methods.control}
                    errors={methods.formState.errors}
                    name="Condition"
                    placeholder="Select condition"
                    options={[
                        {
                            id: ">",
                            name: "greater than"
                        },
                        {
                            id: "<",
                            name: "less than"
                        },
                        {
                            id: ">=",
                            name: "greater than or equal to"
                        },
                        {
                            id: ">=",
                            name: "greater than or equal to"
                        },
                        {
                            id: "<=",
                            name: "less than or equal to"
                        },
                        {
                            id: "===",
                            name: "equal to"
                        },
                        {
                            id: "!==",
                            name: "not equal to"
                        },
                    ]}
                    style={{ width: "22rem" }}
                />
                <TextFieldForm
                    placeholder="Input value"
                    name="Value"
                    errors={methods.formState.errors}
                    register={methods.register}
                    style={{ flex: 1 }}
                />
            </div>
            <div className="row" style={{ gap: 8 }}>
                <Text className="label-3" style={{ width: "6rem" }}>Move to</Text>
                <Select1Form
                    readonly
                    required
                    control={methods.control}
                    errors={methods.formState.errors}
                    name="To"
                    placeholder="Select section"
                    options={[]}
                    style={{ flex: 1 }}
                />
            </div>
        </div>
    </div>
}