import styles from './view.module.css'
import { Popup, showPopup, ToastMessage, Winicon } from "wini-web-components"
import { ChartUI } from "../../module/dashboard/view"
import { SettingDataController } from "../../module/controller"
import { TableController } from "./controller"
import { useEffect, useRef, useState } from "react"
import PopupAddCard from "../../module/dashboard/addChart"

export default function SettingChartTab({ item }) {
    const _settingDataController = new SettingDataController("chart")
    const _colController = new TableController("column")
    const _relController = new TableController("rel")
    const [cards, setCards] = useState({ data: [] })
    const [relatives, setRelatives] = useState([])
    const ref = useRef()

    const showPopupAddCard = (chartItem) => {
        showPopup({
            ref: ref,
            heading: <div className="heading-6 popup-header">{chartItem ? "Edit chart" : "Create chart"}</div>,
            style: { width: '120rem', height: '80%' },
            content: <PopupAddCard
                ref={ref}
                tbName={item.Name}
                item={chartItem}
                colController={_colController}
                relController={_relController}
                onSuccess={async (_newCard) => {
                    const res = await _settingDataController.action("add", { data: [_newCard] })
                    if (res.code === 200) {
                        if (relatives.every(e => e.Id !== _newCard.RelativeId)) {
                            const relResponse = await _relController.getByListId([_newCard.RelativeId])
                            if (relResponse.code === 200) {
                                var _newRels = [...relatives, ...relResponse.data]
                                setRelatives(_newRels)
                            }
                        }
                        _newCard.TableFK = (_newRels ?? relatives).find((_rel) => _rel.Id === _newCard.RelativeId)?.TableFK
                        setCards({
                            data: chartItem ? cards.data.map(e => {
                                if (e.Id === chartItem.Id) return _newCard
                                else return e
                            }) : [_newCard, ...cards.data], total: cards.total + (chartItem ? 0 : 1)
                        })
                    } else {
                        ToastMessage.errors(res.message)
                    }
                }}
            />
        })
    }

    const getData = async (page) => {
        const res = await _settingDataController.getListSimple({ page: page ?? 1, size: 8, query: `@TbName:{${item.Name}}` })
        if (res.code === 200) {
            const _relativeIds = res.data.map(e => e.RelativeId).filter(id => relatives.every(e => e.Id !== id))
            if (_relativeIds.length) {
                const relResponse = await _relController.getByListId(_relativeIds)
                if (relResponse.code === 200) {
                    var _newRels = [...relatives, ...relResponse.data].filter(e => e != undefined)
                    setRelatives(_newRels)
                }
            }
            const _newCards = []
            if (page) {
                _newCards.push(...cards.data, ...res.data.map(e => {
                    e.TableFK ??= (_newRels ?? relatives).find((_rel) => _rel.Id === e.RelativeId)?.TableFK
                    return e
                }))
            } else {
                _newCards.push(...res.data.map(e => {
                    e.TableFK ??= (_newRels ?? relatives).find((_rel) => _rel.Id === e.RelativeId)?.TableFK
                    return e
                }))
            }
            setCards({ data: _newCards, total: res.totalCount })
        } else {
            ToastMessage.errors(res.message)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return <>
        <div className='row' style={{ padding: '2.4rem', width: '100%', flex: 1, height: '100%', flexWrap: 'wrap', overflow: 'hidden auto', gap: '2.4rem', alignItems: 'start' }}>
            <Popup ref={ref} />
            {cards.data.map(e => <ChartUI
                key={e.Id}
                item={e}
                timeRannge={120}
                onEdit={() => { showPopupAddCard(e) }}
                onDelete={async () => {
                    const res = await _settingDataController.action("delete", { ids: [e.Id] })
                    if (res.code === 200) {
                        setCards({
                            data: cards.data.filter(el => el.Id !== e.Id),
                            total: cards.total - 1
                        })
                    } else {
                        ToastMessage.errors(res.message)
                    }
                }}
            />)}
        </div>
        <button type="button" className={`row icon-button60 ${styles['button-add-chart']}`} onClick={() => { showPopupAddCard() }}>
            <Winicon src={"fill/user interface/c-add"} color='var(--primary-main-color)' size={"3.6rem"} />
        </button>
    </>
}