import styles from './index.module.css'
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { closePopup, Popup, Select1, showPopup, Text, TextField, Winicon } from "wini-web-components"
import { cssRegex, onFocus } from "../functions/common"
import { getSizeValue, listBreakpoint, tilteBreakpoints } from "../functions/funtions"
import { regexResponsiveClass } from "../../../config"

export const BoxProperties = ({ selected, onChange, docBody = document.body.querySelector(`div[class*="overview-container"]`) }) => {
    const methods = useForm({ shouldFocusError: false })
    const [isOpen, setIsOpen] = useState(true)
    const [nodeElement, setNode] = useState()
    const [showBrp, setShowBrp] = useState(false)
    const ref = useRef()

    const widthOptions = (ev) => {
        const _rect = ev.target.closest(".regular1").getBoundingClientRect()
        const _nodeParentStyle = window.getComputedStyle(nodeElement.parentElement.closest('.layer-item'))
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { left: _rect.x, top: _rect.bottom, borderRadius: '0.8rem' },
            content: <div className="col popup-actions" style={{ maxHeight: 'fit-content' }}>
                <button type="button" onClick={() => {
                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style } } }
                    delete _tmp.Setting.style.width
                    delete _tmp.Setting.style['--gutter']
                    onChange(_tmp)
                    closePopup(ref)
                }}>
                    <Text className="button-text-5">Auto</Text>
                </button>
                {_nodeParentStyle.flexDirection === "row" && <button type="button" onClick={() => {
                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style } } }
                    _tmp.Setting.className = (_tmp.Setting.className?.split(" ") ?? []).filter(v => v !== "remain").concat("remain").join(" ")
                    delete _tmp.Setting.style['--gutter']
                    onChange(_tmp)
                    closePopup(ref)
                }}>
                    <Text className="button-text-5">Flex</Text>
                </button>}
                <button type="button" onClick={() => {
                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, width: "fit-content" } } }
                    delete _tmp.Setting.style['--gutter']
                    onChange(_tmp)
                    closePopup(ref)
                }}>
                    <Text className="button-text-5">Fit</Text>
                </button>
                <button type="button" onClick={() => {
                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, width: `${nodeElement.offsetWidth}px` } } }
                    delete _tmp.Setting.style['--gutter']
                    onChange(_tmp)
                    closePopup(ref)
                }}>
                    <Text className="button-text-5">Fixed</Text>
                </button>
            </div >
        })
    }

    const heightOptions = (ev) => {
        const _rect = ev.target.closest(".regular1").getBoundingClientRect()
        const _nodeParentStyle = window.getComputedStyle(nodeElement.parentElement.closest('.layer-item'))
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { left: _rect.x, top: _rect.bottom, borderRadius: '0.8rem' },
            content: <div className="col popup-actions" style={{ maxHeight: 'fit-content' }}>
                <button type="button" onClick={() => {
                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style } } }
                    delete _tmp.Setting.style.height
                    onChange(_tmp)
                    closePopup(ref)
                }}>
                    <Text className="button-text-5">Auto</Text>
                </button>
                {_nodeParentStyle.flexDirection === "column" && selected.Setting?.className && <button type="button" onClick={() => {
                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style } } }
                    _tmp.Setting.className = (_tmp.Setting.className?.split(" ") ?? []).filter(v => v !== "remain").concat("remain").join(" ")
                    onChange(_tmp)
                    closePopup(ref)
                }}>
                    <Text className="button-text-5">Remain</Text>
                </button>}
                <button type="button" onClick={() => {
                    onChange({ ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, height: "fit-content" } } })
                    closePopup(ref)
                }}>
                    <Text className="button-text-5">Fit</Text>
                </button>
                <button type="button" onClick={() => {
                    onChange({ ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, height: `${nodeElement.offsetHeight}px` } } })
                    closePopup(ref)
                }}>
                    <Text className="button-text-5">Fixed</Text>
                </button>
            </div >
        })
    }

    useEffect(() => {
        const node = docBody.querySelector(`*[id="${selected.Id}"]`)
        if (selected.ParentId)
            var parentNode = node.parentElement.closest(".layer-item")
        // 
        if (node.id !== nodeElement?.id) setNode(node)
        if (node) {
            const _nodeStyle = window.getComputedStyle(node)
            if (parentNode)
                var parentDirection = window.getComputedStyle(parentNode).flexDirection
            if (selected.ParentId) {
                const _clN = [...node.classList].find(e => regexResponsiveClass.test(e))
                if (_clN) {
                    const _brp = [...node.classList].map(item => getSizeValue(item)).filter(Boolean);
                    if (_brp.length > 0) {
                        // setBreakPoint(true)
                        _brp.forEach(e => methods.setValue(`${e.brp}-brp`, e.value))
                    } else {
                        // setBreakPoint(false)
                        tilteBreakpoints.forEach(e => methods.setValue(`${e}-brp`, null))
                    }
                    if (!_clN.includes("-") && (parentDirection === "row" | _clN !== "remain")) {
                        methods.setValue('colValue', _clN)
                    } else {
                        methods.setValue("width", selected.Setting.style?.width ?? "Fit")
                    }
                } else {
                    tilteBreakpoints.forEach(e => methods.setValue(`${e}-brp`, null))
                    methods.setValue('colValue', null)
                    methods.setValue("width", selected.Setting.style?.width ?? "auto")
                }
                if (_clN === "remain" && parentDirection === "column") {
                    methods.setValue('rowValue', _clN)
                } else {
                    methods.setValue('rowValue', null)
                    methods.setValue("height", selected.Setting.style?.height ?? "auto")
                }
                methods.setValue('max-height', selected.Setting.style.maxHeight ?? _nodeStyle.maxHeight)
                methods.setValue('max-width', selected.Setting.style.maxWidth ?? _nodeStyle.maxWidth)
            }
        }
    }, [selected, selected.ParentId])

    return <div className='col' style={{ borderBottom: 'var(--neutral-main-border-color)' }}>
        <Popup ref={ref} />
        <div className="row" style={{ padding: '0.8rem 1.2rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Dimension</Text>
            <Winicon onClick={() => { setIsOpen(!isOpen) }} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/arrows/${isOpen ? "up" : "down"}-arrow`} />
        </div>
        <div className="col" style={{ padding: '0 1.2rem 0.8rem', gap: '0.8rem', display: isOpen ? 'flex' : 'none' }}>
            <div className="row" style={{ gap: '0.8rem' }}>
                {methods.watch("rowValue") ? <Select1
                    readOnly
                    className="regular1"
                    style={{ flex: 1 }}
                    prefix={<Text className="subtitle-4">H</Text>}
                    popupClassName="select-col"
                    value={methods.watch('rowValue')}
                    options={['fixed', listBreakpoint[0]].map(e => { return { id: e, name: e } })}
                    onChange={(ev) => {
                        if (ev.id !== "remain") {
                            let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, width: ev.target.value.trim() } } }
                            _tmp.Setting.style.height = `${nodeElement.offsetHeight}px`
                            _tmp.Setting.className = _tmp.Setting.className.split(" ").filter(e => e !== "remain").join(" ")
                            onChange(_tmp)
                        }
                    }}
                /> : <TextField
                    style={{ flex: 1 }}
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="subtitle-4">H</Text>}
                    className="regular1"
                    name="height"
                    placeholder="height"
                    suffix={<Winicon src={"fill/arrows/down-arrow"} onClick={heightOptions} size={'1.2rem'} style={{ padding: "0.2rem" }} />}
                    onFocus={(ev) => onFocus(ev, methods)}
                    register={methods.register('height', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, height: _newV } } }
                                if (_newV === "auto") delete _tmp.Setting.style.height
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />}
                {methods.watch("colValue") ? <Select1
                    readOnly
                    className="regular1"
                    style={{ flex: 1 }}
                    prefix={<Text className="subtitle-4">W</Text>}
                    popupClassName="select-col"
                    value={methods.watch('colValue')}
                    options={['fixed', ...listBreakpoint].map(e => { return { id: e, name: e } })}
                    onChange={(ev) => {
                        const _colV = methods.getValues('colValue')
                        const _nodeParentStyle = window.getComputedStyle(nodeElement.parentElement.closest(".layer-item"))
                        let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style } } }
                        if (_colV !== ev.id) {
                            if (ev.id == 'fixed') {
                                _tmp.Setting.style.width = `${nodeElement.offsetWidth}px`
                                delete _tmp['--gutter']
                                _tmp.Setting.className = _tmp.Setting.className.split(" ").filter(e => e !== _colV).join(" ")
                            } else {
                                _tmp.Setting.style['--gutter'] = _nodeParentStyle.columnGap === "normal" ? "0px" : _nodeParentStyle.columnGap
                                _tmp.Setting.className = _tmp.Setting.className.split(" ").map(e => e === _colV ? ev.id : e).join(" ")
                            }
                            onChange(_tmp)
                        }
                    }}
                /> : <TextField
                    style={{ flex: 1 }}
                    onComplete={(ev) => ev.target.blur()}
                    className="regular1"
                    prefix={<Text className="subtitle-4">W</Text>}
                    onFocus={(ev) => onFocus(ev, methods)}
                    name="width"
                    placeholder="width"
                    suffix={<Winicon src={"fill/arrows/down-arrow"} onClick={widthOptions} size={'1.2rem'} style={{ padding: "0.2rem" }} />}
                    register={methods.register('width', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, width: _newV } } }
                                if (_newV === "auto") delete _tmp.Setting.style.width
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />}
                <Winicon
                    src={`${showBrp ? "fill" : "outline"}/user interface/gear`}
                    className={styles['toggle-brp']}
                    size='1.4rem'
                    onClick={() => { setShowBrp(!showBrp) }} style={{ borderColor: showBrp ? undefined : "transparent" }}
                />
            </div>
            <div className='row' style={{ gap: '0.8rem' }}>
                <TextField
                    style={{ width: `calc((100% - 2.4rem - 2px - 1.6rem) / 2)` }}
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="subtitle-5">MaxH</Text>}
                    className="regular1"
                    name="max-height"
                    placeholder="max height"
                    onFocus={(ev) => onFocus(ev, methods)}
                    register={methods.register('max-height', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, maxHeight: _newV } } }
                                if (_newV === "auto" || _newV === "none") delete _tmp.Setting.style.maxHeight
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
                <TextField
                    style={{ width: `calc((100% - 2.4rem - 2px - 1.6rem) / 2)` }}
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="subtitle-5">MaxW</Text>}
                    className="regular1"
                    name="max-width"
                    placeholder="max width"
                    onFocus={(ev) => onFocus(ev, methods)}
                    register={methods.register('max-width', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, maxWidth: _newV } } }
                                if (_newV === "auto" || _newV === "none") delete _tmp.Setting.style.maxWidth
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
            </div>
            {/*#region Breakpoint */}
            {showBrp && <>
                <Text className="semibold1">Breakpoint</Text>
                <div className='row' style={{ gap: '0.8rem 0.4rem', flexWrap: 'wrap' }}>
                    {tilteBreakpoints.map((brp, index) => {
                        const _brpCols = ['none', ...listBreakpoint].map(e => e == "none" ? e : `${e}-${brp.toLowerCase()}`)
                        return <div key={brp} className="col8 col" style={{ gap: '0.5rem', flex: 1, '--gutter': '0.4rem' }}>
                            <Text className="semibold1" style={{ color: 'var(--neutral-text-subtitle-color)' }}>{brp}</Text>
                            <Select1
                                readOnly
                                style={{ flex: 1 }}
                                className="regular1"
                                popupClassName={`${brp}-brp`}
                                placeholder={'none'}
                                value={methods.watch(`${brp}-brp`)}
                                options={_brpCols.map(e => {
                                    return {
                                        id: e,
                                        name: e?.split('-')[0]?.split('col').join('')
                                    }
                                })}
                                onChange={(ev) => {
                                    const parentNode = nodeElement.parentElement.closest(".layer-item")
                                    const { columnGap, gap } = window.getComputedStyle(parentNode)
                                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style } } }
                                    if (ev.id === 'none') {
                                        _tmp.Setting.className = _tmp.Setting.className.split(" ").map(cls => cls !== _brpCols[index] ? cls : "").join(" ")
                                    } else {
                                        _tmp.Setting.style['--gutter'] = (parentNode.style.columnGap ?? columnGap) ? (parentNode.style.columnGap ?? columnGap) : (parentNode.style.gap ?? gap)
                                        _tmp.Setting.className = _tmp.Setting.className.split(" ").filter(cls => _brpCols.every(e => cls !== e)).concat(ev.id).join(" ")
                                    }
                                    onChange(_tmp)
                                }}
                            />
                        </div>
                    })}
                </div>
            </>}
        </div>
    </div>
}