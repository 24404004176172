import { useSelector } from "react-redux"
import { ComponentType } from "../../wini/table/da"
import SettingText from "./componentProperties/text"
import SettingImg from "./componentProperties/img"
import SettingContainer from "./componentProperties/container"
import SettingButton from "./componentProperties/button"
import SettingTextField from "./componentProperties/textfield"
import SettingSwitch from "./componentProperties/switch"
import SettingCheckBox from "./componentProperties/checkbox"
import SettingRadio from "./componentProperties/radio"
import SettingTextArea from "./componentProperties/textarea"
import SettingChart from "./componentProperties/chart"
import SettingForm from "./componentProperties/form"
import SettingCard from "./componentProperties/card"

export default function ActionTab() {
    const { layers, layout } = useSelector((store) => store.page.data)

    const renderSettingView = () => {
        return <div />
        // const _selectedLayer = [...(layers ?? []), ...(layout ?? [])].find(e => e.Id === selectedLayerId)
        // switch (_selectedLayer?.Type) {
        //     case ComponentType.text:
        //         return <SettingText selectedLayer={_selectedLayer} />
        //     case ComponentType.img:
        //         return <SettingImg selectedLayer={_selectedLayer} />
        //     case ComponentType.checkbox:
        //         return <SettingCheckBox selectedLayer={_selectedLayer} />
        //     case ComponentType.switch:
        //         return <SettingSwitch selectedLayer={_selectedLayer} />
        //     case ComponentType.radio:
        //         return <SettingRadio selectedLayer={_selectedLayer} />
        //     case ComponentType.textField:
        //         return <SettingTextField selectedLayer={_selectedLayer} />
        //     case ComponentType.textArea:
        //         return <SettingTextArea selectedLayer={_selectedLayer} />
        //     case ComponentType.form:
        //         return <SettingForm selectedLayer={_selectedLayer} />
        //     case ComponentType.card:
        //         return <SettingCard selectedLayer={_selectedLayer} />
        //     case ComponentType.button:
        //         return <SettingButton selectedLayer={_selectedLayer} />
        //     case ComponentType.container:
        //         return <SettingContainer selectedLayer={_selectedLayer} />
        //     case ComponentType.chart:
        //         return <SettingChart selectedLayer={_selectedLayer} />
        //     default:
        //         return <div />
        // }
    }

    return <div className="col" style={{ backgroundColor: '#14181b', flex: 1, overflow: 'hidden auto', scrollbarWidth: "none" }}>{renderSettingView()}</div>
}


