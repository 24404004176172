import { Button, Text, ToastMessage, Winicon } from "wini-web-components"
import { ComponentType, FEDataType } from "../da"
import { TableController } from "../controller"
import { DataController } from "../../../module/controller"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import ConfigApi from "../../../../da/configApi"

export default function RenderCard({ layers = [], cardItem, dataModel, style, className, id }) {
    const methods = useForm({ shouldFocusError: false })
    const [data, setData] = useState({ data: [], totalCount: undefined })
    const [_rels, setRels] = useState([])
    const [_cols, setCols] = useState([])

    const mapRelativeData = async () => {
        const _relIds = layers.map(e => e.RelativeId).filter(e => e?.length)
        if (_relIds.length === 0) return
        const _relController = new TableController("rel")
        const _rels = await _relController.getByListId(_relIds)
        if (_rels.code !== 200) return undefined
        setRels(_rels.data.filter((e) => e !== undefined))
    }

    const mapColumnData = async () => {
        const _colNames = layers.map(e => e.NameField).filter(e => e?.length)
        if (_colNames.length === 0) return
        const _colController = new TableController("column")
        const _colRes = await _colController.getListSimple({ page: 1, size: 50, query: `@Name:{${_colNames.join(" | ")}} @TableName:{${cardItem.TbName}}` })
        if (_colRes.code !== 200) return undefined
        setCols(_colRes.data)
    }

    useEffect(() => {
        if (layers.length) {
            mapRelativeData()
            mapColumnData()
        }
    }, [layers])

    useEffect(() => {
        if (_rels.length && data.data.length) {
            for (const _relItem of _rels) {
                const layerItem = layers.find(e => e.RelativeId === _relItem.Id)
                if (layerItem.Reducer) {
                    const _tbFKController = new DataController(_relItem.TableFK)
                    _tbFKController.group({
                        searchRaw: `@${_relItem.Column}:{${data.data.map((e) => `${e.Id}*`).join(" | ")}}`,
                        reducers: `GROUPBY 1 @${_relItem.Column} REDUCE ${layerItem.Reducer} ${layerItem.ReducerBy ? `1 @${layerItem.ReducerBy}` : 0} AS _value`,
                    }).then((res) => {
                        if (res.code === 200) methods.setValue(layerItem.NameField, res.data)
                    })
                } else {
                    const _tbPKController = new DataController(_relItem.TablePK)
                    let _idsByPropName = data.data.map((e) => e[_relItem.Column]?.split(",") ?? []).flat(Infinity)
                    const _tmpPkItems = methods.getValues(layerItem.NameField) ?? []
                    if (_tmpPkItems.length) {
                        _idsByPropName = _idsByPropName.filter((id) => _tmpPkItems.every((e) => e.Id !== id))
                    }
                    _tbPKController.getByListId(_idsByPropName).then((res) => {
                        if (res.code === 200) methods.setValue(layerItem.NameField, [..._tmpPkItems, ...res.data])
                    })
                }
            }
        }
    }, [_rels.length, data.data])

    useEffect(() => {
        if (dataModel) {
            setData({ data: [dataModel], totalCount: 1 })
        } else if (cardItem?.TbName) {
            const dataController = new DataController(cardItem.TbName)
            dataController.aggregateList({ page: 1, size: 10 }).then(res => {
                if (res.code === 200) setData({ data: res.data, totalCount: res.totalCount })
                else ToastMessage.errors(res.message)
            })
        }
    }, [cardItem?.TbName])

    return data.data.map(dataItem => {
        let _tmp = {}
        if (_rels.length) {
            for (const _rel of _rels) {
                const _layerItem = layers.find(e => e.RelativeId === _rel.Id)
                if (_layerItem) {
                    const _list = methods.watch(_layerItem.NameField) ?? []
                    if (_rel.TablePK === cardItem.TbName) _tmp[_layerItem.NameField] = _list.find((e) => e[_rel?.Column ?? ""].includes(dataItem.Id))
                    else _tmp[_layerItem.NameField] = _list.filter((e) => dataItem[_rel?.Column ?? ""].includes(e.Id))
                }
            }
        }
        return <RenderCardByLayers key={dataItem.Id} dataItem={{ ...dataItem, ..._tmp }} layers={layers} cols={_cols} className={className} style={style} id={id} />
    })
}

const RenderCardByLayers = ({ layers = [], dataItem, parentId, cols = [], style, className, id }) => {
    return layers.filter(e => e.ParentId === parentId).map(e => <RenderComponentByLayer key={e.Id} layers={layers} item={e} dataItem={dataItem} cols={cols} className={className} style={style} id={id} />)
}

const RenderComponentByLayer = ({ item, layers = [], dataItem, cols = [], style, className, id }) => {

    const retureUI = () => {
        let _props = { ...item.Setting }
        if (!id) {
            _props.id = item.Id
            _props.className = _props.className ? (_props.className + " layer-item") : "layer-item"
        }
        if (!item.ParentId) {
            if (style) _props.style = { ..._props.style, ...style }
            if (className) _props.className = _props.className.split(" ").filter(e => e !== "layer-item").join(" ") + " " + className
            if (id) _props.id = id
        }
        if (item.NameField)
            var dataValue = dataItem[item.NameField]
        switch (item.Type) {
            case ComponentType.container:
                if (_props && (!id || !item.ParentId)) _props['component-type'] = item.Type
                return <div {..._props}>
                    {dataValue && Array.isArray(dataValue) ?
                        dataValue.map(e => <RenderCardByLayers key={e.Id} layers={layers} parentId={item.Id} dataItem={e} cols={cols} className={className} style={style} id={id} />) :
                        <RenderCardByLayers layers={layers} parentId={item.Id} dataItem={dataItem} cols={cols} className={className} style={style} id={id} />}
                </div>
            case ComponentType.text:
                if (dataValue) {
                    const _col = cols.find(e => e.Name === item.NameField)
                    switch (_col?.DataType) {
                        case FEDataType.HTML:
                            return Array.isArray(dataValue) ?
                                dataValue.map(e => <Text key={e.Id} {..._props} html={e} />) :
                                <Text {..._props} html={dataValue} />
                        default:
                            return Array.isArray(dataValue) ?
                                dataValue.map(e => <Text key={e.Id} {..._props}>{e ?? ""}</Text>) :
                                <Text {..._props}>{dataValue}</Text>
                    }
                } else return <Text {..._props}>{item.Setting?.value ?? ""}</Text>
            case ComponentType.button:
                return <Button type="button"  {..._props} />
            case ComponentType.icon:
                return dataValue ? Array.isArray(dataValue) ?
                    dataValue.map(e => <Winicon key={e.Id} {...({ ..._props, src: e })} />) :
                    <Winicon {...({ ..._props, src: dataValue })} /> :
                    <Winicon {..._props} />
            case ComponentType.img:
                return dataValue ? Array.isArray(dataValue) ?
                    dataValue.map(e => <img key={e.Id} alt="" draggable={false} {...({ ..._props, src: e.startsWith("http") ? e : (ConfigApi.imgUrlId + e) })} />) :
                    <img draggable={false} alt="" {...({ ..._props, src: dataValue.startsWith("http") ? dataValue : (ConfigApi.imgUrlId + dataValue) })} /> :
                    <img draggable={false} alt="" {..._props} />
            default:
                return <div {..._props} />
        }
    }

    return retureUI()
}