import { useEffect, useRef, useState } from "react"
import styles from "./index.module.css"
import ReactDOM from 'react-dom'
import { Route, Routes, useLocation } from "react-router-dom"
import { ListProject } from "../screen/wini/project/view"
import CreateProject from "../screen/wini/project/create"
import ModuleListView from "../screen/module/manager/view"
import { useDispatch, useSelector } from "react-redux"
import ActionSidebar from "./menu"
import SettingPageView from "../screen/module/page/view"
import ListTable from "../screen/wini/table/view"
import { TableController } from "../screen/wini/table/controller"
import { PageActions } from "../screen/module/page/reducer"
import RenderPageByLayers from "../screen/module/page/renderPageView"
import Header from './header'
import { DesignTokenActions } from "../screen/wini/designToken/reducer"
import { DesignTokenType } from "../screen/wini/designToken/da"
import { socket } from "../socket"
import $ from "jquery"
import WorkflowPage from "../screen/wini/workflow/view"
import { ConfigDomain } from "../da/configApi"

export const Home = () => {
    return window.location.pathname === '/' ? <div></div> : <div className="row main-layout">
        <Routes>
            {ConfigDomain.includes(window.location.hostname) ?
                <>
                    <Route
                        path={'/pages/:pName'}
                        element={<SettingPageView />}
                        exact
                    />
                    <Route
                        path={'/home'}
                        element={<MainRoute body={<ListProject />} />}
                        exact
                    />
                    <Route
                        path={'/admin'}
                        element={<MainRoute body={<ListTable />} />}
                        exact
                    />
                    <Route
                        path={'/workflow'}
                        element={<MainRoute body={<WorkflowPage />} />}
                        exact
                    />
                </> : <Route
                    path={'/setting/project'}
                    element={<MainRoute body={<CreateProject />} />}
                    exact
                />}
            <Route
                path={'/manager/:pModuleName?/:moduleName'}
                element={<MainRoute body={<ManagerView />} />}
                exact
            />
            <Route
                path={'/page/:pageName'}
                element={<MainRoute body={<PageView />} />}
                exact
            />
        </Routes>
    </div>
}

const MainRoute = ({ body }) => {
    return <>
        <Header />
        <ActionSidebar />
        {body}
    </>
}

const ManagerView = () => {
    const onLoading = useSelector((store) => store.module.onLoading)
    const searchParams = new URLSearchParams(window.location.search)
    const _pageId = searchParams.get("pageid")
    const [table, setTable] = useState()

    useEffect(() => {
        const _tbController = new TableController("table")
        _tbController.getByListId([_pageId]).then(res => {
            if (res.code === 200) setTable(res.data[0])
            else setTable(undefined)
        })
    }, [_pageId])

    return (onLoading || !table) ? <div /> : <ModuleListView module={table.Name} />
}

const PageView = () => {
    const location = useLocation()
    const onLoading = useSelector((store) => store.page.onLoading)
    const searchParams = new URLSearchParams(location.search)
    const _pageId = searchParams.get("pageid")
    const dispatch = useDispatch()

    useEffect(() => {
        PageActions.onReset(dispatch)
        const _pageController = new TableController("page")
        _pageController.getByListId([_pageId]).then(res => {
            if (res.code === 200) PageActions.setSelectedPage(dispatch, res.data[0])
        })
    }, [])

    useEffect(() => {
        if (socket.connected && _pageId) {
            socket.emit('client-init', { pageid: _pageId })
            function onMessage(data) {
                console.log("????: ", data)
            }
            socket.on('page-demo', onMessage);
            return () => {
                socket.off('page-demo', onMessage);
            };
        }
    }, [socket.connected, _pageId])

    return <>
        {/* <ActionSidebar /> */}
        {onLoading ? <div /> : <RenderPageByLayers />}
    </>
}

export const PageViewDemo = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const _pageId = searchParams.get("pageid")
    const { layers } = useSelector((store) => store.page.data)
    const designTokens = useSelector((store) => store.designToken.data)
    const dispatch = useDispatch()
    const canvasRef = useRef()
    const [selectedId, setSelectedId] = useState()
    const [hoverId, setHoverId] = useState()
    const [openLayout, setOpenLayout] = useState(false)

    useEffect(() => {
        if (socket.connected && _pageId) {
            socket.emit('client-init', { pageid: _pageId })
            function onMessage(data) {
                switch (data.type) {
                    case 'SYNC':
                        PageActions.syncSocket(dispatch, data.data)
                        break;
                    case 'SELECTID':
                        setSelectedId(data.data)
                        break;
                    case 'HOVERID':
                        setHoverId(data.data)
                        break;
                    case 'openLayout':
                        setOpenLayout(data.data)
                        break;
                    default:
                        break;
                }
            }
            socket.on('page-demo', onMessage);
            return () => {
                socket.off('page-demo', onMessage);
            };
        }
    }, [socket.connected, _pageId])

    useEffect(() => {
        DesignTokenActions.getData(dispatch)
    }, [])

    useEffect(() => {
        if (canvasRef.current && layers?.length) {
            canvasRef.current.width = canvasRef.current.parentElement.offsetWidth
            canvasRef.current.height = canvasRef.current.parentElement.offsetHeight
            let ctx = canvasRef.current.getContext("2d");
            ctx.clearRect(0, 0, canvasRef.current.offsetWidth, canvasRef.current.offsetHeight)
            ctx.font = "1.4rem Inter";
            let _selectedItem;
            let _hoverItem;
            if (selectedId || hoverId) {
                for (const e of layers) {
                    if (e.Id === selectedId) _selectedItem = e
                    if (e.Id === hoverId && selectedId !== hoverId) _hoverItem = e
                }
            }
            if (_selectedItem) {
                const _layerRect = document.getElementById(selectedId)?.getBoundingClientRect()
                if (_layerRect) {
                    ctx.strokeStyle = '#349c90'
                    if (_layerRect.y === 0) ctx.lineWidth = 6
                    else ctx.lineWidth = 1.2
                    ctx.strokeRect(_layerRect.x, _layerRect.y, _layerRect.width, _layerRect.height)
                    ctx.fillStyle = '#349c90'
                    const textWH = ctx.measureText(_selectedItem.Name)
                    ctx.fillRect(_layerRect.x, _layerRect.y - 18, textWH.width + 4, 17)
                    ctx.fillStyle = '#fff'
                    ctx.fillText(_selectedItem.Name, _layerRect.x + 2, _layerRect.y - 4)
                }
            }
            if (_hoverItem) {
                const _layerRect = document.getElementById(hoverId)?.getBoundingClientRect()
                if (_layerRect) {
                    if (_layerRect.y === 0) ctx.lineWidth = 6
                    else ctx.lineWidth = 1.2
                    ctx.strokeStyle = '#9c6334'
                    ctx.strokeRect(_layerRect.x, _layerRect.y, _layerRect.width, _layerRect.height)
                    ctx.fillStyle = '#9c6334'
                    const textWH = ctx.measureText(_hoverItem.Name)
                    ctx.fillRect(_layerRect.x, _layerRect.y - 18, textWH.width + 4, 17)
                    ctx.fillStyle = '#fff'
                    ctx.fillText(_hoverItem.Name, _layerRect.x + 2, _layerRect.y - 4)
                }
            }
        }
    }, [canvasRef, selectedId, hoverId, layers?.length])

    useEffect(() => {
        if (designTokens.length) {
            const tokenValues = designTokens.filter(e => e.Type !== DesignTokenType.group)
            let styleElement = document.head.querySelector(":scope > .designTokens") ?? document.createElement('style');
            styleElement.type = 'text/css';
            const _innerHTML = `html { \n${tokenValues.map(e => e.Value?.lightMode ? `--${e.Id}: ${e.Value.lightMode};` : "").join('\n')}\n }\n\nhtml.dark { \n${tokenValues.map(e => e.Value?.darkMode ? `--${e.Id}: ${e.Value.darkMode};` : "").join('\n')}\n }`
            styleElement.innerHTML = _innerHTML;
            if (!styleElement.classList.contains("designTokens")) {
                styleElement.classList.add("designTokens")
                document.head.appendChild(styleElement)
            }
        }
    }, [designTokens.length])

    useEffect(() => {
        $("div").on("scroll", function () {
            let ctx = canvasRef.current.getContext("2d");
            ctx.clearRect(0, 0, canvasRef.current.offsetWidth, canvasRef.current.offsetHeight)
        })
    }, [])

    return <>
        {layers?.length ? <RenderPageByLayers onDemo={true} showLayout={openLayout} /> : <div />}
        {ReactDOM.createPortal(<canvas ref={canvasRef} className={styles['handle-demo-page']} />, document.body)}
    </>
}

export const RenderPageView = () => {
    const location = useLocation()
    const onLoading = useSelector((store) => store.page.onLoading)
    const designTokens = useSelector((store) => store.designToken.data)
    const searchParams = new URLSearchParams(location.search)
    const _pageId = searchParams.get("pageid")
    const dispatch = useDispatch()

    useEffect(() => {
        PageActions.onReset(dispatch)
        PageActions.getPageDataById(dispatch, _pageId)
        DesignTokenActions.getData(dispatch)
    }, [])

    useEffect(() => {
        if (designTokens.length) {
            const tokenValues = designTokens.filter(e => e.Type !== DesignTokenType.group)
            let styleElement = document.head.querySelector(":scope > .designTokens") ?? document.createElement('style');
            styleElement.type = 'text/css';
            const _innerHTML = `html { \n${tokenValues.map(e => e.Value?.lightMode ? `--${e.Id}: ${e.Value.lightMode};` : "").join('\n')}\n }\n\nhtml.dark { \n${tokenValues.map(e => e.Value?.darkMode ? `--${e.Id}: ${e.Value.darkMode};` : "").join('\n')}\n }`
            styleElement.innerHTML = _innerHTML;
            if (!styleElement.classList.contains("designTokens")) {
                styleElement.classList.add("designTokens")
                document.head.appendChild(styleElement)
            }
        }
    }, [designTokens.length])

    return <>
        {onLoading ? <div /> : <RenderPageByLayers />}
    </>
}