import './view.css'
import PageView from './pageView'
import LeftView from './leftSidebar'
import RightView from './rightSidebar'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { PageActions } from './reducer'
import { TableController } from '../../wini/table/controller'
import DimensionsHeader from './dimensionsHeader'
import { DesignTokenActions } from '../../wini/designToken/reducer'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { socket } from '../../../socket'

export default function SettingPageView() {
    const location = useLocation()
    const { page, layers, layout } = useSelector((store) => store.page.data)
    const onLoading = useSelector((store) => store.page.onLoading)
    const [listPage, setListPages] = useState([])
    const searchParams = new URLSearchParams(location.search)
    const _pageId = searchParams.get("pageid")
    const dispatch = useDispatch()
    const methods = useForm({ shouldFocusError: false })

    useEffect(() => {
        PageActions.onReset(dispatch)
        const _pageController = new TableController("page")
        _pageController.getAll().then(res => {
            if (res.code === 200) setListPages(res.data.sort((a, b) => a.Sort - b.Sort))
        })
        DesignTokenActions.getData(dispatch)
    }, [])

    useEffect(() => {
        if (_pageId && listPage.length && page?.Id !== _pageId) PageActions.getPageDataById(dispatch, _pageId)
    }, [_pageId, page, listPage.length])

    useEffect(() => {
        document.documentElement.classList.add('dark')
        return () => { document.documentElement.classList.remove('dark') }
    }, [])

    useEffect(() => {
        if (page && layers) {
            socket.emit('page-demo', { pageid: _pageId, type: "SYNC", data: { page, layers, layout } })
        }
    }, [page, layers, layout])

    useEffect(() => {
        socket.emit('page-demo', { pageid: _pageId, type: "SELECTID", data: methods.getValues("selectedId") })
    }, [methods.watch("selectedId")])

    useEffect(() => {
        socket.emit('page-demo', { pageid: _pageId, type: "HOVERID", data: methods.getValues("hoverId") })
    }, [methods.watch("hoverId")])

    useEffect(() => {
        socket.emit('page-demo', { pageid: _pageId, type: "openLayout", data: methods.getValues("openLayout") })
    }, [methods.watch("openLayout")])

    return <div className="col setting-pages-view" style={{ flex: 1, width: '100%', height: '100%', backgroundColor: '#1d2428' }}>
        <DimensionsHeader />
        {onLoading ? null : <div className="row" style={{ flex: 1, height: '100%' }}>
            <LeftView listPage={listPage} onUpdatePage={setListPages} methods={methods} />
            {layers?.length && <>
                <PageView methods={methods} />
                <RightView methods={methods} />
            </>}
        </div>}
    </div>
}



