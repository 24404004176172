import styles from "./view.module.css"
import { Button, Pagination, Text, ToastMessage, Winicon } from "wini-web-components"
import { getTableConfig } from "../config"
import { FEDataType } from "../../wini/table/da"
import { NavLink } from "react-router-dom"
import ConfigApi from "../../../da/configApi"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { BaseDA, imgFileTypes } from "../../../da/baseDA"

export default function ReportResultTable({ module, cols = [], reducers = [], data = [], dataController, methods, rels = [] }) {
    return <div className="col table-view">
        <div className={`col table ${styles['demo-table']}`} style={{ width: "100%" }}>
            <div className="row header">
                {cols.map((_col, i) => {
                    const { _alignCell, _minW } = getTableConfig(_col)
                    return <div key={_col.Id} style={{ width: _col.Setting?.Width ?? _minW, order: _col.Setting.Sort, order: _col["_sort"] }} className='row header-cell' >
                        <Text className='heading-9' maxLine={1}>{_col.Setting.Title}</Text>
                        <div style={{ flex: 1 }} />
                    </div>
                })}
                {reducers.map((_red) => {
                    const { _alignCell, _minW } = getTableConfig(_red)
                    return <div key={`${_red.Id}`} className='row' style={{ width: _minW, order: _red["_sort"] }}>
                        <Text className='heading-9' style={{ flex: 1, textOverflow: "ellipsis" }} maxLine={1}>{_red.Title ?? _red.Name}</Text>
                    </div>
                })}
                <div className='row' style={{ order: 100 }}>
                    <Text className='heading-9' style={{ flex: 1, textOverflow: "ellipsis", textAlign: "center" }} maxLine={1}>Action</Text>
                </div>
            </div>
            {data.data.map((item) => <TableRow
                key={item.Id}
                module={module}
                item={item}
                cols={cols}
                reducers={reducers}
                files={methods.watch("_files")}
                groupData={methods.watch("groupData")}
                dataController={dataController}
                totalCount={methods.watch("__countChildren")?.find(e => e.ParentId === item.Id)?.["_count"]}
                rels={rels}
            />)}
        </div>
        <div className='row' style={{ height: '5.6rem', borderTop: "var(--neutral-bolder-border-color)", pointerEvents: "none" }}>
            <Pagination
                currentPage={1}
                itemPerPage={10}
                totalItem={data?.totalCount ?? 0}
            />
        </div>
    </div>
}

// #region TableRow
const TableRow = ({ item, module, cols = [], reducers = [], groupData = [], files = [], totalCount, dataController, rels = [], space = 0 }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [data, setData] = useState([])
    const methodsChild = useForm({ shouldFocusError: false })

    const getChildren = async (page) => {
        const res = await dataController.aggregateList({
            page: page ?? 1,
            size: 10,
            searchRaw: `@ParentId:{${item.Id}} `,
        })
        if (res.code === 200) {
            const colFiles = cols.filter(e => e.DataType === FEDataType.FILE).map(e => e.Name)
            if (colFiles.length) {
                const _fileId = methodsChild.getValues("_files") ?? []
                const _tmpFileIds = res.data.map(e => colFiles.map(c => e[c])).flat(Infinity).filter(id => id?.length && _fileId.every(e => e.id !== id) && !id.startsWith("http"))
                BaseDA.getFilesInfor(_tmpFileIds).then(res => {
                    if (res?.length) methodsChild.setValue("_files", [..._fileId, ...res.map(e => { return { ...e, type: e.type ? e.type : imgFileTypes.some(t => e.name.toLowerCase().endsWith(t)) ? "image" : "file" } })])
                })
            }
            dataController.groupByIds({
                ids: res.data.map(e => e.Id),
                reducers: reducers.map((e) => {
                    const _rel = rels.find(r => r.Id === e.RelativeId)
                    if (!_rel) return undefined
                    return {
                        Column: _rel.Column,
                        Name: e.Name,
                        Reducer: e.Reducer,
                        Query: e.Query,
                        ReducerBy: e.ReducerBy,
                        TbName: _rel.TableFK,
                    }
                })
            }).then(mapReducerData => {
                if (mapReducerData.code === 200) methodsChild.setValue("groupData", mapReducerData.data)
            })
            setData(page ? [...data, ...res.data] : res.data)
        } else {
            ToastMessage.errors(res.message)
        }
    }

    useEffect(() => {
        if (isOpen && totalCount !== data.length) getChildren()
    }, [totalCount])

    return <>
        <div className="row table-row">
            {cols.map((_col, i) => {
                const { _minW, _value } = getTableConfig(_col, item)
                if (_col.DataType == FEDataType.FILE && item[_col.Name] && files.length) {
                    var _fileInfor = files.find(e => e.id === item[_col.Name])
                    var _maxWidth = '28rem'
                }
                return <div key={`${_col.Id}-${item.Id}`} className={`row ${_col.Id}`} style={{ width: _col.Setting?.Width ?? _minW, order: _col["_sort"], maxWidth: _maxWidth }}>
                    {i ? null : <div style={{ paddingLeft: `${space}rem` }}>
                        {totalCount ? <button type='button' className='row icon-button20' onClick={() => {
                            if (!isOpen && !data.length) getChildren()
                            setIsOpen(!isOpen)
                        }}>
                            <Winicon src={isOpen ? "fill/arrows/triangle-down" : "fill/arrows/triangle-right"} size={"1.2rem"} />
                        </button> : <div style={{ width: '2rem' }} />}
                    </div>}
                    {
                        _fileInfor ? <NavLink to={ConfigApi.imgUrlId + _fileInfor.id} target='_blank' style={{ width: "100%" }}>
                            {_fileInfor.type === "image" ? <img alt={_fileInfor.name} src={ConfigApi.imgUrlId + _fileInfor.id} style={{ width: "100%" }} /> : <Text maxLine={1} style={{ color: "var(--primary-main-color)" }}>{_fileInfor.name}</Text>}
                        </NavLink> :
                            typeof _value === "string" || typeof _value === "number" ?
                                <Text maxLine={2} className='body-3' style={{ flex: 1, textOverflow: "ellipsis" }}>{_value ?? ""}</Text> :
                                _value
                    }
                </div>
            })}
            {reducers.map((_red) => {
                const { _minW, _value } = getTableConfig(_red, groupData.find(e => e[`_${module}Id`]?.includes(item.Id)) ?? {})
                return <div key={`${_red.Id}-${item.Id}`} className={`row ${_red.Id}`} style={{ minWidth: _minW, order: _red["_sort"] }}>
                    <Text maxLine={3} className='body-3'>{_value ?? ""}</Text>
                </div>
            })}
            <div className='row' style={{ gap: 12, justifyContent: 'center', order: 100 }}>
                <Winicon src='fill/user interface/d-edit' size={"1.6rem"} />
                <Winicon src='fill/text/menu-dots' style={{ rotate: "90deg" }} size={"1.6rem"} />
            </div>
        </div>
        {
            isOpen ? <>
                {data.map((child, i) => <TableRow
                    key={`${child.Id}-${i}`}
                    index={i}
                    item={child}
                    cols={cols}
                    rels={rels}
                    files={methodsChild.watch("_files")}
                    methods={methodsChild}
                    dataController={dataController}
                    space={space + 1.6}
                    module={module}
                    groupData={methodsChild.watch("groupData")}
                    reducers={reducers}
                />)}
                {totalCount > data.length ? <Button
                    label={`See more ${totalCount} items`}
                    onClick={() => { getChildren(Math.floor(data.length / 10) + 1) }}
                    style={{ position: "sticky", left: `${8.4 + space}rem`, width: "fit-content", padding: "0.4rem 0.8rem", color: "var(--primary-main-color)", margin: "0.8rem" }}
                /> : null}
                <div style={{ backgroundColor: 'var(--neutral-bolder-background-color)', width: "100%", height: 1, position: "sticky", left: 0 }} />
            </> : null
        }
    </>
}