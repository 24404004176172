import { FEDataType } from "../wini/table/da"

// report module
export interface ReportModelItem {
    Id: string,
    Name: string,
    TbName: string,
    DateCreated: number,
    Description?: string,
    Columns: string
}

export enum Reducers {
    COUNT = "COUNT",
    COUNT_DISTINCT = "COUNT_DISTINCT",
    COUNT_DISTINCTISH = "COUNT_DISTINCTISH",
    SUM = "SUM",
    MIN = "MIN",
    MAX = "MAX",
    AVG = "AVG",
    STDDEV = "STDDEV",
    QUANTILE = "QUANTILE",
    TOLIST = "TOLIST",
    FIRST_VALUE = "FIRST_VALUE",
    RANDOM_SAMPLE = "RANDOM_SAMPLE"
}

export interface ReducerItem {
    Id: string,
    Name: string,
    Title?: string,
    ReducerBy?: string,
    Query?: string,
    Reducer: string | Reducers,
    DataType: FEDataType,
    ModelId: string,
    RelativeId: string,
}


// dashboard module
export enum EChartType {
    // "area" | "line" | "donut" | "pie" | "radar" | "bar" | "scatter" | "bubble" | "polarArea" | "radialBar" | "heatmap" | "candlestick" | "boxPlot" | "rangeBar" | "rangeArea" | "treemap" | undefined
    line = 'line',
    bar = 'bar',
    horizontalBar = 'horizontal bar',
    scatter = 'scatter',
    bubble = 'bubble',
    radar = 'radar',
    area = 'area',
    doughnut = 'doughnut',
    pie = 'pie',
}

export enum FormType {
    formFullLeftLabelCol = "Form-full left-label col",
    formWithoutHeaderLeftLabelCol = "Form-without-header left-label col",
    formFullTopLabelCol = "Form-full top-label col",
    formWithoutHeaderTopLabelCol = "Form-without-header top-label col",
}

export enum CardType {
    cardNewsContainMediaVertical = "Card-news contain-media vertical",
    cardNewsCoverMediaVertical = "Card-news cover-media vertical",
    cardNewsHugMedia = "Card-news hug-media",// only vertical
    cardNewsContainMediaHorizontal = "Card-news contain-media horizontal",
    cardNewsCoverMediaHorizontal = "Card-news cover-media horizontal",
    cardNewsViewHorizontal = "Card-news view horizontal",
    cardProductContainMediaVertical = "Card-product contain-media vertical",
    cardProductCoverMediaVertical = "Card-product cover-media vertical",
    cardProductContainMediaHorizontal = "Card-product contain-media horizontal",
    cardProductCoverMediaHorizontal = "Card-product cover-media horizontal",
    cardMenu = "Card-menu",
}

export interface ChartItem {
    Id: string,
    Name: string,
    TbName: string,
    Title: string,
    Type?: EChartType,
    RelativeId: string,
    GroupBy?: string,
    ReducerBy?: string,
    DateCreated: number,
    Sort: number,
    Reducer: string | Reducers,
    DataType: FEDataType,
    Setting: string | { Datasets: Array<{ Id: string | number, Label?: string, BackgroundColor?: string }>, BorderWidth?: number, Legend?: 'top' | 'right' | 'bottom' | 'left' }
}

export interface FormItem {
    Id: string,
    Name: string,
    TbName: string,
    DateCreated: number,
    Description: string,
    Type: FormType,
    Props: string | { [p: string]: any },
}

export interface CardItem {
    Id: string,
    Name: string,
    TbName: string,
    DateCreated: number,
    Description: string,
    Type: CardType,
    Props: string | { [p: string]: any },
}

export interface PageItem {
    Id: string,
    Name: string,
    DateCreated: number,
    Sort: number,
    Url?: string,
    LayoutId?: string,
}

export interface LayerItem {
    Id: string,
    Name: string,
    DateCreated: number,
    Type: string,
    ParentId?: string,
    Setting?: string,
    PageId: string,
    ProjectId?: string
    LayoutId?: string,
}

export enum RoleType {
    create = "create",
    read = "read",
    edit = "edit",
    delete = "delete",
}