export default class ConfigApi {
    // static url = 'http://localhost:3000/api/'
    // static socketUrl = 'http://localhost:3000'
    // static url = 'https://server.wini.vn/api/'
    static url = 'https://apicore.wini.vn/api/'
    static socketUrl = 'https://apicore.wini.vn'
    static fileUrl = "https://file-mamager.wini.vn/api/";
    static imgUrl = "https://file-mamager.wini.vn/";
    static imgUrlId = "https://file-mamager.wini.vn/api/SystemFile/img/";
    static coreId = "3c932511de9d40a78535604415b1b7ff"
}

export const ConfigDomain = ["localhost", "wini.vn"]