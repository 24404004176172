import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { TableController } from "../table/controller"
import { Button, Checkbox, closePopup, ComponentStatus, Dialog, DialogAlignment, Pagination, Popup, showDialog, showPopup, Text, TextField, ToastMessage, Winicon } from "wini-web-components"
import { forwardRef, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { randomGID, Ultis } from "../../../Utils"
import { TextAreaForm, TextFieldForm } from "../../../project-component/component-form"
import WorkflowDetail from "./detail"
import PopupSearchIn from "../../module/manager/seachIn"

export default function WorkflowPage() {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")

    return id ? <WorkflowDetail /> : <ListWorkflow />
}

const searchkeyVl = "keysearch";
function ListWorkflow() {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const _wfController = new TableController('workflow')
    const ref = useRef()
    const dialogRef = useRef()
    const [data, setData] = useState({ data: [], totalCount: undefined })
    const [pageDetails, setPageDetails] = useState({ page: 1, size: 10 })
    const methods = useForm({ shouldFocusError: false })
    const navigate = useNavigate()

    const getData = async (page, size) => {
        let _query = []
        for (const [key, value] of searchParams) {
            if (key == searchkeyVl) {
                methods.setValue('search-key-value', value)
            }
            if (key == "searchin") {
                const listKeySearch = value.split(',')
                if (listKeySearch.length > 0) {
                    _query.push(`${listKeySearch.map(element => `(@${element}:*${searchParams.get(searchkeyVl)}*)`).join(" | ")}`)
                }
            }
        }

        const res = await _wfController.getListSimple({
            page: page ?? pageDetails.page, size: size ?? pageDetails.size,
            query: _query.length ? _query.join(" ") : "*",
        })
        if (res.code === 200) {
            setData({ data: res.data, totalCount: res.totalCount })
        } else {
            ToastMessage.errors(res.message)
        }
    }

    const drawerSettingOverview = (item) => {
        showPopup({
            ref: ref,
            hideButtonClose: true,
            style: { position: "absolute", width: "59.4rem", height: "100dvh", maxHeight: "100dvh", right: 0, top: 0, borderRadius: 0 },
            content: <DrawerSetting
                ref={ref}
                item={item}
                onEdit={(newItem) => {
                    setData({
                        data: data.data.map(e => e.Id === item.Id ? newItem : e),
                        totalCount: data.totalCount
                    })
                }}
            />
        })
    }

    const searchKeyAction = async (e) => {
        if (e.target.value.length == 0) {
            searchParams.delete(searchkeyVl)
        } else {
            if (searchParams.has(searchkeyVl))
                searchParams.set(searchkeyVl, e.target.value.trim());
            else searchParams.append(searchkeyVl, e.target.value.trim());
        }
        navigate('?' + searchParams.toString());

        getData(pageDetails.page, pageDetails.size)
    }

    const showSearchBox = (event) => {
        var listFilterSearch = [{ Id: "0", Name: "Name", Setting: { Title: "Name", isChecked: false } }, { Id: "1", Name: "Description", Setting: { Title: "Description", isChecked: false } }]

        for (const [key, value] of searchParams) {
            if (key === "searchin") {
                const valueList = value.split(',')
                if (valueList) {
                    listFilterSearch.forEach(element => {
                        if (valueList.find((e) => e == element.Setting.Title)) {
                            element.Setting.isChecked = true
                        }
                    });
                }
            }
        }
        const searchInput = event.target.closest('div:has(>input)')?.getBoundingClientRect()
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', width: searchInput?.width, top: searchInput?.bottom, left: searchInput?.x, bottom: 0, maxHeight: '100%' },
            content: <PopupSearchIn ref={ref} cols={listFilterSearch} />
        })
    }

    useEffect(() => {
        if (Ultis.getCookie("pid")) getData()
    }, [])

    return <div className="col">
        <Popup ref={ref} />
        <Dialog ref={dialogRef} />
        <div className='row' style={{ padding: '2rem 3.2rem', gap: '0.8rem', borderBottom: 'var(--neutral-bolder-border-color)' }}>
            <button type='button' className='row' style={{ gap: '0.8rem' }}>
                <Winicon src="outline/files/folder" size={'2.2rem'} />
                <Text className='heading-6'>Workflow</Text>
                <Winicon src={"fill/arrows/down-arrow"} size={"1.2rem"} />
            </button>
        </div>
        <div className="col table-view" style={{ padding: '0 3.2rem 1.2rem' }}>
            <div className='row' style={{ padding: '1.2rem 0', gap: "0.8rem" }}>
                <div className='row' style={{ height: '3.2rem', alignItems: "stretch", border: "var(--neutral-bolder-border-color)", borderRadius: '0.8rem' }} >
                    <button type='button' className='row' style={{ padding: '0 1.2rem', borderRight: "var(--neutral-bolder-border-color)", gap: '0.8rem' }} onClick={() => { drawerSettingOverview() }}>
                        <Winicon src={"outline/user interface/e-add"} size={"1.4rem"} />
                        <Text className='button-text-3' style={{ color: "var(--neutral-text-subtitle-color)" }}>Add workflow</Text>
                    </button>
                    <div className='row' style={{ padding: '0.8rem' }}>
                        <Winicon src={"fill/arrows/down-arrow"} size={"1.2rem"} />
                    </div>
                </div>
                <div style={{ flex: 1 }} />
                <TextField
                    placeholder='Search'
                    prefix={<Winicon src={"fill/development/zoom"} size={"1.4rem"} />}
                    suffix={<button type='button' onClick={showSearchBox}>
                        <Winicon src={"fill/arrows/down-arrow"} size={"1.2rem"} />
                    </button>}
                    name='txtSearch'
                    onComplete={searchKeyAction}
                    defaultValue={`${searchParams.get(searchkeyVl) ?? ''}`}
                    register={methods.register('search-key-value')}
                    style={{ padding: '0 1.6rem', borderRadius: '0.8rem', height: '3.2rem', width: '26.8rem', gap: '1.2rem' }}
                />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { }}>
                    <Winicon src='outline/user interface/setup-preferences' size={'1.6rem'} />
                    <Text className='button-text-3'>Filter</Text>
                </button>
                <div className='row divider' style={{ height: '1.2rem', margin: 0 }} />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { }}>
                    <Winicon src='fill/arrows/enlarge' size={'1.6rem'} />
                    <Text className='button-text-3'>Sort</Text>
                </button>
            </div>
            <div className={`col table`}>
                <div className={`row header`}>
                    <div key={'h0'} className='row' style={{ flex: 1, minWidth: '24rem' }}>
                        <Checkbox size={'1.6rem'} value={false} onChange={(v) => { }} />
                        <div className='row' style={{ height: '100%', gap: '1.2rem', paddingLeft: "0.4rem", flex: 1 }}>
                            <Text className='heading-9' style={{ flex: 1, textOverflow: "ellipsis" }} maxLine={1}>Name</Text>
                        </div>
                    </div>
                    <div key={'h2'} className='row' style={{ width: '20rem' }}>
                        <Text className='heading-9' >Created date</Text>
                    </div>
                    <div key={'h3'} className='row' style={{ width: '40rem' }}>
                        <Text className='heading-9'>Description</Text>
                    </div>
                    <div key={'hlast'} className='row' style={{ width: "14rem" }}>
                        <Text className='heading-9' style={{ flex: 1, textAlign: "center" }}>Action</Text>
                    </div>
                </div>
                {
                    data.data.map((item, index) => <div key={`${item.Id}`} className='row'>
                        <div key={'b0-' + index} className='row' style={{ flex: 1, minWidth: '24rem' }}>
                            <Checkbox size={'1.6rem'} value={false} onChange={(v) => { }} />
                            <NavLink to={`?id=${item.Id}`} style={{ flex: 1 }}><Text className='body-3' style={{ textOverflow: "ellipsis", color: "var(--primary-main-color", width: "100%" }} maxLine={1}>{item.Name}</Text></NavLink>
                        </div>
                        <div key={'b2-' + index} className='row' style={{ width: '20rem' }}>
                            <Text className='body-3'>{item.DateCreated ? Ultis.datetoString(new Date(item.DateCreated), "dd/mm/yyyy hh:mm") : "-"}</Text>
                        </div>
                        <div key={'b3-' + index} className='row' style={{ width: '40rem' }}>
                            <Text className='body-3'>{item.Description ?? "-"}</Text>
                        </div>
                        <div key={'blast-' + index} className='row' style={{ gap: "0.8rem", justifyContent: 'center', width: "14rem" }} >
                            <Winicon src='outline/user interface/edit' size={'1.8rem'} style={{ padding: "0.3rem" }} onClick={() => { drawerSettingOverview(item) }} />
                            <Winicon
                                src='outline/user interface/trash-can'
                                size={'1.8rem'}
                                style={{ padding: "0.3rem" }}
                                onClick={(ev) => {
                                    showDialog({
                                        ref: dialogRef,
                                        alignment: DialogAlignment.center,
                                        status: ComponentStatus.WARNING,
                                        title: 'Are you sure to delete ' + item.Name + ' workflow?',
                                        content: `You will not be able to recover data or setting of ${item.Name} workflow!`,
                                        submitTitle: "Delete",
                                        onSubmit: async () => {
                                            const res = await _wfController.delete([item.Id])
                                            if (res.code === 200) {
                                                ToastMessage.success(`Delete workflow ${item.Name} successfully!`)
                                                getData()
                                            } else {
                                                ToastMessage.errors(res.message)
                                            }
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>)
                }
            </div>
            <div style={{ padding: '1.2rem 2.4rem' }}>
                <Pagination
                    /// Size
                    currentPage={pageDetails.page}
                    /// pageSize
                    itemPerPage={pageDetails.size}
                    // data.total
                    totalItem={data?.totalCount ?? 0}
                    /// action
                    onChangePage={(page, size) => {
                        if (pageDetails.page !== page || pageDetails.size !== size) {
                            setPageDetails({ page: page, size: size });
                            getData(page, size)
                        }
                    }}
                />
            </div>
        </div>
    </div>
}

const DrawerSetting = forwardRef(function DrawerSetting(data, ref) {
    const _wfController = new TableController('workflow')
    const methods = useForm({ shouldFocusError: false })
    const navigate = useNavigate()

    const onSubmit = async (ev) => {
        let values = { ...ev }
        values.Id ??= randomGID()
        values.DateCreated ??= Date.now()
        if (data.item) {
            closePopup(ref)
            const wfRes = await _wfController.edit([values])
            if (wfRes.code === 200) {
                ToastMessage.success('Update workflow successfully!')
                data.onEdit(values)
            } else ToastMessage.errors(wfRes.message)
        } else {
            const _stageController = new TableController('stage')
            const wfRes = await _wfController.add([values])
            if (wfRes.code === 200) {
                const newStages = [{
                    Id: randomGID(),
                    Name: 'Stage 1',
                    DateCreated: Date.now(),
                    Description: "stage 1",
                    Sort: 0,
                    WorkflowId: values.Id,
                }]
                newStages.push(...Array.from({ length: 2 }).map((_, i) => {
                    return {
                        Id: randomGID(),
                        Name: 'Section ' + (i + 1),
                        DateCreated: Date.now(),
                        Description: 'section ' + (i + 1),
                        Sort: i,
                        WorkflowId: values.Id,
                        ParentId: newStages[0].Id
                    }
                }))
                const sgRes = await _stageController.add(newStages)
                if (sgRes.code === 200) {
                    navigate(`?id=${values.Id}`)
                } else ToastMessage.errors(sgRes.message)
            } ToastMessage.errors(wfRes.message)
        }
    }

    useEffect(() => {
        if (data.item) {
            Object.keys(data.item).forEach(prop => {
                methods.setValue(prop, data.item[prop])
            })
        }
    }, [data.item])

    return <div className='col drawer' style={{ flex: 1, height: "100%" }}>
        <div className="popup-header row" style={{ gap: "0.8rem" }}>
            <Winicon src="outline/user interface/fav-list" size={"1.6rem"} color="var(--neutral-text-title-color)" />
            <Text className='heading-6' style={{ flex: 1 }}>Create business flow</Text>
            <div className="row" style={{ gap: 4 }}>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }}>
                    <Winicon src="fill/user interface/menu-dots" />
                </button>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }}>
                    <Winicon src="fill/arrows/arrows-maximize" />
                </button>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }}>
                    <Winicon src="fill/arrows/refresh" />
                </button>
                <button type="button" className="icon-button24 row" style={{ backgroundColor: 'var(--neutral-main-background-color)', borderRadius: "50%" }} onClick={() => { closePopup(ref) }}>
                    <Winicon src="fill/user interface/e-remove" />
                </button>
            </div>
        </div>
        <div className="col" style={{ flex: 1, padding: "2.4rem", gap: "2rem", overflow: "hidden auto" }}>
            <TextFieldForm
                required
                name="Name"
                errors={methods.formState.errors}
                register={methods.register}
                label="Business flow name"
            />
            <TextAreaForm
                name="Description"
                errors={methods.formState.errors}
                register={methods.register}
                style={{ height: "18rem" }}
                label="Input some description..."
            />
        </div>
        <div className="row popup-footer">
            <Button
                label="Clear"
                style={{ width: "7.2rem", borderRadius: '0.8rem', backgroundColor: "var(--neutral-main-background-color)", color: "var(--neutral-text-subtitle-color)" }}
                onClick={() => { methods.reset() }}
            />
            <div style={{ flex: 1 }} />
            <Button
                label="Cancel"
                style={{ width: "7.2rem", borderRadius: '0.8rem', backgroundColor: "var(--neutral-main-background-color)", color: "var(--neutral-text-subtitle-color)" }}
                onClick={() => { closePopup(ref) }}
            />
            <Button
                label={data.item ? "Save" : "Create"}
                className="button-primary"
                style={{ width: "5.8rem", borderRadius: '0.8rem' }}
                onClick={methods.handleSubmit(onSubmit)}
            />
        </div>
    </div>
})