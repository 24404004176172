import { useEffect, useRef, useState } from "react"
import { SettingDataController } from "../../module/controller"
import { Checkbox, ComponentStatus, Dialog, DialogAlignment, Pagination, Popup, showDialog, showPopup, Text, ToastMessage, Winicon } from "wini-web-components"
import { Ultis } from "../../../Utils"
import { SettingModel } from "../../module/report/settingModel"

export default function SettingReportTab({ item }) {
    const _modelDataController = new SettingDataController("model")
    const [data, setData] = useState({ data: [], totalCount: undefined })
    const [pageDetails, setPageDetails] = useState({ page: 1, size: 10 })
    const [selectedIds, setSelectedIds] = useState([])
    const dialogRef = useRef()
    const popupRef = useRef()

    const showPopupAddEditReport = (id) => {
        showPopup({
            ref: popupRef,
            style: { width: '100dvw', height: '100dvh', maxWidth: '100dvw', maxHeight: '100dvh', borderRadius: 0 },
            hideButtonClose: true,
            content: <SettingModel ref={popupRef} id={id} item={item} onSuccess={getData} />
        })
    }

    const getData = async (page, size) => {
        const res = await _modelDataController.getListSimple({ page: page ?? pageDetails.page, size: size ?? pageDetails.size, query: `@TbName:{${item.Name}}` })
        if (res.code === 200) setData({ data: res.data, totalCount: res.totalCount })
    }

    useEffect(() => { getData() }, [])

    return <div className="col" style={{ flex: 1, width: '100%', height: '100%', padding: "1.6rem 2.4rem", gap: '2.4rem' }}>
        <Dialog ref={dialogRef} />
        <Popup ref={popupRef} />
        <div className="row" style={{ gap: "0.8rem" }}>
            <Text className="heading-7" style={{ flex: 1 }}>Report model</Text>
            <button type="button" className="row button-primary" style={{ padding: '0.4rem 0.8rem' }} onClick={() => { showPopupAddEditReport() }}>
                <Winicon src={"fill/user interface/e-add"} size={"1.4rem"} />
                <Text className="button-text-3">Create</Text>
            </button>
        </div>
        <div className="col table-view">
            <div className="col table">
                <div className="row header">
                    <div className='row header-cell' style={{ minWidth: '40rem', flex: 1, gap: '1.6rem' }}>
                        <Checkbox
                            size={'1.6rem'}
                            value={selectedIds.length && selectedIds.every(id => data.data.some(e => e.Id === id))}
                            onChange={(v) => {
                                if (v) { setSelectedIds(data.data.map(e => e.Id)) }
                                else setSelectedIds([])
                            }}
                        />
                        <Text className='heading-9'>Name</Text>
                    </div>
                    <div className="row header-cell" style={{ width: '22rem' }}>
                        <Text className='heading-9'>Created date</Text>
                    </div>
                    <div className="row header-cell" style={{ width: '36rem', }} >
                        <Text className='heading-9'>Data columns</Text>
                    </div>
                    <div className="row header-cell" style={{ width: '32rem', }} >
                        <Text className='heading-9'>Description</Text>
                    </div>
                    <div className="row header-cell" style={{ width: '9.6rem', justifyContent: "center" }} >
                        <Text className='heading-9'>Action</Text>
                    </div>
                </div>
                {data.data.map((item) => {
                    const _props = typeof item.Columns === "string" ? JSON.parse(item.Columns) : item.Columns
                    return <div key={item.Id} className="row table-row">
                        <div className="row table-cell" style={{ minWidth: '40rem', flex: 1, }} >
                            <div className='row' style={{ width: '100%', gap: '1.6rem' }}>
                                <Checkbox size={'1.6rem'} value={selectedIds.some(id => item.Id === id)} onChange={(v) => {
                                    if (v) setSelectedIds([...selectedIds, item.Id])
                                    else setSelectedIds(selectedIds.filter(id => id !== item.Id))
                                }} />
                                <Text className='heading-9' >{item.Name}</Text>
                            </div>
                        </div>
                        <div className="row table-cell" style={{ width: '22rem' }}>
                            <Text className='heading-9' >{Ultis.datetoString(new Date(item.DateCreated))}</Text>
                        </div>
                        <div className="row table-cell" style={{ width: '36rem' }}  >
                            <Text className='heading-9' maxLine={1}>{Object.keys(_props).filter(k => _props[k] >= 0).join(", ")}</Text>
                        </div>
                        <div className="row table-cell" style={{ width: '32rem' }}>
                            <Text className='heading-9' >{item.Description}</Text>
                        </div>
                        <div className='row table-cell' style={{ gap: '1.6rem', justifyContent: 'center', width: "9.6rem" }}>
                            <Winicon src={"outline/user interface/edit"} onClick={() => { showPopupAddEditReport(item.Id) }} />
                            <Winicon src={"fill/user interface/trash-can"} onClick={() => {
                                showDialog({
                                    ref: dialogRef,
                                    alignment: DialogAlignment.center,
                                    status: ComponentStatus.WARNING,
                                    title: 'Confirm delete',
                                    content: `Are you sure you want to delete ${item.Name}?`,
                                    submitTitle: 'Delete',
                                    onSubmit: async () => {
                                        const res = await _modelDataController.action("delete", { ids: [item.Id] })
                                        if (res.code === 200) {
                                            ToastMessage.success(`Delete model ${item.Name} successfully!`)
                                            getData()
                                        } else {
                                            ToastMessage.errors(res.message)
                                        }
                                    }
                                })
                            }} />
                        </div>
                    </div>
                })}
            </div>
            <div style={{ padding: '1.2rem 2.4rem' }}>
                <Pagination
                    /// Size
                    currentPage={pageDetails.page}
                    /// pageSize
                    itemPerPage={pageDetails.size}
                    // data.total
                    totalItem={data.totalCount ?? 0}
                    /// action
                    onChangePage={(page, size) => {
                        if (pageDetails.page !== page || pageDetails.size !== size) {
                            setPageDetails({ page: page, size: size });
                            getData(page, size)
                        }
                    }}
                />
            </div>
        </div>
    </div>
}