import styles from '../view.module.css'
import indexStyles from './index.module.css'
import { forwardRef, useEffect, useRef } from "react"
import { SettingDataController } from "../../../module/controller"
import { useFieldArray, useForm } from "react-hook-form"
import { randomGID, Ultis } from "../../../../Utils"
import { ComponentType } from "../da"
import { Button, closePopup, ComponentStatus, Dialog, DialogAlignment, showDialog, Text, ToastMessage, Winicon } from "wini-web-components"
import { OutlineButton, OutlineContainer } from "../../../../assets/icon"
import LayerTile from "./layerTile"
import RenderCard from './renderCard'
import CardInforContainer from './cardInfor'

export const DrawerSettingCard = forwardRef(function DrawerSettingCard(data, ref) {
    const _settingDataController = new SettingDataController("card")
    const methods = useForm({ shouldFocusError: false, defaultValues: { TbName: data.TbName } })
    const methodProps = useForm({
        shouldFocusError: false, defaultValues: {
            selectedId: undefined,
            hoverId: undefined,
            layers: [{ Id: randomGID(), Type: ComponentType.container, DateCreated: Date.now(), ProjectId: Ultis.getCookie("pid"), Name: "New card", Setting: { style: { backgroundColor: "#fff" }, className: "col" } }]
        }
    })
    const _layers = useFieldArray({
        name: "layers",
        control: methodProps.control,
        keyName: undefined
    })
    const dialogRef = useRef()
    const demoCardRef = useRef()
    const componentType = [ComponentType.container, ComponentType.text, ComponentType.button, ComponentType.img, ComponentType.icon, ComponentType.navLink]

    const _onSubmit = (ev) => {
        showDialog({
            ref: dialogRef,
            alignment: DialogAlignment.center,
            status: ComponentStatus.WARNING,
            title: 'Confirm save',
            content: "Every changes will be applied on this card",
            submitTitle: "Submit",
            onSubmit: async () => {
                let _cardData = { ...ev, Props: methodProps.getValues("layers") }
                _cardData.Props = JSON.stringify(_cardData.Props.map(e => {
                    if (e.Setting.className?.length) {
                        e.Setting.className = e.Setting.className.split(" ").filter(e => e !== "layer-item").join(" ")
                    }
                    return e
                }))
                delete _cardData.id
                _cardData.Id ??= randomGID()
                _cardData.DateCreated ??= Date.now()
                const res = await _settingDataController.action("add", { data: [_cardData] })
                if (res.code !== 200) return ToastMessage.errors(res.message)
                data.onSubmit()
                closePopup(ref)
            }
        })
    }

    useEffect(() => {
        if (data.cardItem) {
            Object.keys(data.cardItem).forEach((p) => {
                if (data.cardItem[p] !== undefined) {
                    methods.setValue(p, data.cardItem[p])
                    if (p === "Props") {
                        const _props = typeof data.cardItem[p] === "string" ? JSON.parse(data.cardItem[p]) : data.cardItem[p]
                        methodProps.setValue("layers", _props.map(e => {
                            if (!e.ParentId) {
                                delete e.Setting.style.width
                                delete e.Setting.style.height
                            }
                            return e
                        }))
                    }
                }
            })
        }
    }, [])

    const handleDragToTarget = (ev) => {
        ev.preventDefault()
        const _parent = ev.target.closest('div[component-type="Container"]')
        let _demo = demoCardRef.current.querySelector(`div[class*="demo-component-in-container"]`)
        if (_parent?.id?.length === 32) {
            let _children = [..._parent.children].filter(e => !e.classList.contains(styles['demo-component-in-container'])).sort((a, b) => parseInt(window.getComputedStyle(a).order ?? 0) - parseInt(window.getComputedStyle(b).order ?? 0))
            if (!_demo) {
                _demo = document.createElement("div")
                _demo.className = styles['demo-component-in-container']
            }
            const _direction = window.getComputedStyle(_parent).flexDirection
            let _order = 0
            let _distance = 0
            if (_direction === "column") {
                _demo.style.height = '0.3rem'
                _demo.style.width = "2rem"
                _demo.style.maxWidth = "100%"
                if (_children.length) {
                    let closestHTML = [..._children].sort((aHTML, bHTML) => {
                        let aRect = aHTML.getBoundingClientRect()
                        let bRect = bHTML.getBoundingClientRect()
                        let a_center_oy = Math.abs(ev.pageY - (aRect.y + aRect.height / 2))
                        let b_center_oy = Math.abs(ev.pageY - (bRect.y + bRect.height / 2))
                        return a_center_oy - b_center_oy
                    })[0]
                    if (closestHTML) {
                        let htmlRect = closestHTML.getBoundingClientRect()
                        _order = closestHTML.style.order
                        _distance = ev.pageY - (htmlRect.y + htmlRect.height / 2)
                        if (_distance < 0) _order--
                    } else _order = _children.length - 1
                }
            } else {
                _demo.style.width = '0.3rem'
                _demo.style.height = "2rem"
                _demo.style.maxHeight = "100%"
                if (_children.length) {
                    let isWrap = window.getComputedStyle(_parent).flexWrap === 'wrap'
                    let closestHTML = [..._children].sort((aHTML, bHTML) => {
                        let aRect = aHTML.getBoundingClientRect()
                        let bRect = bHTML.getBoundingClientRect()
                        let a_center_ox = Math.abs(ev.pageX - (aRect.x + aRect.width / 2))
                        let b_center_ox = Math.abs(ev.pageX - (bRect.x + bRect.width / 2))
                        if (isWrap) {
                            a_center_ox = Math.sqrt(Math.pow(ev.pageX - (aRect.x + aRect.width / 2), 2) + Math.pow(ev.pageY - (aRect.y + aRect.height / 2), 2))
                            b_center_ox = Math.sqrt(Math.pow(ev.pageX - (bRect.x + bRect.width / 2), 2) + Math.pow(ev.pageY - (bRect.y + bRect.height / 2), 2))
                        }
                        return a_center_ox - b_center_ox
                    })[0]
                    if (isWrap) closestHTML = _children.find(childHTML => childHTML.getBoundingClientRect().bottom >= ev.pageY)
                    if (closestHTML) {
                        let htmlRect = closestHTML.getBoundingClientRect()
                        _order = closestHTML.style.order
                        _distance = ev.pageX - (htmlRect.x + htmlRect.width / 2)
                        if (_distance < 0) _order--
                    } else _order = _children.length - 1
                }
            }
            _demo.style.order = _order
            if (_demo.parentElement !== _parent) _parent.appendChild(_demo)
        } else if (_demo) _demo.parentElement.removeChild(_demo)
    }

    const handleOnClick = (ev) => {
        ev.preventDefault()
        const _layerItem = ev.target.closest('.layer-item')
        if (_layerItem?.id) {
            methodProps.setValue("reload", true)
            methodProps.setValue("selectedId", _layerItem?.id)
        }
    }

    const handleOnMouseMove = (ev) => {
        ev.stopPropagation()
        ev.preventDefault()
        if (ev.buttons === 1) {
            if (ev.target.parentElement === demoCardRef.current || (ev.movementX <= 4 && ev.movementY <= 4)) return handleOnClick(ev)
            let _layerItem = demoCardRef.current.querySelector(".dragging.layer-item")
            if (_layerItem) {
                handleDragToTarget(ev, true)
            } else {
                const _layerElement = demoCardRef.current.querySelectorAll(`.layer-item[id="${methodProps.getValues("selectedId")}"]`)
                _layerElement.forEach((e, i) => {
                    e.classList.add('dragging')
                    e.style.display = 'none'
                })
                demoCardRef.current.classList.add("dragging")
            }
        } else {
            const _layerItem = ev.target.closest('.layer-item')
            if (_layerItem) methodProps.setValue("hoverId", _layerItem.id)
        }
    }

    const handleOnMouseUp = () => {
        let _layerItem = demoCardRef.current.querySelector(".dragging.layer-item")
        if (_layerItem) {
            let _demo = demoCardRef.current.querySelector(`div[class*="demo-component-in-container"]`)
            if (_demo) {
                const _parent = _demo.parentElement
                const _children = [..._parent.children].filter(e => e.id !== _layerItem.id).sort((a, b) => parseInt(window.getComputedStyle(a).order) - parseInt(window.getComputedStyle(b).order))
                const _layerIds = []
                _children.forEach(e => {
                    const _id = e.id?.length === 32 ? e.id : _layerItem.id
                    if (_layerIds.every(id => _id !== id)) _layerIds.push(_id)
                })
                const updateChildren = demoCardRef.current.querySelectorAll(`.layer-item[id="${_layerItem.id}"]`)
                updateChildren.forEach(e => {
                    e.classList.remove('dragging')
                    e.style.display = ''
                })
                methodProps.setValue("layers", _layers.fields.map(e => {
                    let _tmp = { ...e, Setting: { ...e.Setting, style: { ...e.Setting.style } } }
                    if (e.Id === _layerItem.id) {
                        _tmp.ParentId = _parent.id
                        _tmp.Setting.style.order = _demo.style.order
                    } else if (e.ParentId === _parent.id) {
                        let _idx = _layerIds.findIndex(id => e.Id === id)
                        _tmp.Setting.style.order = _idx
                    }
                    return _tmp
                }))
                _demo.remove()
            }
        }
        demoCardRef.current.classList.remove("dragging")
        methodProps.setValue("reload", true)
    }

    const renderComponentOption = (prop) => {
        switch (prop) {
            case ComponentType.container:
                icon = <OutlineContainer size={'2.8rem'} />
                break;
            case ComponentType.text:
                var icon = <Winicon src='fill/text/text' />
                break;
            case ComponentType.button:
                icon = <OutlineButton size={'2.8rem'} />
                break;
            case ComponentType.img:
                icon = <Winicon src={"fill/development/image"} />
                break;
            case ComponentType.icon:
                icon = <Winicon src={"fill/development/icon"} />
                break;
            case ComponentType.navLink:
                icon = <Winicon src={"fill/user interface/link"} />
                break;
            default:
                return null
        }
        return <div key={prop} draggable className={`col col8 ${indexStyles['component-options']}`} style={{ alignItems: 'center' }}
            onDragStart={(ev) => { ev.dataTransfer.setData("component-type", prop) }}
            onDragEnd={(ev) => { demoCardRef.current.querySelector(`div[class*="demo-component-in-container"]`)?.remove() }}
        >
            {icon}
            <Text className="regular1" maxLine={1}>{prop}</Text>
        </div>
    }

    const onDrop = (ev) => {
        ev.preventDefault()
        let _demo = demoCardRef.current.querySelector(`div[class*="demo-component-in-container"]`)
        if (_demo) {
            const _parent = _demo.parentElement
            const _children = [..._parent.children].sort((a, b) => parseInt(window.getComputedStyle(a).order) - parseInt(window.getComputedStyle(b).order))
            const _compType = ev.dataTransfer.getData('component-type')
            let newLayer = {
                Id: randomGID(),
                DateCreated: Date.now(),
                ParentId: _parent.id,
                Name: _compType,
                Type: _compType,
                Setting: { style: { order: _children.findIndex(el => !el.id) } }
            }
            switch (_compType) {
                case ComponentType.container:
                    newLayer.Setting.className = "row"
                    if (window.getComputedStyle(_parent).flexDirection === "row") {
                        newLayer.Setting.style.height = '4.8rem'
                        newLayer.Setting.style.width = '8rem'
                    } else {
                        newLayer.Setting.style.width = '4.8rem'
                        newLayer.Setting.style.height = '8rem'
                    }
                    break;
                case ComponentType.img:
                    newLayer.Setting.src = "https://file-mamager.wini.vn/Upload/2024/09/yuy_81f2.jpg"
                    newLayer.Setting.style = { borderRadius: '0.8rem', width: '2.4rem', height: '2.4rem', ...newLayer.Setting.style }
                    break;
                case ComponentType.text:
                    newLayer.Setting.value = "Text"
                    break;
                case ComponentType.button:
                    newLayer.Setting.label = "Button"
                    break;
                case ComponentType.icon:
                    newLayer.Setting.src = "fill/development/icon"
                    break;
                default:
                    break;
            }
            methodProps.setValue("layers", [...methodProps.getValues("layers").map((e) => {
                const _index = _children.findIndex(el => el.id === e.Id)
                if (_index >= 0) return { ...e, Setting: { ...e.Setting, style: { ...e.Setting.style, order: _index } } }
                return e
            }), newLayer])
            _parent.removeChild(_demo)
        }
    }

    useEffect(() => {
        const _selectedId = methodProps.getValues("selectedId")
        if (methodProps.getValues("reload")) {
            if (_selectedId) {
                const _rect = demoCardRef.current.getBoundingClientRect()
                const _layerRect = demoCardRef.current.querySelector(`*[id="${_selectedId}"]`).getBoundingClientRect()
                demoCardRef.current.style.setProperty('--selectedX', `${_layerRect.x - _rect.x - 4}px`)
                demoCardRef.current.style.setProperty('--selectedY', `${_layerRect.y - _rect.y - 4}px`)
                demoCardRef.current.style.setProperty('--selectedW', `${_layerRect.width}px`)
                demoCardRef.current.style.setProperty('--selectedH', `${_layerRect.height}px`)
                demoCardRef.current.setAttribute("selected-name", _layers.fields.find(e => e.Id === _selectedId)?.Name ?? "")
            }
            methodProps.setValue("reload", null)
        } else if (_selectedId) {
            const handleOnKeyDown = (ev) => {
                if (!['input', 'textarea'].includes(ev.target.localName)) {
                    const _element = demoCardRef.current.querySelector(`:scope > div .layer-item[id="${_selectedId}"]`)
                    if (_element) {
                        let _deleteIds = []
                        switch (ev.key.toLowerCase()) {
                            case 'delete':
                                _deleteIds = [..._element.querySelectorAll('*[id]')].map(e => e.id).filter(id => id.length === 32)
                                _deleteIds.push(_selectedId)
                                if (_deleteIds.length) {
                                    methodProps.setValue("selectedId", null)
                                    methodProps.setValue("layers", methodProps.getValues("layers").filter(e => !_deleteIds.includes(e.Id)))
                                }
                                break;
                            case 'backspace':
                                _deleteIds = [..._element.querySelectorAll('*[id]')].map(e => e.id).filter(id => id.length === 32)
                                _deleteIds.push(_selectedId)
                                if (_deleteIds.length) {
                                    methodProps.setValue("selectedId", null)
                                    methodProps.setValue("layers", methodProps.getValues("layers").filter(e => !_deleteIds.includes(e.Id)))
                                }
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            window.onkeydown = handleOnKeyDown
            return () => { window.onkeydown = undefined }
        } else {
            demoCardRef.current.removeAttribute("selected-name")
        }
    }, [methodProps.watch("selectedId"), methodProps.watch("reload")])

    useEffect(() => {
        const _hoverId = methodProps.getValues("hoverId")
        if (_hoverId) {
            const _rect = demoCardRef.current.parentElement.getBoundingClientRect()
            const _layerRect = demoCardRef.current.querySelector(`.layer-item[id="${_hoverId}"]`)?.getBoundingClientRect()
            demoCardRef.current.parentElement.style.setProperty('--hoverX', `${_layerRect.x - _rect.x}px`)
            demoCardRef.current.parentElement.style.setProperty('--hoverY', `${_layerRect.y - _rect.y}px`)
            demoCardRef.current.parentElement.style.setProperty('--hoverW', `${_layerRect.width}px`)
            demoCardRef.current.parentElement.style.setProperty('--hoverH', `${_layerRect.height}px`)
            demoCardRef.current.parentElement.setAttribute("hover-name", _layers.fields.find(e => e.Id === _hoverId)?.Name ?? "")
        }
    }, [methodProps.watch("hoverId")])

    return <div className={`col ${indexStyles['setting-card-item-container']}`}>
        <Dialog ref={dialogRef} />
        <div className='row popup-header' style={{ padding: '1.2rem 1.2rem 1.2rem 2.4rem' }}>
            <Text className='heading-7' style={{ flex: 1 }}>{data.cardItem?.Name ?? "New card"}</Text>
            <Winicon src={"fill/user interface/e-remove"} size={'2rem'} style={{ padding: "0.4rem" }} onClick={() => { closePopup(ref) }} />
        </div>
        <div className='row' style={{ alignItems: 'start', height: "100%", flex: 1 }}>
            <div className={`col`} style={{ width: "31.4rem", borderRight: "var(--neutral-bolder-border-color)", height: "100%", padding: "0.4rem 0" }}>
                <Text className='heading-8' style={{ padding: "0.4rem 1.2rem" }}>Element</Text>
                <div className={`row ${indexStyles['component-options-group']}`}>
                    {componentType.map((prop) => renderComponentOption(prop))}
                </div>
                <div className={`col ${indexStyles['layer-list-container']}`}>
                    {_layers.fields.filter(e => !e.ParentId).map(e => {
                        return <LayerTile
                            key={e.Id}
                            item={e}
                            layers={_layers.fields}
                            methods={methodProps}
                        />
                    })}
                </div>
            </div>
            <div className={`col ${indexStyles['overview-container']}`}>
                <Text className='heading-8'>Overview</Text>
                <div className='col'>
                    <div ref={demoCardRef} className='col' onDragOver={handleDragToTarget} onDrop={onDrop} onMouseMove={handleOnMouseMove} onClick={handleOnClick} onMouseUp={handleOnMouseUp}>
                        <RenderCard layers={_layers.fields} dataModel={data.dataModel} cardItem={{ ...(methods.watch()), Props: methodProps.watch() ?? {} }} />
                    </div>
                </div>
            </div>
            <CardInforContainer methods={methods} methodsProps={methodProps} />
        </div>
        <div className="row" style={{ padding: "1.6rem 2.4rem", borderTop: "var(--neutral-bolder-border-color)", justifyContent: "end", gap: "0.8rem" }}>
            <Button
                label="Cancel"
                style={{ width: "7.2rem", borderRadius: '0.4rem', backgroundColor: "transparent", color: "var(--neutral-text-subtitle-color)" }}
                onClick={() => {
                    showDialog({
                        ref: dialogRef,
                        alignment: DialogAlignment.center,
                        status: ComponentStatus.WARNING,
                        title: 'Confirm cancel',
                        content: "Every changes will be unsaved",
                        submitTitle: "Submit",
                        onSubmit: () => { closePopup(ref) }
                    })
                }}
            />
            <Button
                label="Save"
                className={methods.watch("Name")?.trim()?.length ? "button-primary" : "button-disabled"}
                style={{ width: "5.8rem", borderRadius: '0.4rem' }}
                onClick={methods.handleSubmit(_onSubmit)}
            />
        </div>
    </div>
})