import './button.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { Select1, Text, TextField, Winicon } from "wini-web-components"
import React, { useEffect, useState } from 'react'
import { PageActions } from '../reducer'
import { OutlineRadius4Angle, OutlineRadiusAll } from '../../../../assets/icon'
import { ColorPicker } from './functions/funtions'
import { Ultis } from '../../../../Utils'
import { DesignTokenType } from '../../../wini/designToken/da'
import { OnChangeWH } from './functions/common'

export default function SettingButton({ selectedLayer }) {
    const methods = useForm({ shouldFocusError: false })
    const designToken = useSelector((store) => store.designToken.data)
    const [hasDesignToken, setHasDesignToken] = useState(false)
    const [position, setPosition] = useState(false)
    const tiltePositionss = ['static', 'relative', 'fixed', 'absolute', 'sticky'];

    const dispatch = useDispatch()

    const [radiusAll, setRadiusAll] = useState(true)
    const [breakPoint, setBreakPoint] = useState(false)

    const _iframe = document.getElementById("iframe-render-page-view")

    const updateStyle = (style) => {
        let _newSettingStyle = { ...selectedLayer.Setting.style, ...style }
        for (const prop in _newSettingStyle) {
            if (_newSettingStyle[prop] == undefined) delete _newSettingStyle[prop]
        }
        PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: _newSettingStyle } }])
    }

    useEffect(() => {
        if (!_iframe || !selectedLayer) return
        setTimeout(() => {
            const _newElement = _iframe.contentWindow.document.getElementById(selectedLayer?.Id)

            if (_newElement) {
                const _newLabel = _newElement.querySelector('.comp-text')

                const _newStyle = window.getComputedStyle(_newElement)
                const _newLabelStyle = window.getComputedStyle(_newLabel)
                const _rootFz = parseFloat(window.getComputedStyle(_iframe.contentWindow.document.documentElement)['font-size'].replace("px", ""))
                methods.setValue('font-family', _newLabelStyle['font-family'])
                methods.setValue('font-weight', _newLabelStyle['font-weight'])
                const _textColor = Ultis.rgbToHex(_newLabelStyle.color)
                methods.setValue('text-color', _textColor.substring(0, 7))
                methods.setValue('text-color-opacity', `${Ultis.hexToPercent(_textColor.substring(7))}`)
                const _borderColor = Ultis.rgbToHex(_newStyle.borderColor)
                methods.setValue('border-color', _borderColor.substring(0, 7))
                methods.setValue('border-color-opacity', `${Ultis.hexToPercent(_borderColor.substring(7))}`)

                if (selectedLayer.Setting.style?.backgroundColor?.startsWith("var")) {
                    const _bgColor = Ultis.rgbToHex(_newStyle.backgroundColor)
                    methods.setValue('background-color-value', _bgColor.substring(0, 7))
                    methods.setValue('background-color-opacity', `${Ultis.hexToPercent(_bgColor.substring(7))}`)
                    methods.setValue('opacity', parseFloat(_newStyle.opacity))
                    methods.setValue('background-color-id', selectedLayer.Setting.style?.backgroundColor.replace("var(--", "").replace(")", ""))
                    //setHasDesignToken(true)
                } else if (selectedLayer.Setting.style?.backgroundColor) {
                    const _bgColor = Ultis.rgbToHex(_newStyle.backgroundColor)
                    methods.setValue('background-color-value', _bgColor.substring(0, 7))
                    methods.setValue('background-color-opacity', `${Ultis.hexToPercent(_bgColor.substring(7))}`)
                    methods.setValue('opacity', parseFloat(_newStyle.opacity))
                    methods.setValue('background-color-id', undefined)
                } else {
                    methods.setValue('background-color-id', undefined)
                    methods.setValue('background-color-value', undefined)
                }

                if (selectedLayer.Setting.style?.borderColor?.startsWith("var")) {
                    const _borderColor = Ultis.rgbToHex(_newStyle.borderColor)
                    methods.setValue('border-color', _borderColor.substring(0, 7))
                    methods.setValue('border-color-opacity', `${Ultis.hexToPercent(_borderColor.substring(7))}`)
                    methods.setValue('border-color-id', selectedLayer.Setting.style?.borderColor.replace("var(--", "").replace(")", ""))
                    //setHasDesignToken(true)
                } else {
                    const _borderColor = Ultis.rgbToHex(_newStyle.borderColor)
                    methods.setValue('border-color', _borderColor.substring(0, 7))
                    methods.setValue('border-color-opacity', `${Ultis.hexToPercent(_borderColor.substring(7))}`)
                }

                if (selectedLayer.Setting.style?.textColor?.startsWith("var")) {
                    debugger
                    const _textColor = Ultis.rgbToHex(_newStyle.color)
                    methods.setValue('text-color', _textColor.substring(0, 7))
                    methods.setValue('text-color-opacity', `${Ultis.hexToPercent(_textColor.substring(7))}`)
                    methods.setValue('text-color-id', selectedLayer.Setting.style?.textColor.replace("var(--", "").replace(")", ""))
                    //setHasDesignToken(true)
                } else {
                    const _textColor = Ultis.rgbToHex(_newStyle.color)
                    methods.setValue('text-color', _textColor.substring(0, 7))
                    methods.setValue('text-color-opacity', `${Ultis.hexToPercent(_textColor.substring(7))}`)
                }

                if (selectedLayer.Setting.style.borderWidth?.includes("px")) {
                    methods.setValue('border-width', _newStyle['border-width'].replace("px", ""))
                    methods.setValue('border-unit', "px")
                } else if (selectedLayer.Setting.style.borderWidth?.includes("%")) {
                    methods.setValue('border-width', parseFloat(_newStyle['border-width'].replace("%", "")))
                    methods.setValue('border-unit', "%")
                } else {
                    methods.setValue('border-width', (parseFloat(_newStyle['border-width'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                    methods.setValue('border-unit', "rem")
                }
                methods.setValue('font-size', selectedLayer.Setting.style?.fontSize?.includes("px") ? _newStyle['font-size'].replace("px", "") : (parseFloat(_newStyle['font-size'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                methods.setValue('font-size-unit', selectedLayer.Setting.style?.fontSize?.includes("px") ? "px" : "rem")
                methods.setValue('line-height', selectedLayer.Setting.style?.lineHeight?.includes("px") ? _newStyle['line-height'].replace("px", "") : _newStyle['line-height'] === "normal" ? "normal" : (parseFloat(_newStyle['line-height'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                methods.setValue('line-height-unit', selectedLayer.Setting.style?.lineHeight?.includes("px") ? "px" : "rem")
                methods.setValue('letter-spacing', selectedLayer.Setting.style?.letterSpacing?.includes("px") ? _newStyle['letter-spacing'].replace("px", "") : _newStyle['letter-spacing'] === "normal" ? "normal" : (parseFloat(_newStyle['letter-spacing'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                methods.setValue('letter-spacing-unit', selectedLayer.Setting.style?.letterSpacing?.includes("px") ? "px" : "rem")

                if (selectedLayer.Setting.style?.position) {
                    if (selectedLayer.Setting.style?.position !== "static") {
                        if (['top', 'right', 'bottom', 'left'].some((v) => selectedLayer.Setting.style[v] != undefined)) {
                            if (selectedLayer.Setting.style?.top?.includes("px")) {
                                var _top = _newStyle['top'].replace("px", "")
                                var _topUnit = 'px'
                            } else if (selectedLayer.Setting.style?.top?.includes("%")) {
                                _top = parseFloat(_newStyle['top'].replace("%", ""))
                                _topUnit = '%'
                            } else {
                                _top = (parseFloat(_newStyle['top'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                                _topUnit = "rem"
                            }
                            if (selectedLayer.Setting.style?.right?.includes("px")) {
                                var _right = _newStyle['right']?.replace("px", "")
                                var _rightUnit = 'px'
                            } else if (selectedLayer.Setting.style?.right?.includes("%")) {
                                _right = parseFloat(_newStyle['right']?.replace("%", ""))
                                _rightUnit = '%'
                            } else {
                                _right = (parseFloat(_newStyle['right']?.replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                                _rightUnit = "rem"
                            }
                            if (selectedLayer.Setting.style?.bottom?.includes("px")) {
                                var _bottom = _newStyle['bottom']?.replace("px", "")
                                var _bottomUnit = 'px'
                            } else if (selectedLayer.Setting.style?.bottom?.includes("%")) {
                                _bottom = parseFloat(_newStyle['bottom']?.replace("%", ""))
                                _bottomUnit = '%'
                            } else {
                                _bottom = (parseFloat(_newStyle['bottom']?.replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                                _bottomUnit = "rem"
                            }
                            if (selectedLayer.Setting.style?.left?.includes("px")) {
                                var _left = _newStyle['left']?.replace("px", "")
                                var _leftUnit = 'px'
                            } else if (selectedLayer.Setting.style?.left?.includes("%")) {
                                _left = parseFloat(_newStyle['left']?.replace("%", ""))
                                _leftUnit = '%'
                            } else {
                                _left = (parseFloat(_newStyle['left']?.replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                                _leftUnit = "rem"
                            }
                            methods.setValue('position-top', _top)
                            methods.setValue('position-top-unit', _topUnit)
                            methods.setValue('position-right', _right)
                            methods.setValue('position-right-unit', _rightUnit)
                            methods.setValue('position-bottom', _bottom)
                            methods.setValue('position-bottom-unit', _bottomUnit)
                            methods.setValue('position-left', _left)
                            methods.setValue('position-left-unit', _leftUnit)
                        }
                        methods.setValue('position-style', selectedLayer.Setting.style.position)
                        setPosition(true)
                    } else {
                        methods.setValue('position-style', "static")
                        methods.setValue('position-top-unit', "rem")
                        methods.setValue('position-right-unit', "rem")
                        methods.setValue('position-bottom-unit', "rem")
                        methods.setValue('position-left-unit', "rem")
                        methods.setValue('position-top', undefined)
                        methods.setValue('position-right', undefined)
                        methods.setValue('position-bottom', undefined)
                        methods.setValue('position-left', undefined)
                        setPosition(false)
                    }

                } else {
                    methods.setValue('position-style', "static")
                    methods.setValue('position-top-unit', "rem")
                    methods.setValue('position-right-unit', "rem")
                    methods.setValue('position-bottom-unit', "rem")
                    methods.setValue('position-left-unit', "rem")
                    methods.setValue('position-top', undefined)
                    methods.setValue('position-right', undefined)
                    methods.setValue('position-bottom', undefined)
                    methods.setValue('position-left', undefined)

                    setPosition(false)
                }

                if (['borderTopLeftRadius', 'borderTopRightRadius', 'borderBottomLeftRadius', 'borderBottomRightRadius'].some((v) => selectedLayer.Setting.style[v] != undefined)) {
                    if (selectedLayer.Setting.style?.borderTopLeftRadius?.includes("px")) {
                        var _vTL = _newStyle['border-top-left-radius'].replace("px", "")
                        var _vUnitTL = 'px'
                    } else if (selectedLayer.Setting.style?.borderTopLeftRadius?.includes("%")) {
                        _vTL = parseFloat(_newStyle['border-top-left-radius'].replace("%", ""))
                        _vUnitTL = '%'
                    } else {
                        _vTL = (parseFloat(_newStyle['border-top-left-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnitTL = "rem"
                    }
                    if (selectedLayer.Setting.style?.borderTopRightRadius?.includes("px")) {
                        var _vTR = _newStyle['border-top-right-radius'].replace("px", "")
                        var _vUnitTR = 'px'
                    } else if (selectedLayer.Setting.style?.borderTopRightRadius?.includes("%")) {
                        _vTR = parseFloat(_newStyle['border-top-right-radius'].replace("%", ""))
                        _vUnitTR = '%'
                    } else {
                        _vTR = (parseFloat(_newStyle['border-top-right-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnitTR = "rem"
                    }
                    if (selectedLayer.Setting.style?.borderBottomLeftRadius?.includes("px")) {
                        var _vBL = _newStyle['border-bottom-left-radius'].replace("px", "")
                        var _vUnitBL = 'px'
                    } else if (selectedLayer.Setting.style?.borderBottomLeftRadius?.includes("%")) {
                        _vBL = parseFloat(_newStyle['border-bottom-left-radius'].replace("%", ""))
                        _vUnitBL = '%'
                    } else {
                        _vBL = (parseFloat(_newStyle['border-bottom-left-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnitBL = "rem"
                    }
                    if (selectedLayer.Setting.style?.borderBottomRightRadius?.includes("px")) {
                        var _vBR = _newStyle['border-bottom-right-radius'].replace("px", "")
                        var _vUnitBR = 'px'
                    } else if (selectedLayer.Setting.style?.borderBottomRightRadius?.includes("%")) {
                        _vBR = parseFloat(_newStyle['border-bottom-right-radius'].replace("%", ""))
                        _vUnitBR = '%'
                    } else {
                        _vBR = (parseFloat(_newStyle['border-bottom-right-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnitBR = "rem"
                    }
                    methods.setValue('border-top-left-radius', _vTL)
                    methods.setValue('border-top-left-radius-unit', _vUnitTL)
                    methods.setValue('border-top-right-radius', _vTR)
                    methods.setValue('border-top-right-radius-unit', _vUnitTR)
                    methods.setValue('border-bottom-left-radius', _vBL)
                    methods.setValue('border-bottom-left-radius-unit', _vUnitBL)
                    methods.setValue('border-bottom-right-radius', _vBR)
                    methods.setValue('border-bottom-right-radius-unit', _vUnitBR)
                    methods.setValue('border-radius', _vTL)
                    methods.setValue('border-radius-unit', _vUnitTL)
                    if (radiusAll) setRadiusAll(false)
                } else {
                    if (selectedLayer.Setting.style?.borderRadius?.includes("px")) {
                        var _v = _newStyle['border-radius'].replace("px", "")
                        var _vUnit = 'px'
                    } else if (selectedLayer.Setting.style?.borderRadius?.includes("%")) {
                        _v = parseFloat(_newStyle['border-radius'].replace("%", ""))
                        _vUnit = '%'
                    } else {
                        _v = (parseFloat(_newStyle['border-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnit = "rem"
                    }
                    methods.setValue('border-radius', _v)
                    methods.setValue('border-radius-unit', _vUnit)
                    methods.setValue('border-top-left-radius', _v)
                    methods.setValue('border-top-left-radius-unit', _vUnit)
                    methods.setValue('border-top-right-radius', _v)
                    methods.setValue('border-top-right-radius-unit', _vUnit)
                    methods.setValue('border-bottom-left-radius', _v)
                    methods.setValue('border-bottom-left-radius-unit', _vUnit)
                    methods.setValue('border-bottom-right-radius', _v)
                    methods.setValue('border-bottom-right-radius-unit', _vUnit)
                    if (!radiusAll) setRadiusAll(true)
                }
                const _bgColor = Ultis.rgbToHex(_newStyle.backgroundColor)
                methods.setValue('background-color-value', _bgColor.substring(0, 7))
                methods.setValue('background-color-opacity', `${Ultis.hexToPercent(_bgColor.substring(7))}`)
                methods.setValue('opacity', parseFloat(_newStyle.opacity))

            }
        }, 100)

    }, [selectedLayer])

    return <>
        <div className="row" style={{ borderBottom: '1px solid #444444', padding: '0.8rem 1.2rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>{selectedLayer.Name}</Text>
            <button type="button" className="row icon-button16"><Winicon src={"fill/user interface/menu-8"} /></button>
        </div>
        <div className="col" style={{ padding: '0.6rem 1.2rem', borderBottom: '1px solid #444444' }}>
            <TextField
                key={'textfield-' + selectedLayer.Id}
                className="regular1"
                defaultValue={selectedLayer.Setting.label}
                placeholder="Button Name..."
                onBlur={(ev) => {
                    PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, label: ev.target.value.trim() } }])
                }}
            />
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Button Text Style</Text>
            <button type="button" className="row icon-button16"><Winicon src={"fill/arrows/down-arrow"} /></button>
        </div>
        <div className="col" style={{ padding: '0.8rem 1.2rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            <Select1
                readOnly
                placeholder={'Font family'}
                className="regular1"
                value={methods.watch('font-family')}
                options={['Inter, sans-serif', 'Arial', 'Times New Roman'].map(e => {
                    return {
                        id: e,
                        name: e
                    }
                })}
                onChange={(v) => { updateStyle({ fontFamily: v.id }) }}
            />
            <div className="row" style={{ gap: '0.8rem' }}>
                <Select1
                    readOnly
                    placeholder={'Font weight'}
                    className="regular1"
                    style={{ flex: 1 }}
                    value={methods.watch('font-weight')}
                    options={['200', '400', '600', '800'].map(e => {
                        return {
                            id: e,
                            name: e
                        }
                    })}
                    onChange={(v) => { updateStyle({ fontWeight: v.id }) }}
                />
                <TextField
                    className="regular1"
                    name="font-size"
                    register={methods.register('font-size', {
                        onBlur: (ev) => { updateStyle({ fontSize: ev.target.value.trim() + methods.getValues('font-size-unit') }) }
                    })}
                    style={{ flex: 1 }}
                    placeholder="Font size"
                    type="number"
                    suffix={<Select1
                        readOnly
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('font-size-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }]}
                        style={{ border: 'none', padding: 0, width: '2.8rem', textAlign: 'right' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('font-size-unit')
                            if (_tmp !== v.id) {
                                methods.setValue('font-size-unit', v.id)
                                updateStyle({ fontSize: v.id === 'rem' ? `${parseFloat(methods.getValues('font-size')) / 10}rem` : `${parseFloat(methods.getValues('font-size')) * 10}px` })
                            }
                        }}
                    />}
                />
            </div>
            <div className="row" style={{ gap: '0.8rem' }}>
                <TextField
                    className="regular1"
                    name="line-height"
                    register={methods.register('line-height', {
                        onBlur: (ev) => { updateStyle({ lineHeight: ev.target.value.trim().toLowerCase() === "normal" ? undefined : (ev.target.value.trim() + methods.getValues('line-height-unit')) }) }
                    })}
                    style={{ flex: 1 }}
                    placeholder="Line height"
                    suffix={<Select1
                        readOnly
                        disabled={methods.watch('line-height') === "normal"}
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('line-height-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }]}
                        style={{ border: 'none', padding: 0, width: '2.8rem', textAlign: 'right', '--neutral-disable-background-color': '#00000000' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('line-height-unit')
                            if (_tmp !== v.id) {
                                methods.setValue('line-height-unit', v.id)
                                updateStyle({ lineHeight: v.id === 'rem' ? `${parseFloat(methods.getValues('line-height')) / 10}rem` : `${parseFloat(methods.getValues('line-height')) * 10}px` })
                            }
                        }}
                    />}
                />
                <TextField
                    className="regular1"
                    name="letter-spacing"
                    register={methods.register('letter-spacing', {
                        onBlur: (ev) => { updateStyle({ letterSpacing: ev.target.value.trim().toLowerCase() === "normal" ? undefined : (ev.target.value.trim() + methods.getValues('letter-spacing-unit')) }) }
                    })}
                    style={{ flex: 1 }}
                    placeholder="Letter spacing"
                    suffix={<Select1
                        readOnly
                        disabled={methods.watch('letter-spacing') === "normal"}
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('letter-spacing-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }]}
                        style={{ border: 'none', padding: 0, width: '2.8rem', textAlign: 'right', '--neutral-disable-background-color': 'transparent' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('letter-spacing-unit')
                            if (_tmp !== v.id) {
                                methods.setValue('letter-spacing-unit', v.id)
                                updateStyle({ letterSpacing: v.id === 'rem' ? `${parseFloat(methods.getValues('letter-spacing')) / 10}rem` : `${parseFloat(methods.getValues('letter-spacing')) * 10}px` })
                            }
                        }}
                    />}
                />
            </div>
            <div className="col" style={{ gap: '0.8rem' }}>
                {/* {methods.watch("text-color") ? <ColorPicker
                    colorName={'text-color'}
                    colorOpcaityName={'text-color-opacity'}
                    register={methods.register}
                    colorValue={methods.watch("text-color") + Ultis.percentToHex(parseFloat(methods.watch('text-color-opacity')))}
                    onChange={({ colorValue, opacity }) =>
                        PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: { ...selectedLayer.Setting.style, color: colorValue } } }])
                    }
                /> : null} */}
                {hasDesignToken && designToken?.length > 0 ?
                    <Select1
                        className="regular1"
                        placeholder={`Text Color`}
                        value={methods.watch('text-color-id')}
                        options={designToken.map((el) => {
                            return {
                                id: el.Id,
                                name: <div className='row' style={{ gap: '0.4rem', flex: 1, alignItems: 'center' }}>
                                    <div style={{
                                        width: "1.4rem",
                                        height: "1.4rem",
                                        borderRadius: '0.2rem',
                                        border: "1px solid #444444", backgroundColor: `${el.Value?.lightMode ?? el.Value}`
                                    }}></div> | <div style={{
                                        width: "1.4rem",
                                        height: "1.4rem",
                                        borderRadius: '0.2rem',
                                        border: "1px solid #444444", backgroundColor: `${el.Value?.darkMode ?? el.Value}`
                                    }}></div>
                                    <Text className="regular1" style={{ color: '#999999' }}>{el.Name}</Text>
                                </div>,
                                disabled: el.Type === DesignTokenType.group
                            }
                        })}
                        style={{}}
                        onChange={(v) => {
                            const _tmp = methods.getValues('text-color-id')
                            if (_tmp !== v.id) {
                                // updateStyle({ textColor: `var(--${v.id})` })
                                PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: { ...selectedLayer.Setting.style, color: `var(--${v.id})` } } }])
                            }
                        }}
                    /> :
                    methods.watch("text-color") ? <ColorPicker
                        colorName={'text-color'}
                        colorOpcaityName={'text-color-opacity'}
                        register={methods.register}
                        colorValue={methods.watch("text-color") + Ultis.percentToHex(parseFloat(methods.watch('text-color-opacity')))}
                        onChange={({ colorValue, opacity }) =>
                            PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: { ...selectedLayer.Setting.style, color: colorValue } } }])
                        }
                    /> : null}

            </div>
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Box Properties</Text>
            <div className='row' style={{ gap: 10 }}>
                <button type="button" className="row icon-button16" onClick={() => { setBreakPoint(!breakPoint) }}><Winicon src={"fill/development/layout-25"} /></button>
                <button type="button" className="row icon-button16"><Winicon src={"fill/arrows/down-arrow"} /></button>
            </div>
        </div>
        <div className="col" style={{ padding: '0.4rem 1.2rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            <div className="col" style={{ gap: '0.4rem', }}>
                <Text className="semibold1" style={{ color: '#999999' }}>Position style</Text>
                <Select1
                    readOnly
                    className="regular1"
                    popupClassName="position-style"
                    value={methods.watch('position-style')}
                    options={tiltePositionss.map(e => {
                        return {
                            id: e,
                            name: e
                        }
                    })}
                    onChange={(ev) => {
                        const _tmp = methods.getValues('position-style')
                        if (_tmp !== ev.id) {
                            updateStyle({ position: ev.id })
                        }
                    }}
                />
                {
                    position ?
                        <div className="row" style={{ gap: '0.8rem', padding: '0.8rem 0.8rem', borderRadius: '0.8rem', border: '1px solid #444444' }}>
                            <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                                <Text className="semibold1" style={{ color: '#999999' }}>Position Top</Text>
                                <TextField
                                    onComplete={(ev) => ev.target.blur()}

                                    className="regular1"

                                    name="position-top"
                                    register={methods.register('position-top', {
                                        onBlur: (ev) => {
                                            updateStyle({
                                                position: methods.getValues('position-style'),
                                                top: ev.target.value.trim() + methods.getValues('position-top-unit'),
                                            })
                                        }
                                    })}
                                    placeholder="top"
                                    type="number"
                                    suffix={<Controller
                                        name={'position-top-unit'}
                                        control={methods.control}
                                        render={({ field }) => <Select1
                                            readOnly
                                            className="regular1"
                                            popupClassName="select-unit-options"
                                            value={field.value}
                                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                            onChange={(v) => {
                                                const _tmp = methods.getValues('position-top-unit')
                                                if (_tmp !== v.id) {
                                                    updateStyle({
                                                        position: methods.getValues('position-style'),
                                                        top: `${v.id === 'rem' ? `${parseFloat(methods.getValues('position-top')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('position-top')) * 10}${v.id}` : `${parseFloat(methods.getValues('position-top'))}${v.id}`}`
                                                    })
                                                }
                                            }}
                                        />}
                                    />}
                                />
                                <Text className="semibold1" style={{ color: '#999999', paddingTop: '0.4rem' }}>Position Right</Text>
                                <TextField
                                    onComplete={(ev) => ev.target.blur()}

                                    className="regular1"
                                    name="position-right"
                                    register={methods.register('position-right', {
                                        onBlur: (ev) => {
                                            updateStyle({
                                                position: methods.getValues('position-style'),
                                                right: ev.target.value.trim() + methods.getValues('position-right-unit'),
                                            })
                                        }
                                    })}
                                    placeholder="right"
                                    type="number"
                                    suffix={<Select1
                                        readOnly
                                        className="regular1"
                                        popupClassName="select-unit-options"
                                        value={methods.watch('position-right-unit')}
                                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                        onChange={(v) => {
                                            const _tmp = methods.getValues('position-right-unit')
                                            if (_tmp !== v.id) {
                                                updateStyle({
                                                    position: methods.getValues('position-style'),
                                                    right: `${v.id === 'rem' ? `${parseFloat(methods.getValues('position-right')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('position-right')) * 10}${v.id}` : `${parseFloat(methods.getValues('position-right'))}${v.id}`}`
                                                })
                                            }
                                        }}
                                    />}
                                />
                            </div>
                            <OutlineRadius4Angle size={'3.2rem'} color={'#fff'} />
                            <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                                <Text className="semibold1" style={{ color: '#999999' }}>Position Bottom</Text>
                                <TextField
                                    onComplete={(ev) => ev.target.blur()}

                                    className="regular1"
                                    name="position-bottom"
                                    register={methods.register('position-bottom', {
                                        onBlur: (ev) => {
                                            updateStyle({
                                                position: methods.getValues('position-style'),
                                                bottom: ev.target.value.trim() + methods.getValues('position-bottom-unit'),
                                            })
                                        }
                                    })}
                                    placeholder="bottom"
                                    type="number"
                                    suffix={<Select1
                                        readOnly
                                        className="regular1"
                                        popupClassName="select-unit-options"
                                        value={methods.watch('position-bottom-unit')}
                                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                        onChange={(v) => {
                                            const _tmp = methods.getValues('position-bottom-unit')
                                            if (_tmp !== v.id)
                                                updateStyle({
                                                    position: methods.getValues('position-style'),
                                                    bottom: `${v.id === 'rem' ? `${parseFloat(methods.getValues('position-bottom')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('position-bottom')) * 10}${v.id}` : `${parseFloat(methods.getValues('position-bottom'))}${v.id}`}`
                                                })
                                        }}
                                    />}
                                />
                                <Text className="semibold1" style={{ color: '#999999', paddingTop: '0.4rem' }}>Position Left</Text>
                                <TextField
                                    onComplete={(ev) => ev.target.blur()}

                                    className="regular1"
                                    name="position-left"
                                    register={methods.register('position-left', {
                                        onBlur: (ev) => {
                                            updateStyle({
                                                position: methods.getValues('position-style'),
                                                left: ev.target.value.trim() + methods.getValues('position-left-unit'),
                                            })
                                        }
                                    })}
                                    placeholder="left"
                                    type="number"
                                    suffix={<Select1
                                        readOnly
                                        className="regular1"
                                        popupClassName="select-unit-options"
                                        value={methods.watch('position-left-unit')}
                                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                        onChange={(v) => {
                                            const _tmp = methods.getValues('position-left-unit')
                                            if (_tmp !== v.id)
                                                updateStyle({
                                                    position: methods.getValues('position-style'),
                                                    left: `${v.id === 'rem' ? `${parseFloat(methods.getValues('position-left')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('position-left')) * 10}${v.id}` : `${parseFloat(methods.getValues('position-left'))}${v.id}`}`
                                                })
                                        }}
                                    />}
                                />
                            </div>
                        </div> : <></>
                }
            </div>
            <OnChangeWH methods={methods} breakPoint={breakPoint} setBreakPoint={setBreakPoint} onChange={updateStyle} selectedLayer={selectedLayer} selectedElement={_iframe.contentWindow.document.getElementById(selectedLayer.Id)} />
            <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                <div className="row" style={{ gap: '0.8rem' }}>
                    <Text className="semibold1" style={{ color: '#999999' }}>Border radius</Text>
                    <div className="row" style={{ borderRadius: '0.6rem', border: '1px solid #444444' }}>
                        <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: radiusAll ? undefined : "#9c6334" }} onClick={() => setRadiusAll(false)}>
                            <OutlineRadius4Angle size={'1.1rem'} color={'#fff'} />
                        </button>
                        <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: radiusAll ? "#9c6334" : undefined }} onClick={() => setRadiusAll(true)}>
                            <OutlineRadiusAll size={'1.1rem'} color={'#fff'} />
                        </button>
                    </div>
                </div>
                {radiusAll ? <TextField
                    className="regular1"
                    name="border-radius"
                    register={methods.register('border-radius', { onBlur: (ev) => { updateStyle({ borderRadius: ev.target.value.trim() + methods.getValues('border-radius-unit') }) } })}
                    placeholder="Border radius"
                    type="number"
                    suffix={<Select1
                        readOnly
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('border-radius-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('border-radius-unit')
                            if (_tmp !== v.id) {
                                updateStyle({
                                    borderRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-radius'))}${v.id}`,
                                    borderTopLeftRadius: undefined,
                                    borderBottomLeftRadius: undefined,
                                    borderTopRightRadius: undefined,
                                    borderBottomRightRadius: undefined,
                                })
                            }
                        }}
                    />}
                /> : <div className="row" style={{ padding: '0.4rem 0', gap: '0.8rem', height: '8rem' }}>
                    <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                        <TextField
                            className="regular1"
                            name="border-top-left-radius"
                            register={methods.register('border-top-left-radius', {
                                onBlur: (ev) => {
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderTopLeftRadius: ev.target.value.trim() + methods.getValues('border-top-left-radius-unit')
                                    })
                                }
                            })}
                            placeholder="TL radius"
                            type="number"
                            suffix={<Controller
                                name={'border-top-left-radius-unit'}
                                control={methods.control}
                                render={({ field }) => <Select1
                                    readOnly
                                    className="regular1"
                                    popupClassName="select-unit-options"
                                    value={field.value}
                                    options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                    style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                    onChange={(v) => {
                                        const _tmp = methods.getValues('border-top-left-radius-unit')
                                        if (_tmp !== v.id) {
                                            updateStyle({
                                                borderRadius: undefined,
                                                borderTopLeftRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-top-left-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-top-left-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-top-left-radius'))}${v.id}`
                                            })
                                        }
                                    }}
                                />}
                            />}
                        />
                        <TextField
                            className="regular1"
                            name="border-bottom-left-radius"
                            register={methods.register('border-bottom-left-radius', {
                                onBlur: (ev) => {
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderBottomLeftRadius: ev.target.value.trim() + methods.getValues('border-bottom-left-radius-unit')
                                    })
                                }
                            })}
                            placeholder="BL radius"
                            type="number"
                            suffix={<Select1
                                readOnly
                                className="regular1"
                                popupClassName="select-unit-options"
                                value={methods.watch('border-bottom-left-radius-unit')}
                                options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                onChange={(v) => {
                                    const _tmp = methods.getValues('border-bottom-left-radius-unit')
                                    if (_tmp !== v.id) {
                                        updateStyle({
                                            borderRadius: undefined,
                                            borderBottomLeftRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-bottom-left-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-bottom-left-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-bottom-left-radius'))}${v.id}`
                                        })
                                    }
                                }}
                            />}
                        />
                    </div>
                    <OutlineRadius4Angle size={'3.2rem'} color={'#fff'} />
                    <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                        <TextField
                            className="regular1"
                            name="border-top-right-radius"
                            register={methods.register('border-top-right-radius', {
                                onBlur: (ev) => {
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderTopRightRadius: ev.target.value.trim() + methods.getValues('border-top-right-radius-unit')
                                    })
                                }
                            })}
                            placeholder="TR radius"
                            type="number"
                            suffix={<Select1
                                readOnly
                                className="regular1"
                                popupClassName="select-unit-options"
                                value={methods.watch('border-top-right-radius-unit')}
                                options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                onChange={(v) => {
                                    const _tmp = methods.getValues('border-top-right-radius-unit')
                                    if (_tmp !== v.id)
                                        updateStyle({
                                            borderRadius: undefined,
                                            borderTopRightRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-top-right-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-top-right-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-top-right-radius'))}${v.id}`
                                        })
                                }}
                            />}
                        />
                        <TextField
                            className="regular1"
                            name="border-bottom-right-radius"
                            register={methods.register('border-bottom-right-radius', {
                                onBlur: (ev) => {
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderBottomRightRadius: ev.target.value.trim() + methods.getValues('border-bottom-right-radius-unit')
                                    })
                                }
                            })}
                            placeholder="BR radius"
                            type="number"
                            suffix={<Select1
                                readOnly
                                className="regular1"
                                popupClassName="select-unit-options"
                                value={methods.watch('border-bottom-right-radius-unit')}
                                options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                onChange={(v) => {
                                    const _tmp = methods.getValues('border-bottom-right-radius-unit')
                                    if (_tmp !== v.id)
                                        updateStyle({
                                            borderRadius: undefined,
                                            borderBottomRightRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-bottom-right-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-bottom-right-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-bottom-right-radius'))}${v.id}`
                                        })
                                }}
                            />}
                        />
                    </div>
                </div>}
            </div>
            <div style={{ paddingTop: '0.4rem ', paddingBottom: '0.4rem ' }}>
                <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                    <Text className="semibold1" style={{ color: '#999999' }}>Border width</Text>
                    <TextField
                        className="regular1"
                        name="border-width"
                        register={methods.register('border-width', { onBlur: (ev) => { updateStyle({ border: 'solid', borderWidth: ev.target.value.trim() + methods.getValues('border-unit') }) } })}
                        placeholder="border width"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('border-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('border-unit')
                                if (_tmp !== v.id) {
                                    updateStyle({ borderWidth: v.id === 'rem' ? `${parseFloat(methods.getValues('border-width')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-width')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-width'))}${v.id}` })
                                }
                            }}
                        />}
                    />
                </div>
                <div className="col" style={{ gap: '0.4rem', flex: 1, paddingTop: '0.8rem' }}>
                    <Text className="semibold1" style={{ color: '#999999' }}>Border color</Text>
                    {/* {methods.watch("border-color") ? <ColorPicker
                        colorName={'border-color'}
                        colorOpcaityName={'border-color-opacity'}
                        register={methods.register}
                        colorValue={methods.watch("border-color") + Ultis.percentToHex(parseFloat(methods.watch('border-color-opacity')))}
                        onChange={({ colorValue, opacity }) => updateStyle({ borderColor: colorValue })}
                    /> : null} */}
                    {hasDesignToken && designToken?.length > 0 ?
                        <Select1
                            className="regular1"
                            placeholder={`Design Border Color`}
                            value={methods.watch('border-color-id')}
                            options={designToken.map((el) => {
                                return {
                                    id: el.Id,
                                    name: <div className='row' style={{ gap: '0.4rem', flex: 1, alignItems: 'center' }}>
                                        <div style={{
                                            width: "1.4rem",
                                            height: "1.4rem",
                                            borderRadius: '0.2rem',
                                            border: "1px solid #444444", backgroundColor: `${el.Value?.lightMode ?? el.Value}`
                                        }}></div> | <div style={{
                                            width: "1.4rem",
                                            height: "1.4rem",
                                            borderRadius: '0.2rem',
                                            border: "1px solid #444444", backgroundColor: `${el.Value?.darkMode ?? el.Value}`
                                        }}></div>
                                        <Text className="regular1" style={{ color: '#999999' }}>{el.Name}</Text>
                                    </div>,
                                    disabled: el.Type === DesignTokenType.group
                                }
                            })}
                            style={{}}
                            onChange={(v) => {
                                const _tmp = methods.getValues('border-color-id')
                                if (_tmp !== v.id) {
                                    updateStyle({ borderColor: `var(--${v.id})` })
                                }
                            }}
                        /> :
                        methods.watch("border-color") ? <div style={{}}>
                            <ColorPicker
                                colorName={'border-color'}
                                colorOpcaityName={'border-color-opacity'}
                                style={{ width: '100%', height: 28 }}
                                register={methods.register}
                                colorValue={methods.watch("border-color") + Ultis.percentToHex(parseFloat(methods.watch('border-color-opacity')))}
                                onChange={({ colorValue, opacity }) => updateStyle({ borderColor: colorValue })}
                            />
                        </div> : null}
                </div>
            </div>
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>BackgroundColor</Text>
            <div className='row' style={{ gap: 10 }}>
                <button type="button" className="row icon-button16" onClick={() => { setHasDesignToken(!hasDesignToken) }}><Winicon src={"fill/business/coins"} /></button>
                <button type="button" className="row icon-button16"><Winicon src={"fill/arrows/down-arrow"} /></button>
            </div>
        </div>
        <div className="col" style={{ padding: '0.4rem 0.8rem 0.8rem 1.2rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            {/* {methods.watch("background-color-value") ? <ColorPicker
                colorName={'background-color-value'}
                colorOpcaityName={'background-color-opacity'}
                register={methods.register}
                colorValue={methods.watch("background-color-value") + Ultis.percentToHex(parseFloat(methods.watch('background-color-opacity')))}
                opacity={methods.watch('opacity')}
                onChange={({ colorValue, opacity }) => updateStyle({ backgroundColor: colorValue, opacity: opacity ? undefined : 0 })}
            /> : null} */}

            {hasDesignToken && designToken?.length > 0 ?
                <Select1
                    className="regular1"
                    placeholder={`Design Color`}
                    value={methods.watch('background-color-id')}
                    options={designToken.map((el) => {
                        return {
                            id: el.Id,
                            name: <div className='row' style={{ gap: '0.4rem', flex: 1, alignItems: 'center' }}>
                                <div style={{
                                    width: "1.4rem",
                                    height: "1.4rem",
                                    borderRadius: '0.2rem',
                                    border: "1px solid #444444", backgroundColor: `${el.Value?.lightMode ?? el.Value}`
                                }}></div> | <div style={{
                                    width: "1.4rem",
                                    height: "1.4rem",
                                    borderRadius: '0.2rem',
                                    border: "1px solid #444444", backgroundColor: `${el.Value?.darkMode ?? el.Value}`
                                }}></div>
                                <Text className="regular1" style={{ color: '#999999' }}>{el.Name}</Text>
                            </div>,
                            disabled: el.Type === DesignTokenType.group
                        }
                    })}
                    style={{}}
                    onChange={(v) => {
                        const _tmp = methods.getValues('background-color-id')
                        if (_tmp !== v.id) {
                            updateStyle({ backgroundColor: `var(--${v.id})` })
                        }
                    }}
                /> :
                methods.watch("background-color-value") ? <ColorPicker
                    colorName={'background-color-value'}
                    colorOpcaityName={'background-color-opacity'}
                    style={{ width: '100%', height: 28, }}
                    register={methods.register}
                    colorValue={methods.watch("background-color-value") + Ultis.percentToHex(parseFloat(methods.watch('background-color-opacity')))}
                    opacity={methods.watch('opacity')}
                    onChange={({ colorValue, opacity }) => updateStyle({ backgroundColor: colorValue, opacity: opacity })}
                    onDelete={() => updateStyle({ backgroundColor: undefined, opacity: undefined })}
                /> : null}
        </div>
    </>
}