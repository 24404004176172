import styles from './index.module.css'
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Select1, Text, TextField, Winicon } from "wini-web-components"
import { cssRegex, onFocus } from "../functions/common"
import { OutlineRadiusBottomLeft, OutlineRadiusBottomRight, OutlineRadiusTopLeft, OutlineRadiusTopRight } from '../../../../../assets/icon'
import { ColorPicker } from '../functions/funtions'
import { Ultis } from '../../../../../Utils'

export const AppearanceProperties = ({ selected, onChange, docBody = document.body.querySelector(`div[class*="overview-container"]`) }) => {
    const methods = useForm({ shouldFocusError: false })
    const [isOpen, setIsOpen] = useState(true)
    const [nodeElement, setNode] = useState()
    const [radiusAngle, setRadiusAngle] = useState(false)
    const [bWidthSide, setBWidthSide] = useState(false)
    const radiusAngles = ['border-top-left-radius', 'border-top-right-radius', 'border-bottom-left-radius', 'border-bottom-right-radius']
    const borderWidthSides = ['border-top-width', 'border-right-width', 'border-bottom-width', 'border-left-width']

    useEffect(() => {
        const node = docBody.querySelector(`*[id="${selected.Id}"]`)
        if (node.id !== nodeElement?.id) setNode(node)
        if (node) {
            const _nodeStyle = window.getComputedStyle(node)
            if (selected.Setting.style.rotate) methods.setValue('rotate', selected.Setting.style.rotate)
            else methods.setValue('rotate', _nodeStyle.rotate)
            if (selected.Setting.style.borderRadius) methods.setValue('border-radius', selected.Setting.style.borderRadius)
            else methods.setValue('border-radius', _nodeStyle.borderRadius)
            radiusAngles.forEach((v) => {
                const vName = v.split("-").map((e, i) => i ? (e.charAt(0).toUpperCase() + e.slice(1)) : e).join("")
                if (selected.Setting.style[vName]) methods.setValue(v, selected.Setting.style[vName])
                else if (selected.Setting.style.borderRadius) methods.setValue(v, selected.Setting.style.borderRadius)
                else methods.setValue(v, _nodeStyle[v])
            })
            if (selected.Setting.style.borderStyle) methods.setValue('border-style', selected.Setting.style.borderStyle)
            else methods.setValue('border-style', _nodeStyle.borderStyle)
            if (selected.Setting.style.borderWidth) methods.setValue('border-width', selected.Setting.style.borderWidth)
            else methods.setValue('border-width', _nodeStyle.borderWidth)
            borderWidthSides.forEach((v) => {
                const vName = v.split("-").map((e, i) => i ? (e.charAt(0).toUpperCase() + e.slice(1)) : e).join("")
                if (selected.Setting.style[vName]) methods.setValue(v, selected.Setting.style[vName])
                else if (selected.Setting.style.borderWidth) methods.setValue(v, selected.Setting.style.borderWidth)
                else methods.setValue(v, _nodeStyle[v])
            })
            if (selected.Setting.style?.borderColor?.startsWith("var")) {
                const _borderColor = Ultis.rgbToHex(_nodeStyle.borderColor)
                methods.setValue('border-color', _borderColor.substring(0, 7))
                methods.setValue('border-color-opacity', `${Ultis.hexToPercent(_borderColor.substring(7))}`)
                methods.setValue('border-color-id', selected.Setting.style?.borderColor.replace("var(--", "").replace(")", ""))
                // setDesignToken(true)
            } else {
                const _borderColor = Ultis.rgbToHex(_nodeStyle.borderColor)
                methods.setValue('border-color', _borderColor.substring(0, 7))
                methods.setValue('border-color-opacity', `${Ultis.hexToPercent(_borderColor.substring(7))}`)
                // setDesignToken(false)
            }
        }
    }, [selected])

    return <div className="col" style={{ borderBottom: 'var(--neutral-main-border-color)' }}>
        <div className="row" style={{ padding: '0.8rem 1.2rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Appearance</Text>
            <Winicon onClick={() => { setIsOpen(!isOpen) }} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/arrows/${isOpen ? "up" : "down"}-arrow`} />
        </div>
        <div className="col" style={{ padding: '0 1.2rem 0.8rem', gap: '0.8rem', display: isOpen ? 'flex' : 'none' }}>
            <div className="row" style={{ gap: '0.8rem' }}>
                <TextField
                    style={{ flex: 1 }}
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Winicon src="outline/development/angle" size='1rem' />}
                    className="regular1"
                    name="rotate"
                    placeholder="rotate"
                    onFocus={(ev) => onFocus(ev, methods)}
                    register={methods.register('rotate', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, rotate: _newV } } }
                                if (_newV === "auto" || _newV === "none") delete _tmp.Setting.style.rotate
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
                <TextField
                    style={{ flex: 1, visibility: radiusAngle ? 'hidden' : 'visible' }}
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Winicon src='outline/development/border-radius' size={"1.2rem"} />}
                    className="regular1"
                    name="border-radius"
                    placeholder="radius"
                    onFocus={(ev) => onFocus(ev, methods)}
                    register={methods.register('border-radius', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, borderRadius: _newV } } }
                                if (_newV === "auto" || _newV === "none") delete _tmp.Setting.style.borderRadius
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
                <Winicon
                    src={`${radiusAngle ? "fill" : "outline"}/user interface/gear`}
                    className={styles['toggle-radius']}
                    size='1.4rem'
                    onClick={() => { setRadiusAngle(!radiusAngle) }} style={{ borderColor: radiusAngle ? undefined : "transparent" }}
                />
            </div>
            {radiusAngle && <div className='row' style={{ gap: "0.8rem", flexWrap: "wrap" }}>
                {radiusAngles.map((v, i) => {
                    switch (i) {
                        case 0:
                            var _icon = <OutlineRadiusTopLeft size={"1.2rem"} />
                            break;
                        case 1:
                            _icon = <OutlineRadiusTopRight size={"1.2rem"} />
                            break;
                        case 2:
                            _icon = <OutlineRadiusBottomLeft size={"1.2rem"} />
                            break;
                        case 3:
                            _icon = <OutlineRadiusBottomRight size={"1.2rem"} />
                            break;
                        default:
                            break;
                    }
                    return <TextField
                        key={v}
                        style={{ width: "calc((100% - 2.4rem - 2px - 1.6rem) / 2)" }}
                        onComplete={(ev) => ev.target.blur()}
                        prefix={_icon}
                        className="regular1"
                        name={v}
                        placeholder={v.replace('border-', '').replace('-radius', '')}
                        onFocus={(ev) => onFocus(ev, methods)}
                        register={methods.register(v, {
                            onBlur: (ev) => {
                                const _newV = ev.target.value.trim().toLowerCase()
                                if (cssRegex.test(_newV)) {
                                    const vName = v.split("-").map((e, i) => i ? (e.charAt(0).toUpperCase() + e.slice(1)) : e).join("")
                                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, [vName]: _newV } } }
                                    if (_newV === "auto" || _newV === "none") delete _tmp.Setting.style[vName]
                                    onChange(_tmp)
                                } else ev.target.value = methods.getValues("_tmp")
                                methods.setValue('_tmp', null)
                            }
                        })}
                    />
                })}
            </div>}
            <div className="row" style={{ gap: '0.8rem' }}>
                <Select1
                    readOnly
                    style={{ flex: 1 }}
                    prefix={<Winicon src="outline/development/path-minus" size='1rem' />}
                    className="regular1"
                    name="border-style"
                    placeholder="border style"
                    value={methods.watch('border-style')}
                    options={['none', 'solid', 'dashed', 'dotted', 'double', 'groove', 'inset', 'outset', 'ridge', 'hidden'].map(e => {
                        return { id: e, name: e }
                    })}
                    onChange={(v) => {
                        if (v.id !== methods.getValues('border-style')) {
                            let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, borderStyle: v.id } } }
                            if (v.id === "none") delete _tmp.Setting.style.borderStyle
                            onChange(_tmp)
                        }
                    }}
                />
                <TextField
                    style={{ flex: 1, visibility: bWidthSide ? 'hidden' : 'visible' }}
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Winicon src='outline/development/border-width' size={"1rem"} />}
                    className="regular1"
                    name="border-width"
                    placeholder="border width"
                    onFocus={(ev) => onFocus(ev, methods)}
                    register={methods.register('border-width', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, borderWidth: _newV } } }
                                if (_newV === "auto" || _newV === "none") delete _tmp.Setting.style.borderWidth
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
                <Winicon
                    src={`${bWidthSide ? "fill" : "outline"}/user interface/gear`}
                    className={styles['toggle-radius']}
                    size='1.4rem'
                    onClick={() => { setBWidthSide(!bWidthSide) }} style={{ borderColor: bWidthSide ? undefined : "transparent" }}
                />
            </div>
            {bWidthSide && <div className='row' style={{ gap: "0.8rem", flexWrap: "wrap" }}>
                {borderWidthSides.map((v, i) => {
                    switch (i) {
                        case 0:
                            var _icon = <Winicon src='fill/arrows/move-layer-left' style={{ rotate: '90deg' }} size={"1.2rem"} />
                            break;
                        case 1:
                            _icon = <Winicon src='fill/arrows/move-layer-right' size={"1.2rem"} />
                            break;
                        case 2:
                            _icon = <Winicon src='fill/arrows/move-layer-right' style={{ rotate: '90deg' }} size={"1.2rem"} />
                            break;
                        case 3:
                            _icon = <Winicon src='fill/arrows/move-layer-left' size={"1.2rem"} />
                            break;
                        default:
                            break;
                    }
                    return <TextField
                        key={v}
                        style={{ width: "calc((100% - 2.4rem - 2px - 1.6rem) / 2)" }}
                        onComplete={(ev) => ev.target.blur()}
                        prefix={_icon}
                        className="regular1"
                        name={v}
                        placeholder={v.replace('border-', '').replace("-width", "")}
                        onFocus={(ev) => onFocus(ev, methods)}
                        register={methods.register(v, {
                            onBlur: (ev) => {
                                const _newV = ev.target.value.trim().toLowerCase()
                                if (cssRegex.test(_newV)) {
                                    const vName = v.split("-").map((e, i) => i ? (e.charAt(0).toUpperCase() + e.slice(1)) : e).join("")
                                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, [vName]: _newV } } }
                                    if (_newV === "auto" || _newV === "none") delete _tmp.Setting.style[vName]
                                    onChange(_tmp)
                                } else ev.target.value = methods.getValues("_tmp")
                                methods.setValue('_tmp', null)
                            }
                        })}
                    />
                })}
            </div>}
            {methods.watch('border-style') !== "none" && <ColorPicker
                colorName={'border-color'}
                colorOpcaityName={'border-color-opacity'}
                style={{ width: 'calc(100% - 2.4rem - 2px - 0.8rem)', height: 28 }}
                register={methods.register}
                colorValue={methods.watch("border-color") + Ultis.percentToHex(parseFloat(methods.watch('border-color-opacity')))}
                onChange={({ colorValue, opacity }) => {
                    let _tmp = { ...selected, Setting: { ...selected.Setting, style: { ...selected.Setting.style, borderColor: colorValue } } }
                    onChange(_tmp)
                }}
            />}
        </div>
    </div>
}