import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'wini-web-components';
import { Home, PageViewDemo, RenderPageView } from './router/router';
import { Provider } from 'react-redux';
import { store } from './store';
import LandingLayout from './screen/landing/view';
import LoginView from './screen/login/view';
import { Ultis } from './Utils';
import ConfigApi, { ConfigDomain } from './da/configApi';
import { TableController } from './screen/wini/table/controller';
import { ProjectController } from './screen/wini/project/controller';

const checkToken = () => {
  // const token = Ultis.getCookie('accessToken') && localStorage.getItem('listActive');
  const token = Ultis.getCookie('accessToken');
  if (token) return true;
  Ultis.clearCookie()
  return false;
};

function App() {

  useEffect(() => {
    if (!ConfigDomain.includes(window.location.hostname) && !["/manager", "/page", "/setting"].some(e => window.location.pathname.startsWith(e))) {
      ProjectController.getListSimple({ page: 1, size: 1, query: `@Domain:{*${window.location.hostname.replaceAll(".", "\\.")}*}` }).then(res => {
        if (res.code === 200 && res.data.length) {
          Ultis.setCookie("pid", res.data[0].Id)
          const _menuController = new TableController("menu")
          _menuController.getAll().then(menuRes => {
            if (menuRes.code === 200 && menuRes.data.length) {
              const _page = menuRes.data.sort((a, b) => a.Sort - b.Sort).find(e => e.TableId?.length)
              window.location.replace(`/manager/${Ultis.toSlug(_page?.Name)}?pageid=${_page.TableId}`)
            }
          })
        }
      })
    }
  }, [])

  return <Provider store={store} stabilityCheck="always">
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<LoginView />} />
        <Route path="/*" element={checkToken() ? <Home /> : <Navigate to={"/login"} replace />} />
        {ConfigDomain.includes(window.location.hostname) ?
          <>
            <Route path="/" element={<LandingLayout />} />
            <Route path={'/demoFullPage'} element={checkToken() ? <RenderPageView /> : <Navigate to={"/login"} replace />} />
            <Route path={'/demoPage'} element={checkToken() ? <PageViewDemo /> : <Navigate to={"/login"} replace />} />
          </> : <></>
        }
      </Routes>
    </BrowserRouter>
  </Provider>
}

export default App;