import styles from './index.module.css'
import { forwardRef, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { TableController } from "../screen/wini/table/controller"
import { ModuleActions } from "../screen/module/reducer"
import { Button, closePopup, Popup, showPopup, Text, TextField, ToastMessage, Winicon } from "wini-web-components"
import { randomGID, Ultis } from "../Utils"
import { Select1Form, TextAreaForm, TextFieldForm } from "../project-component/component-form"
import { useForm } from "react-hook-form"
import { ToolType } from "../screen/wini/table/da"
import { ProjectActions } from "../screen/wini/project/reducer"
import { OutlineAdministrator } from '../assets/icon'
import { ConfigDomain } from '../da/configApi'

export default function ActionSidebar() {
    const location = useLocation()
    const [listModule, setListM] = useState([])
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const _pageId = searchParams.get("pageid")
    const _menuController = new TableController("menu")
    const popupRef = useRef()
    const dispatch = useDispatch()

    useEffect(() => {
        if (Ultis.getCookie("pid")) {
            ProjectActions.getInfor(dispatch)
            _menuController.getAll().then(res => {
                if (res.code === 200 && res.data) setListM(res.data)
                else navigate('/')
            })
        }
    }, [location.pathname])

    useEffect(() => {
        if (ConfigDomain.includes(window.location.hostname)) {
            if (window.location.pathname.startsWith("/home")) {
                ModuleActions.setSelectedM(dispatch)
                setListM([])
            } else if (['/admin', '/workflow'].some(e => window.location.pathname.startsWith(e))) {
                ModuleActions.setSelectedM(dispatch)
            } else if (listModule.length) {
                const _m = listModule.find(e => _pageId === e.PageId || _pageId === e.TableId)
                if (_m) ModuleActions.setSelectedM(dispatch, _m)
                else navigate('/home')
            }
        } else {
            if (window.location.pathname.startsWith("/setting")) {
                ModuleActions.setSelectedM(dispatch)
            } else if (listModule.length) {
                const _m = listModule.find(e => _pageId === e.PageId || _pageId === e.TableId)
                if (_m) ModuleActions.setSelectedM(dispatch, _m)
                else navigate('/setting')
            }
        }
    }, [_pageId, listModule.length, window.location.pathname])

    const updateSort = (sortList = []) => {
        const _sortList = sortList.map((e, i) => {
            let _item = { ...e, Sort: i }
            delete _item.id
            delete _item.name
            delete _item.parentId
            return _item
        })
        _menuController.edit(_sortList)
        setListM(listModule.map(e => {
            const _item = _sortList.find(el => el.Id === e.Id)
            return { ...e, ..._item }
        }))
    }

    const addMenuItem = (parentId) => {
        showPopup({
            ref: popupRef,
            hideButtonClose: true,
            heading: <div className="heading-7 popup-header">New module</div>,
            content: <AddModulePopup
                ref={popupRef}
                parentId={parentId}
                onSubmit={async (_newItem) => {
                    const _sameLevel = listModule.filter(e => e.ParentId === parentId && e.id !== "admin" && e.parentId !== "admin")
                    _newItem.Sort = _sameLevel.length
                    const res = await _menuController.add([
                        ..._sameLevel.map((e, i) => {
                            let _item = { ...e, Sort: i }
                            delete _item.id
                            delete _item.name
                            delete _item.parentId
                            return _item
                        }),
                        _newItem
                    ])
                    if (res.code !== 200) return
                    ToastMessage.success('Create module successfully!')
                    _newItem.id = _newItem.Name
                    _newItem.parentId = _newItem.ParentId ? Ultis.toSlug(listModule.find(e => e.Id === _newItem.ParentId)?.Name) : undefined
                    _newItem.name = _newItem.Name
                    setListM([...listModule, _newItem])
                }}
            />
        })
    }

    const deleteMennuItem = (item) => {
        const children = listModule.filter(e => e.ParentId === item.Id).map(e => e.Id)
        _menuController.delete([item.Id, ...children]).then(res => {
            if (res.code === 200) {
                const _sameLevel = listModule.filter(e => e.ParentId === item.ParentId)
                let _index = _sameLevel.findIndex(e => e.Id === item.Id)
                if (_index === 0) _index = 1
                else _index = _index - 1
                if (_index < 0) _index += 2
                let _selectedM = _sameLevel[_index]
                const _newMenu = listModule.filter((e) => e.Id !== item.Id && children.every(id => id !== e.Id))
                if (!_selectedM?.TableId) _selectedM = _newMenu.find(e => e.PageId)
                if (!_selectedM?.PageId) {
                    navigate(`/admin/table`)
                } else if (_selectedM.PageId === ToolType.Manager) {
                    navigate(`/manager/${_selectedM.parentId ? `${_selectedM.parentId}/${_selectedM.id}` : _selectedM.id}?pageid=${_selectedM.TableId}`)
                } else {
                    navigate(`/page/${_selectedM.parentId ? `${_selectedM.parentId}/${_selectedM.id}` : _selectedM.id}?pageid=${_selectedM.PageId}`)
                }
                setListM(listModule.filter(e => e.Id !== item.Id))
            } else {
                ToastMessage.errors(res.message)
            }
        })
    }

    return <div className={`col ${styles['sidebar']}`}>
        <Popup ref={popupRef} />
        <div className='col'>
            {ConfigDomain.includes(window.location.hostname) ? <>
                <NavLink to="/home" className={`row ${styles['module-tile']} ${window.location.pathname.startsWith("/home") ? styles['selected'] : ""}`} >
                    <Winicon src='outline/user interface/home' size={'1.8rem'} />
                    <Text className='label-3'>Home</Text>
                </NavLink>
                {location.pathname.startsWith("/home") ? null : <>
                    <NavLink to={`/admin`} className={`row ${styles['module-tile']} ${window.location.pathname.startsWith("/admin") ? styles['selected'] : ""}`}>
                        <OutlineAdministrator size={'1.8rem'} />
                        <Text className='label-3'>Admin</Text>
                    </NavLink>
                    <NavLink to={`/workflow`} className={`row ${styles['module-tile']} ${window.location.pathname.startsWith("/workflow") ? styles['selected'] : ""}`}>
                        <Winicon src='outline/files/folder' size={'1.8rem'} />
                        <Text className='label-3'>Workflow</Text>
                    </NavLink>
                </>}
            </> : <NavLink to="/setting/project" className={`row ${styles['module-tile']} ${window.location.pathname.startsWith("/setting") ? styles['selected'] : ""}`} >
                <Winicon src='outline/user interface/settings-gear' size={'2rem'} />
                <Text className='label-3'>Setting</Text>
            </NavLink>}
        </div>
        <div className={`col ${styles['module-list-container']}`}>
            {listModule.filter(e => !e.ParentId).map(e => <RenderModuleTile
                key={e.Id}
                item={e}
                addMenuItem={addMenuItem}
                controller={_menuController}
                updateSort={updateSort}
                listModule={listModule}
                deleteMennuItem={deleteMennuItem}
                onChangeName={(_updateItem) => {
                    setListM(listModule.map(e => {
                        if (e.Id === _updateItem.Id) return _updateItem
                        else return e
                    }))
                }}
            />)}
        </div>
        {window.location.pathname.startsWith("/home") ? null : <div className='col'>
            <Button
                label='Add Module'
                className='button-text-2 button-neutral'
                style={{ width: '100%', backgroundColor: "transparent", height: "4rem" }}
                prefix={<Winicon src={"outline/user interface/e-add"} size={"1.8rem"} />}
                onClick={() => { addMenuItem() }}
            />
            <Button
                label='Help'
                className='button-text-2'
                prefix={<Winicon src={"outline/technology/headset"} size={"1.8rem"} />}
                style={{ width: '100%', backgroundColor: "transparent", color: "var(--neutral-text-subtitle-color)", height: "4rem" }}
            />
        </div>}
    </div>
}

//#region Popup add Module 
const AddModulePopup = forwardRef(function AddModulePopup(data, ref) {
    const methods = useForm({ shouldFocusError: false, defaultValues: { PageId: ToolType.Manager } })
    const _tbController = new TableController("table")
    const _pageController = new TableController("page")
    const [tables, setTables] = useState([])
    const [pages, setPages] = useState([])

    const onSubmit = async (ev) => {
        const _newItem = {
            Id: randomGID(),
            ...ev,
            DateCreated: Date.now(),
            ParentId: data.parentId,
        }
        data.onSubmit(_newItem)
        closePopup(ref)
    }

    useEffect(() => {
        _pageController.getAll().then(res => {
            if (res.code === 200) setPages(res.data)
        })
    }, [])

    useEffect(() => {
        if (!tables.length && methods.getValues("PageId") === ToolType.Manager) {
            _tbController.getAll().then(res => {
                if (res.code === 200) {
                    setTables(res.data)
                } else {
                    ToastMessage.errors(res.message)
                }
            })
        }
    }, [methods.watch("PageId")])

    return <div className="col" style={{ flex: 1 }}>
        <div className="col" style={{ padding: '1.6rem 2.4rem', gap: '1.6rem', overflow: 'hidden auto', flex: 1, width: '56rem' }}>
            <div className="row" style={{ gap: '1.2rem' }}>
                <TextFieldForm
                    name={"Icon"}
                    style={{ width: '12rem' }}
                    placeholder={"Icon"}
                    register={methods.register}
                    errors={methods.formState.errors}
                />
                <TextFieldForm
                    required
                    style={{ flex: 1 }}
                    name={"Name"}
                    placeholder={"Module name"}
                    register={methods.register}
                    errors={methods.formState.errors}
                />
            </div>
            <Select1Form
                name={"PageId"}
                placeholder={"Choose page"}
                control={methods.control}
                errors={methods.formState.errors}
                hideSearch
                options={[{ id: ToolType.Manager, name: ToolType.Manager }, ...pages.map(e => {
                    return {
                        id: e.Id,
                        name: e.Name
                    }
                })]}
            />
            {methods.watch("PageId") === ToolType.Manager && <Select1Form
                required={data.parentId != undefined}
                name={"TableId"}
                placeholder={"Choose table"}
                control={methods.control}
                errors={methods.formState.errors}
                options={tables.map(e => {
                    return {
                        id: e.Id,
                        name: e.Name
                    }
                })}
            />}
            <TextAreaForm
                name={"Description"}
                placeholder={"Write some thing about this module..."}
                register={methods.register}
                errors={methods.formState.errors}
            />
        </div>
        <div className="row popup-footer">
            <Text className="button-text-3" style={{ color: "var(--neutral-text-subtitle-color)" }} onClick={() => { closePopup(ref) }}>Cancel</Text>
            <div style={{ flex: 1 }} />
            <button
                type="button"
                className={`row ${(methods.watch('Name')?.length && (!data.parentId || methods.watch('PageId')) ? 'button-primary' : 'button-disabled')}`}
                onClick={methods.handleSubmit(onSubmit)}
            >
                <Text className="button-text-3">Create</Text>
            </button>
        </div>
    </div>
})

//#region  RenderModuleTile
const RenderModuleTile = ({ item, listModule = [], addMenuItem, updateSort, controller, deleteMennuItem, onChangeName, space = 0 }) => {
    const selectedM = useSelector((store) => store.module.data)
    const [isOpen, setIsOpen] = useState(false)
    const [onEdit, setOnEdit] = useState(false)
    const [isSelected, setSelected] = useState(false)
    const [children, setChildren] = useState([])
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const popupRef = useRef()

    const handleRightClick = (ev, item) => {
        const _sameLevel = listModule.filter(e => e.ParentId === item.ParentId && e.id !== "admin" && e.parentId !== "admin")
        const _index = _sameLevel.findIndex(e => e.Id === item.Id)
        switch (ev.type) {
            case 'contextmenu':
                ev.preventDefault()
                showPopup({
                    ref: popupRef,
                    clickOverlayClosePopup: true,
                    style: { left: ev.pageX, top: ev.pageY, borderRadius: '0.4rem' },
                    content: <div className="col popup-actions" style={{ maxHeight: 'fit-content' }}>
                        {!item.ParentId && !item.TableId ? <button type="button"
                            onClick={() => {
                                closePopup(popupRef)
                                addMenuItem(item.Id)
                            }}>
                            <Text className="button-text-3">Create child module</Text>
                        </button> : null}
                        {_index > 0 ? <button type="button" onClick={() => {
                            const _sortList = [item, ..._sameLevel.filter(e => e.Id !== item.Id)]
                            updateSort(_sortList)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Move to top</Text>
                        </button> : null}
                        {_index > 0 ? <button type="button" onClick={() => {
                            const _sortList = [..._sameLevel.slice(0, _index - 1), item, ..._sameLevel.slice(_index - 1).filter(e => e.Id !== item.Id)]
                            updateSort(_sortList)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Move up</Text>
                        </button> : null}
                        {(_index + 1) < _sameLevel.length ? <button type="button" onClick={() => {
                            const _sortList = [..._sameLevel.slice(0, _index), _sameLevel[_index + 1], item, ..._sameLevel.slice(_index + 2)]
                            updateSort(_sortList)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Move down</Text>
                        </button> : null}
                        {(_index + 1) < _sameLevel.length ? <button type="button" onClick={() => {
                            const _sortList = [..._sameLevel.filter(e => e.Id !== item.Id), item]
                            updateSort(_sortList)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Move to bottom</Text>
                        </button> : null}
                        <button type="button" onClick={() => {
                            closePopup(popupRef)
                            setOnEdit(true)
                        }}>
                            <Text className="button-text-3">Change name</Text>
                        </button>
                        <button type="button" onClick={() => {
                            deleteMennuItem(item)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Delete</Text>
                        </button>
                    </div >
                })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (!item.parentId) setChildren(listModule.filter(e => e.ParentId === item.Id))
    }, [listModule.length])

    useEffect(() => {
        if (selectedM) {
            if (selectedM.Id === item.Id) return setSelected(true)
            else if (selectedM.ParentId === item.Id) return setIsOpen(true)
        }
        setSelected(false)
    }, [selectedM, children.length])
    // 
    return <div className="col" style={{ order: item.Sort }}>
        <Popup ref={popupRef} />
        <button type="button" className={`row ${styles['module-tile']} ${isSelected ? styles['selected'] : ''}`}
            onContextMenu={item.Id ? (ev) => { handleRightClick(ev, item) } : undefined}
            onClick={() => {
                if (item.TableId?.length) navigate(`/manager/${item.ParentId ? `${Ultis.toSlug(listModule.find(e => e.Id === item.ParentId)?.Name ?? "")}/${Ultis.toSlug(item.Name)}` : Ultis.toSlug(item.Name)}?pageid=${item.TableId}`)
                else if (item.PageId?.length && item.PageId !== ToolType.Manager) navigate(`/page/${item.ParentId ? `${Ultis.toSlug(listModule.find(e => e.Id === item.ParentId)?.Name ?? "")}/${Ultis.toSlug(item.Name)}` : Ultis.toSlug(item.Name)}?pageid=${item.PageId}`)
                else setIsOpen(!isOpen)
            }}>
            <div style={{ paddingLeft: `${space}px` }}>{children?.length ? <Winicon src={isOpen ? "fill/arrows/triangle-down" : "fill/arrows/triangle-right"} size={'1.2rem'} /> : item.Icon ? <Winicon src={item.Icon} size='1.6rem' /> : <div className={styles["prefix"]} />}</div>
            {onEdit ? <TextField
                autoFocus
                className="label-3"
                defaultValue={item.Name}
                onComplete={(ev) => { ev.target.blur() }}
                onBlur={async (ev) => {
                    const _newValue = ev.target.value.trim()
                    if (_newValue.length) {
                        let _updateItem = { ...item, Name: _newValue }
                        controller.edit([_updateItem]).then(res => {
                            if (res.code === 200) {
                                onChangeName(_updateItem)
                                if (_updateItem.Id === selectedM?.Id) ModuleActions.setSelectedM(dispatch, { ...selectedM, ..._updateItem })
                            } else {
                                ev.target.value = item.Name
                                ToastMessage.errors(res.message)
                            }
                        })
                    } else ev.target.value = item.Name
                    if (onEdit) setOnEdit(undefined)
                }}
            /> : <Text className="label-3" style={{ flex: 1 }}>{item.Name}</Text>}
            {children?.length ? <Winicon src={"fill/user interface/e-add"} size={"1.6rem"} onClick={() => { addMenuItem(item.Id) }} /> : undefined}
        </button>
        {isOpen ? children.map(e => <RenderModuleTile
            key={e.Id}
            item={e}
            addMenuItem={addMenuItem}
            updateSort={updateSort}
            listModule={listModule}
            controller={controller}
            deleteMennuItem={deleteMennuItem}
            onChangeName={onChangeName}
            space={space + 16}
        />) : undefined}
    </div>
}