import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { PageActions } from "../reducer"
import { Text, TextField, Winicon } from "wini-web-components"
import { useEffect, useState } from "react"
import { BoxProperties } from "./dimension/dimension"
import { FontProperties } from "./font/font"

export default function SettingText({ selected }) {
    const { register, setValue } = useForm({ shouldFocusError: false })
    const [isOpen, setIsOpen] = useState(true)
    const dispatch = useDispatch()
    const _iframe = document.getElementById("iframe-render-page-view")

    useEffect(() => {
        setValue("value", selected.Setting.value)
    }, [selected])

    const props = {
        docBody: _iframe.contentWindow.document.body,
        selected: selected,
        onChange: (newValue) => {
            if (newValue) PageActions.updateLayerAll(dispatch, [newValue])
        }
    }

    return selected && <div className='col'>
        <div className="col" style={{ borderBottom: 'var(--neutral-main-border-color)' }}>
            <div className="row" style={{ padding: '0.8rem 1.2rem', gap: '0.4rem' }}>
                <Text className="semibold1" style={{ flex: 1 }}>Text</Text>
                <Winicon onClick={() => { setIsOpen(!isOpen) }} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/arrows/${isOpen ? "up" : "down"}-arrow`} />
            </div>
            <div className="col" style={{ padding: '0 1.2rem 0.8rem', gap: '0.8rem', display: isOpen ? 'flex' : 'none' }}>
                <TextField
                    onComplete={(ev) => ev.target.blur()}
                    className="regular1"
                    style={{ padding: "0.6rem 1.2rem", gap: "1.2rem", height: "fit-content" }}
                    name="value"
                    placeholder="value"
                    onFocus={(ev) => { ev.target.select() }}
                    prefix={<Winicon src={"outline/user interface/edit"} size={'1.6rem'} />}
                    register={register('value', {
                        onBlur: (ev) => {
                            const newValue = { ...selected, Setting: { ...selected.Setting, value: ev.target.value } }
                            PageActions.updateLayerAll(dispatch, [newValue])
                        }
                    })}
                />
            </div>
        </div>
        <BoxProperties {...props} />
        <FontProperties {...props} />
    </div>
}