import './view.css'
import { useDispatch, useSelector } from 'react-redux'
import { forwardRef, useRef, useState, React, useEffect } from 'react'
import { closePopup, Popup, Select1, showPopup, Text, TextField, ToastMessage, Winicon } from 'wini-web-components'
import { useFieldArray, useForm } from "react-hook-form"
import Paint from "../../../assets/Paint.svg";
import Menu from "../../../assets/Menu.svg";
import Dock from "../../../assets/IconDock.svg";
import Icon from "../../../assets/Icon.svg";
import Number from "../../../assets/IconNumber.svg";
import String from "../../../assets/IconString.svg";
import { randomGID, Ultis } from "../../../Utils"
import { ColorPicker } from '../../module/page/componentProperties/functions/funtions'
import { DesignTokenActions } from './reducer'
import { DesignTokenType } from './da'
import { TableController } from '../table/controller'

const PopupDesignToken = forwardRef(function PopupDesignToken(data, ref) {
    const designToken = useSelector((store) => store.designToken?.data);
    const dispatch = useDispatch();
    const reffs = useRef()

    useEffect(() => {
        DesignTokenActions.getData(dispatch)
    }, [])

    const [group, setGroup] = useState({ Id: "all", Name: "" });

    const showCreateVariable = (event) => {
        showPopup({
            ref: reffs,
            hideButtonClose: true,
            clickOverlayClosePopup: true,
            style: { backgroundColor: "#FFF", width: '17.2rem', height: '22.5%', boxShadow: '1px solid rgba(0, 0, 0, 0.3)', maxHeight: '100%', maxWidth: '100%', position: 'absolute', top: event.clientY - 200, left: event.clientX },
            content: <CreateVariable
                ref={reffs}
                groupId={group.Id}
                onClick={async (_newItem) => {
                    delete _newItem.id
                    DesignTokenActions.add(dispatch, { data: [_newItem] })
                    ToastMessage.success("Tạo thành công");
                }}
            />
        })
    }

    return <div className="col" style={{ flex: 1, width: '100%', height: '100%', backgroundColor: '#ffffff' }}>
        <Popup ref={reffs} />
        <div className="row" style={{ flex: 1, height: '100%' }}>
            <SidebarLeft setGroup={setGroup} group={group} />
            <div className="col" style={{ width: '100%', flex: '1', justifyContent: 'flex-start', height: '100%', border: '0.5px solid  #EAEAEC', paddingTop: "1rem", backgroundColor: "#EFEFF0" }}>
                <div className='col' style={{ flex: 1, height: '100%' }}>
                    <div className='col' style={{ height: "100%", }}>
                        <div className='row dynamic' style={{ padding: "1rem 1.5rem", gap: "2.25rem", borderBottom: "1px solid #EAEAEC", backgroundColor: "#EFEFF0", height: "5.4rem", }}>
                            <div className='row' style={{ padding: "0rem 1rem", alignItems: 'center', justifyContent: "center", width: "100%", }}>
                                <div className='row' style={{ justifyContent: "flex-start", alignItems: "center", flex: "1 0 0%" }}>
                                    <img src={Dock}></img>
                                </div>
                                <button type="button" className="row icon-button32" onClick={() => { closePopup(ref) }}><Winicon src={"fill/user interface/e-remove"} size="2.4rem" /></button>
                            </div>
                        </div>
                        <div className='col' style={{ flexGrow: "1", overflowY: "auto", maxHeight: 'calc(100dvh - 12.5rem)' }}>
                            <div className='col dynamic' >
                                <div className='col' style={{ width: '100%', }}>
                                    <div className='row' style={{ backgroundColor: "#FFF", borderBottom: "1px solid #EAEAEC", width: '100%' }}>
                                        <div className='row' style={{ alignItems: 'center', justifyContent: 'center', padding: "2rem 1.5rem", flex: '1', borderRight: "1px solid #EAEAEC" }}>
                                            <div className='row' style={{ alignItems: "center", flex: "1", justifyContent: "flex-start", padding: "0rem 1rem" }}>
                                                <Text className='heading-9' style={{ color: "rgba(97, 97, 107, 1)" }}>Name</Text>
                                            </div>
                                        </div>
                                        <div className='row' style={{ alignItems: 'center', justifyContent: 'center', padding: "2rem ", flex: '1', borderRight: "1px solid #EAEAEC" }}>
                                            <div className='row' style={{ alignItems: "center", flex: "1", justifyContent: "flex-start" }}>
                                                <Text className='heading-9' style={{ color: "rgba(97, 97, 107, 1)" }}>Light Mode</Text>
                                            </div>
                                        </div>
                                        <div className='row' style={{ alignItems: 'center', justifyContent: 'center', padding: "2rem ", flex: '1', borderRight: "1px solid #EAEAEC" }}>
                                            <div className='row' style={{ alignItems: "center", flex: "1", justifyContent: "flex-start" }}>
                                                <Text className='heading-9' style={{ color: "rgba(97, 97, 107, 1)" }}>Dark Mode</Text>
                                            </div>
                                        </div>
                                        <div className='row' style={{ alignItems: 'center', justifyContent: 'center', padding: "2rem ", flex: '1', borderRight: "1px solid #EAEAEC" }}>
                                            <div className='row' style={{ alignItems: "center", flex: "1", justifyContent: "flex-start" }}>
                                                <Text className='heading-9' style={{ color: "rgba(97, 97, 107, 1)" }}>Action</Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <GroupData group={group} />
                            </div>
                        </div>
                        <div className='row' style={{ alignItems: 'center', justifyContent: "flex-start", padding: "1rem 2.5rem", position: "sticky", bottom: "0px", backgroundColor: "#FFF" }}>
                            <button type='button' className='row' style={{ padding: '1rem 1.2rem', gap: "1rem", width: "150px", backgroundColor: '#EFEFF0', borderRadius: "1rem" }} onClick={(event) => {
                                showCreateVariable(event)
                            }}>
                                <Winicon src={"fill/user interface/e-add"} size={"1.4rem"} />
                                <Text className='button-text-3' style={{ color: 'rgba(97, 97, 107, 1)' }} >Create variable</Text>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
})

const GroupData = ({ group }) => {
    const Refs = useRef();
    const designToken = useSelector((store) => store.designToken?.data);
    const dispatch = useDispatch()
    const methods = useForm({ shouldFocusError: false, defaultValues: { dataByGroup: [] } })
    const arrayDataByGroup = useFieldArray({
        name: "dataByGroup",
        control: methods.control,
        keyName: undefined
    })

    const handleOnChange = async (ev) => {
        const valueString = JSON.stringify(ev.Value);
        delete ev.id;
        DesignTokenActions.edit(dispatch, { data: [{ ...ev, Value: valueString }] })
        arrayDataByGroup.update(arrayDataByGroup.fields.findIndex(e => e.Id === ev.Id), ev)
    }

    useEffect(() => {
        if (designToken?.length) {
            const _filter = designToken.filter(e => e.Type !== DesignTokenType.group && (group.Id.length !== 32 || e.ParentId === group.Id));
            methods.setValue('dataByGroup', _filter);
        }
    }, [designToken?.length, group?.Id])

    return (
        <>
            <div className='col' style={{ width: "100%" }}>
                <div className='col' style={{ borderTop: '1px solid #EAEAEC', width: '100%', overflow: 'auto' }}>
                    <Popup ref={Refs} />
                    {group.Id === "all" ?
                        <div className="col" style={{ alignItems: 'flex-start', backgroundColor: '#FFF', borderBottom: "var(--neutral-bolder-border-color)" }}>
                            {(designToken ?? []).filter(e => e.Type === DesignTokenType.group).map((item) => {
                                return <div className='col' key={item.Id} style={{ width: "100%" }} >
                                    <div className='col' style={{ width: '100%', justifyContent: 'flex-start', alignItems: "start", padding: "1.2rem 2.5rem", borderBottom: "1px solid #EAEAEC" }}>
                                        <Text className='label-3' style={{ color: "rgba(24, 24, 27, 1)" }}>{item.Name}</Text>
                                    </div>
                                    {arrayDataByGroup.fields.filter(e => e.ParentId === item.Id).map((ev, index) => {
                                        return <TitleColor
                                            key={`tc${index}`}
                                            item={methods.watch(`dataByGroup[${arrayDataByGroup.fields.findIndex(e => e.Id === ev.Id)}]`)}
                                            handleOnChange={handleOnChange}
                                        />
                                    })}
                                </div>
                            })}
                        </div>
                        :
                        <>
                            {arrayDataByGroup.fields.filter(e => e.Type !== DesignTokenType.group).map((ev, index) => {
                                return <TitleColor
                                    key={`tc${index}`}
                                    item={methods.watch(`dataByGroup[${arrayDataByGroup.fields.findIndex(e => e.Id === ev.Id)}]`)}
                                    handleOnChange={handleOnChange}
                                />
                            })}
                        </>
                    }
                </div>
            </div>
        </>
    )
}

const SidebarLeft = ({ setGroup, group }) => {
    const refEditGroup = useRef();
    const designToken = useSelector((store) => store.designToken.data);
    const dispatch = useDispatch();
    const [editId, setEdit] = useState();
    const arrSearchSkin = [
        { id: 1, name: "Skin" },
        { id: 2, name: "Store Color" }
    ]

    const moreActions = async (ev) => {
        showPopup({
            ref: refEditGroup,
            hideButtonClose: true,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', top: ev.clientY, left: ev.clientX },
            content: <div className='popup-actions col'>
                <button type='button' onClick={() => {
                    const _newItem = {
                        Id: randomGID(),
                        Name: 'new group',
                        Type: DesignTokenType.group,
                        Sort: Math.max(...designToken?.filter(e => e.Type === DesignTokenType.group).map(e => e.Sort)) + 1,
                        DateCreated: Date.now()
                    }
                    DesignTokenActions.add(dispatch, { data: [_newItem] })
                    setGroup({ Id: _newItem.Id, Name: _newItem.Name });
                    ToastMessage.success("Add group successfully!");
                    closePopup(refEditGroup)
                }}>
                    <Text className='button-text-3'>Add group</Text>
                </button>
                <button type='button' onClick={() => {
                    Ultis.setStorage("designToken-pid", Ultis.getCookie("pid"))
                    closePopup(refEditGroup)
                }}>
                    <Text className='button-text-3'>Copy all</Text>
                </button>
                {Ultis.getStorage("designToken-pid") && <button type='button' onClick={async () => {
                    const _designTokenController = new TableController(Ultis.getStorage("designToken-pid"), "designtoken")
                    const copyDesignTokens = await _designTokenController.getAll()
                    Ultis.clearStorage()
                    if (copyDesignTokens.code === 200 && copyDesignTokens.data.length) {
                        DesignTokenActions.add(dispatch, { data: copyDesignTokens.data })
                    }
                    closePopup(refEditGroup)
                }}>
                    <Text className='button-text-3'>Paste</Text>
                </button>}
            </div>
        })
    }

    const showEditGroup = (event, item) => {
        showPopup({
            ref: refEditGroup,
            hideButtonClose: true,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', top: event.clientY, left: event.clientX },
            content: <div className='popup-actions col' >
                <button type='button' onClick={() => {
                    setEdit(item.Id)
                    closePopup(refEditGroup)
                }}>
                    <Text className='button-text-3' >Edit</Text>
                </button>
                <button type='button' onClick={() => {
                    DesignTokenActions.delete(dispatch, { data: [item.Id] })
                    closePopup(refEditGroup)
                }}>
                    <Text className='button-text-3' >Delete</Text>
                </button>
            </div>
        })
    }

    return <div className="col" style={{ height: "100dvh" }}>
        <Popup ref={refEditGroup} />
        <div className='col' style={{ width: '100%', justifyContent: 'flex-start', height: "100%" }}>
            <div className='row' style={{ padding: "2rem", gap: "2rem", borderBottom: "1px solid #EAEAEC", backgroundColor: "#EFEFF0", height: '6.4rem' }}>
                <div style={{ flex: 1 }}>
                    <Select1 placeholder='Skin' style={{ backgroundColor: '#FFF' }} options={arrSearchSkin}></Select1>
                </div>
                <div style={{ cursor: 'pointer' }} onClick={moreActions}>
                    <img style={{ width: '1.6rem', height: '1.6rem' }} src={Menu}></img>
                </div>
            </div>
            <div className={`row groupTile ${group?.Id === "all" ? "selected" : ""}`} style={{ borderBottom: "var(--neutral-bolder-border-color)" }}>
                <div className='row' style={{ alignItems: "center", flex: "1", justifyContent: "flex-start" }}>
                    <Text className='body-3' style={{ color: "rgba(24, 24, 27, 1)" }} onClick={() => { setGroup({ Id: "all", Name: "" }) }}>All variables</Text>
                </div>
                <Text className='body-3' style={{ color: "rgba(24, 24, 27, 1)" }}>{designToken.filter(e => e.Type !== DesignTokenType.group)?.length}</Text>
            </div>
            <div className='row' style={{ padding: "2rem 1rem 0rem 1 rem", justifyContent: 'flex-start', alignItems: "center", borderRadius: "0rem 0rem 0rem 1rem" }}>
                <div className='col' style={{ padding: '0.6rem 0', gap: '0.6rem', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', overflow: 'auto' }}>
                    {designToken?.filter(e => e.Type === DesignTokenType.group)?.map((item, index) => {
                        return <div
                            key={item.Id}
                            className={`row groupTile ${group?.Id === item.Id ? "selected" : ""}`}
                            style={{ padding: "0.8rem 2rem", height: '100%', cursor: 'pointer' }}
                            onContextMenu={(event) => {
                                event.preventDefault()
                                showEditGroup(event, item)
                            }}
                            onClick={() => {
                                console.log(group)
                                setGroup({ Id: item.Id, Name: item.Name })
                            }}
                        >
                            {editId === item.Id ? <TextField
                                autoFocus
                                style={{ width: '100%', padding: '0.8rem 1.6rem' }}
                                onComplete={(ev) => { ev.target.blur() }}
                                defaultValue={item.Name}
                                onBlur={(ev) => {
                                    debugger
                                    delete ev.id
                                    DesignTokenActions.edit(dispatch, { data: [{ ...item, Name: ev.target.value.trim() }] })
                                    setEdit(undefined)
                                }}
                            /> : <Text className='label-4' style={{ padding: '0.9rem 1.7rem' }}>
                                {item.Name}
                            </Text>}
                        </div>
                    })}
                </div>
            </div>
        </div>
    </div>
}

const TitleColor = ({ item, handleOnChange }) => {
    const methods = useForm({ shouldFocusError: false })
    const dispatch = useDispatch()

    useEffect(() => {
        Object.keys(item).forEach(p => {
            methods.setValue(p, item[p])
        })
        if (item.Type === "color") {
            methods.setValue(`valueLight`, item.Value.lightMode.substring(0, 7))
            methods.setValue(`valueDark`, item.Value.darkMode.substring(0, 7))
            methods.setValue(`opacityValueLight`, Ultis.hexToPercent(item.Value.lightMode.substring(7)))
            methods.setValue(`opacityValueDark`, Ultis.hexToPercent(item.Value.darkMode.substring(7)))
        }
    }, [item])

    return <div key={item.Id} className='row' style={{ backgroundColor: "#FFF", borderBottom: "1px solid #EAEAEC", width: '100%' }}>
        <div className='row' style={{ alignItems: 'center', justifyContent: 'center', padding: "0rem 1.5rem", borderRight: '1px solid  #EAEAEC', flex: '1', height: "6.3rem" }}>
            <div className='row' style={{ alignItems: 'center', justifyContent: 'flex-start', padding: "0rem 1rem ", gap: '1rem', width: "100%" }}>
                <TextField
                    className='body-3'
                    defaultValue={item.Name}
                    prefix={<img style={{ width: "2rem", height: "2rem" }} src={Paint}></img>}
                    style={{ color: "rgba(49, 49, 53, 1)", width: '100%' }}
                    onComplete={(ev) => { ev.target.blur() }}
                    onBlur={(ev) => {
                        DesignTokenActions.edit(dispatch, { data: [{ ...item, Name: ev.target.value.trim() }] })
                    }}
                />
            </div>
        </div>
        <div className='row' style={{ alignItems: "center", justifyContent: "center", padding: "0rem 2rem", borderRight: '1px solid  #EAEAEC', flex: '1', height: "6.3rem" }} >
            <div className='row' style={{ alignItems: 'center', justifyContent: 'flex-start', padding: "0rem ", gap: '1rem', width: "100%" }} >
                {item.Type === "color" ?
                    <ColorPicker
                        colorName={`valueLight`}
                        colorOpcaityName={`opacityValueLight`}
                        register={methods.register}
                        colorValue={methods.watch(`Value.lightMode`)}
                        onChange={({ colorValue }) => {
                            const newItem = { ...item, Value: { ...item.Value, lightMode: colorValue } }
                            handleOnChange(newItem)
                        }}
                    />
                    :
                    <TextField
                        className='placeholder-2'
                        type={`${item.Type}`}
                        style={{ color: "rgba(135, 135, 146, 1)", width: '100%' }}
                        name={`Value.lightMode`}
                        register={methods.register(`Value.lightMode`, {
                            onBlur: (ev) => {
                                let _vl = ev.target.value.trim()
                                if (_vl.length) {
                                    const newItem = { ...item, Value: { ...item.Value, lightMode: _vl } }
                                    handleOnChange(newItem)
                                }
                            }
                        })}
                    >
                        {methods.watch(`Value.lightMode`)}
                    </TextField>
                }
            </div>
        </div>
        <div className='row' style={{ alignItems: "center", justifyContent: "center", padding: "0rem 2rem", borderRight: '1px solid  #EAEAEC', flex: '1', height: "6.3rem" }}>
            <div className='row' style={{ alignItems: 'center', justifyContent: 'flex-start', padding: "0rem ", gap: '1rem', width: "100%" }}>
                {item.Type === "color" ?
                    <ColorPicker
                        colorName={`valueDark`}
                        colorOpcaityName={`opacityValueDark`}
                        register={methods.register}
                        colorValue={methods.watch(`Value.darkMode`)}
                        onChange={({ colorValue }) => {
                            const newItem = { ...item, Value: { ...item.Value, darkMode: colorValue } }
                            handleOnChange(newItem)
                        }}
                    />
                    :
                    <TextField
                        className='placeholder-2'
                        type={`${item.Type}`}
                        name={`Value.darkMode`}
                        register={methods.register(`Value.darkMode`, {
                            onBlur: (ev) => {
                                let _vl = ev.target.value.trim()
                                if (_vl.length) {
                                    const newItem = { ...item, Value: { ...item.Value, darkMode: _vl } }
                                    handleOnChange(newItem)
                                }
                            }
                        })}
                        style={{ color: "rgba(135, 135, 146, 1)", width: '100%' }}>
                        {methods.watch(`Value.darkMode`)}
                    </TextField>
                }
            </div>
        </div>
        <div className='row' style={{ alignItems: "center", justifyContent: "center", padding: "0rem 2rem", borderRight: '1px solid  #EAEAEC', flex: '1', height: "6.3rem" }}>
            <input value='delete' type="button" onClick={() => { DesignTokenActions.delete(dispatch, { data: [item.id ?? item.Id] }) }} />
        </div>

    </div>
}

const CreateVariable = (data, ref) => {
    const onClick = async (ev) => {
        debugger
        const valueString = JSON.stringify(ev.Value);
        const _newItem = {
            Id: randomGID(),
            Name: 'Defaut',
            ...ev,
            Sort: Date.now(),
            Value: valueString,
            DateCreated: Date.now()
        }
        data.onClick(_newItem);
        // closePopup(ref)
    }
    return (
        <>
            <div className='col' style={{ justifyContent: "center", flex: 1 }}>
                <div
                    className='row'
                    style={{ alignItems: "center", justifyContent: "flex-start", padding: "1rem 1.75rem", gap: "1rem", cursor: "pointer" }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#f0f0f0"}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}
                >
                    <img style={{ width: "1.25rem", height: "1.25rem" }} src={Icon}></img>
                    <Text className='label-4' style={{ color: "rgba(49, 49, 53, 1)" }}>Icon</Text>
                </div>
                <div
                    className='row'
                    style={{ alignItems: "center", justifyContent: "flex-start", padding: "1rem 1.75rem", gap: "1rem", cursor: "pointer" }}
                    onClick={() => { onClick({ Type: 'color', Value: { lightMode: '#FFFFFFFF', darkMode: '#000000FF' }, ParentId: data.groupId }) }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#f0f0f0"}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}
                >
                    <img style={{ width: "1.25rem", height: "1.25rem" }} src={Paint}></img>
                    <Text className='label-4' style={{ color: "rgba(49, 49, 53, 1)" }}>Color</Text>
                </div>
                <div
                    className='row'
                    style={{ alignItems: "center", justifyContent: "flex-start", padding: "1rem 1.75rem", gap: "1rem", cursor: "pointer" }}
                    onClick={() => { onClick({ Type: 'number', Value: { lightMode: 0, darkMode: 0 }, ParentId: data.groupId }) }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#f0f0f0"}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}
                >
                    <img style={{ width: "1.25rem", height: "1.25rem" }} src={Number}></img>
                    <Text className='label-4' style={{ color: "rgba(49, 49, 53, 1)" }}>Number</Text>
                </div>
                <div
                    className='row' style={{ alignItems: "center", justifyContent: "flex-start", padding: "1rem 1.75rem", gap: "1rem", cursor: "pointer" }}
                    onClick={() => { onClick({ Type: 'text', Value: { lightMode: '', darkMode: '' }, ParentId: data.groupId }) }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#f0f0f0"}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}
                >
                    <img style={{ width: "1.25rem", height: "1.25rem" }} src={String}></img>
                    <Text className='label-4' style={{ color: "rgba(49, 49, 53, 1)" }}>String</Text>
                </div>
            </div>
        </>
    )
}

export default PopupDesignToken