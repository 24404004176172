import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Text, TextField, Winicon } from "wini-web-components";
import { NavLink } from "react-router-dom";

export default function DimensionsHeader() {
    const methods = useForm({ shouldFocusError: false })
    const dimentionList = [{ id: "570", value: "MIN" }, { id: "760", value: "SM" }, { id: "990", value: "MD" }, { id: "1190", value: "LG" }, { id: "1430", value: "XL" }, { id: "1590", value: "XXL" }];
    const _iframe = document.getElementById("iframe-render-page-view")

    useEffect(() => {
        if (!_iframe) return
        setTimeout(() => {
            const resizeObserver = new ResizeObserver((entries) => {
                changeDimension(_iframe.offsetWidth, _iframe.offsetHeight);
                methods.setValue("width", _iframe.offsetWidth)
                methods.setValue("height-placeholder", _iframe.offsetHeight)
                console.log("Size changed");
            });
            resizeObserver.observe(_iframe);
            return () => resizeObserver.unobserve(_iframe);
        }, 100)
    }, [_iframe])

    function changeDimension(width) {
        if (width < 576 && width > 0) {
            methods.setValue("dimention-options", "MIN");
        } else if (width < 768 && width > 576) {
            methods.setValue("dimention-options", "SM");
        } else if (width < 992 && width > 768) {
            methods.setValue("dimention-options", "MD");
        } else if (width < 1200 && width > 992) {
            methods.setValue("dimention-options", "LG");
        } else if (width < 1500 && width > 1200) {
            methods.setValue("dimention-options", "XL");
        } else if (width > 1500) {
            methods.setValue("dimention-options", "XXL");
        }
    }

    return <div className="row setting-page-header">
        <div className='row' style={{ justifyContent: 'center', alignItems: 'center', height: "100%", flex: 1, padding: "1rem 0rem" }}>
            <div className='row' style={{ alignItems: "center", justifyContent: "flex-start" }}>
                <div className="row" style={{ gap: 4, justifyContent: "flex-end" }}>
                    <Text className='semibold1' >Dimentions: </Text>
                    {dimentionList.map((e, i) => {
                        return <button onClick={() => {
                            const _tmp = methods.getValues('dimention-options')
                            if (_tmp !== e.id) {
                                methods.setValue("dimention-options", e.value)
                                _iframe.style.width = e.id + 'px'
                            }
                        }} type="button" style={{ width: "7.2rem", borderRadius: '0.4rem', color: methods.watch("dimention-options") === e.value ? "white" : "#999999", background: methods.watch("dimention-options") === e.value ? "#999999" : "transparent" }} key={i}> <Text className='semibold1' style={{ textAlign: "center", }} >{e.value}</Text></button>
                    })}
                </div>
                <div style={{ paddingLeft: 20, alignItems: 'center', flexDirection: 'row', display: 'flex', justifyItems: 'center', gap: 4 }} >
                    <TextField
                        className="regular1"
                        name="width"
                        onChange={(ev) => ev.target.blur()}
                        style={{ width: '18%', height: 20 }}
                        onComplete={(ev) => ev.target.blur()}
                        register={methods.register('width', {
                            onBlur: (ev) => {
                                _iframe.style.width = ev.target.value.trim() + 'px'
                            }
                        })}
                        type="number"
                    />
                    <Text className="semibold3" style={{ color: '#999999' }}>x</Text>
                    <TextField
                        className="regular1"
                        name="height"
                        onComplete={(ev) => ev.target.blur()}
                        placeholder={`${methods.getValues("height-placeholder") ?? ''}`}
                        style={{ width: '18%', height: 20 }}
                        register={methods.register('height', {
                            onBlur: (ev) => {
                                _iframe.style.height = ev.target.value.trim() + 'px'
                            }
                        })}
                        type="number"
                    />
                    <NavLink to={`/demoFullPage`} target="_blank" style={{ padding: ".4rem 1.4rem", borderRadius: '0.4rem' }}> <Winicon src={"fill/arrows/triangle-right"} color="#fff" size="lg" />
                    </NavLink>
                </div>

            </div>
        </div>
    </div>
}