import styles from '../module/manager/view.module.css'
import { CheckboxForm, DatePickerForm, ImportFileForm, GroupCheckboxForm, RadioButtonForm, RangeForm, Select1Form, SelectMultipleForm, SwitchForm, TextAreaForm, TextFieldForm, CKEditorForm, RateForm } from "../../project-component/component-form";
import { CalendarType, Rating, Text, Winicon } from "wini-web-components";
import { Ultis } from "../../Utils.js";
import { differenceInCalendarDays, differenceInMinutes } from "date-fns";
import { validate } from "validate.js";
import { BaseDA } from "../../da/baseDA";
import { ComponentType, FEDataType, ValidateType } from "../wini/table/da";
import bcrypt from 'bcryptjs';
import { useState } from 'react';

export function RenderComponentByType({ fieldItem, methods, className, style = {}, labelStyle = {}, label }) {
    switch (fieldItem.Form.ComponentType) {
        case ComponentType.text:
            return <Text>text</Text>
        case ComponentType.button:
            return <button type="button"></button>
        case ComponentType.textField:
            return fieldItem.DataType === FEDataType.PASSWORD ? <InputPasswordForm
                methods={methods}
                name={fieldItem.Name}
                label={label ?? fieldItem.Form.Label}
                required={fieldItem.Form.Required}
                readOnly={fieldItem.Form.ReadOnly}
                disabled={fieldItem.Form.Disabled}
                placeholder={fieldItem.Form.Placeholder}
                maxLength={fieldItem.Form.Max}
                className={className}
                style={style}
                labelStyle={labelStyle}
            /> : <TextFieldForm
                required={fieldItem.Form.Required}
                readOnly={fieldItem.Form.ReadOnly}
                disabled={fieldItem.Form.Disabled}
                label={label ?? fieldItem.Form.Label}
                placeholder={fieldItem.Form.Placeholder}
                name={fieldItem.Name}
                maxLength={fieldItem.Form.Max}
                className={className}
                style={style}
                labelStyle={labelStyle}
                type={fieldItem.DataType === FEDataType.NUMBER ? 'number' : fieldItem.DataType === FEDataType.MONEY ? 'money' : 'text'}
                register={methods.register}
                errors={methods.formState.errors}
            />
        case ComponentType.textArea:
            return <TextAreaForm
                className={className}
                style={{ alignItems: 'start', ...style }}
                labelStyle={labelStyle}
                required={fieldItem.Form.Required}
                readOnly={fieldItem.Form.ReadOnly}
                disabled={fieldItem.Form.Disabled}
                label={label ?? fieldItem.Form.Label}
                placeholder={fieldItem.Form.Placeholder}
                name={fieldItem.Name}
                maxLength={fieldItem.Form.Max}
                register={methods.register}
                errors={methods.formState.errors}
            />
        case ComponentType.switch:
            return <div className={className ?? "row"} style={{ width: '100%', justifyContent: 'space-between', ...style }}>
                {fieldItem.Form.Title ? <Text className="label-3" style={labelStyle}>{fieldItem.Form.Title}</Text> : undefined}
                <SwitchForm
                    disabled={fieldItem.Form.Disabled}
                    label={label ?? fieldItem.Form.Label}
                    control={methods.control}
                    name={fieldItem.Name}
                    size={'2.4rem'}
                />
            </div>
        case ComponentType.rate:
            return <div className={className ?? "row"} style={{ width: '100%', justifyContent: 'space-between', ...style }}>
                {fieldItem.Form.Title ? <Text className="label-3" style={labelStyle}>{fieldItem.Form.Title}</Text> : undefined}
                <RateForm
                    label={label ?? fieldItem.Form.Label}
                    control={methods.control}
                    name={fieldItem.Name}
                    size={'2.4rem'}
                />
            </div>
        case ComponentType.radio:
            return <div className={className ?? "row"} style={{ gap: '2.4rem', width: '100%', ...style }}>
                <div className="row" style={{ gap: '0.4rem', ...labelStyle }}>
                    <Text className="label-3">{label ?? fieldItem.Form.Label}</Text>
                    {fieldItem.Form.Required ? <Text className="label-4" style={{ color: '#E14337' }}>*</Text> : null}
                </div>
                <div className="row" style={{ gap: '1.6rem', flex: 1, flexWrap: 'wrap' }}>
                    {fieldItem.Form.Options?.map(e => {
                        return <RadioButtonForm
                            key={e.id}
                            label={e.name}
                            name={fieldItem.Name}
                            value={e.id}
                            register={methods.register}
                            size={'1.8rem'}
                        />
                    })}
                </div>
            </div>
        case ComponentType.select1:
            return <Select1Form
                required={fieldItem.Form.Required}
                disabled={fieldItem.Form.Disabled}
                label={label ?? fieldItem.Form.Label}
                placeholder={fieldItem.Form.Placeholder}
                name={fieldItem.Name}
                options={fieldItem.Form.Options ?? []}
                control={methods.control}
                errors={methods.formState.errors}
                className={className}
                style={style}
                labelStyle={labelStyle}
            />
        case ComponentType.selectMultiple:
            return <SelectMultipleForm
                required={fieldItem.Form.Required}
                disabled={fieldItem.Form.Disabled}
                label={label ?? fieldItem.Form.Label}
                placeholder={fieldItem.Form.Placeholder}
                name={fieldItem.Name}
                options={fieldItem.Form.Options ?? []}
                control={methods.control}
                errors={methods.formState.errors}
                className={className}
                style={style}
                labelStyle={labelStyle}
            />
        case ComponentType.checkbox:
            return fieldItem.Form?.Options?.length ? <GroupCheckboxForm
                disabled={fieldItem.Form.Disabled}
                label={label ?? fieldItem.Form.Label}
                control={methods.control}
                name={fieldItem.Name}
                className={className}
                style={style}
                labelStyle={labelStyle}
                dataType={'string'}
                options={fieldItem.Form.Options}
            /> :
                <CheckboxForm
                    disabled={fieldItem.Form.Disabled}
                    label={label ?? fieldItem.Form.Label}
                    control={methods.control}
                    name={fieldItem.Name}
                    style={style}
                />
        case ComponentType.upload:
            return <ImportFileForm
                required={fieldItem.Form.Required}
                label={label ?? fieldItem.Form.Label}
                control={methods.control}
                name={fieldItem.Name}
                allowType={fieldItem.Form.AcceptFiles?.split(',')}
                subTitle={fieldItem.Form.AcceptFiles}
                maxSize={fieldItem.Form.Max}
                className={className}
                style={style}
                labelStyle={labelStyle}
            />
        case ComponentType.datePicker:
            return <DatePickerForm
                required={fieldItem.Form.Required}
                disabled={fieldItem.Form.Disabled}
                label={label ?? fieldItem.Form.Label}
                placeholder={fieldItem.Form.Placeholder}
                name={fieldItem.Name}
                errors={methods.formState.errors}
                control={methods.control}
                hideButtonToday={true}
                pickerType={CalendarType.DATE}
                className={className}
                style={style}
                labelStyle={labelStyle}
            />
        case ComponentType.dateTimePicker:
            return <DatePickerForm
                required={fieldItem.Form.Required}
                disabled={fieldItem.Form.Disabled}
                label={label ?? fieldItem.Form.Label}
                placeholder={fieldItem.Form.Placeholder}
                name={fieldItem.Name}
                errors={methods.formState.errors}
                control={methods.control}
                pickerType={CalendarType.DATETIME}
                className={className}
                style={style}
                labelStyle={labelStyle}
            />
        case ComponentType.ckEditor:
            return <CKEditorForm
                className={className}
                style={{ alignItems: 'start', ...style }}
                labelStyle={labelStyle}
                required={fieldItem.Form.Required}
                disabled={fieldItem.Form.Disabled}
                label={label ?? fieldItem.Form.Label}
                name={fieldItem.Name}
                errors={methods.formState.errors}
                control={methods.control}
            />
        case ComponentType.range:
            const splitName = fieldItem.Name.split(',')
            const splitPlaceholder = fieldItem.Form.Placeholder?.split(',')
            return <RangeForm
                className={className}
                type={fieldItem.DataType === FEDataType.DATE ? 'date' : fieldItem.DataType === FEDataType.DATETIME ? 'date-time' : 'number'}
                startName={splitName[0].trim()}
                endName={splitName[1].trim()}
                placeholderStart={splitPlaceholder?.[0]?.trim()}
                placeholderEnd={splitPlaceholder?.[1]?.trim()}
                control={methods.control}
                label={label ?? fieldItem.Form.Label}
                disabled={fieldItem.Form.Disabled}
                errors={methods.formState.errors}
                getValues={methods.getValues}
                style={style}
                labelStyle={labelStyle}
            />
        default:
            return <div></div>
    }
}

const InputPasswordForm = ({ methods, name, label, required, readOnly, disabled, placeholder, maxLength, className, style, labelStyle }) => {
    const [isShowPass, setIsShowPass] = useState(false)

    return <TextFieldForm
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        name={name}
        maxLength={maxLength}
        className={className}
        style={style}
        labelStyle={labelStyle}
        register={methods.register}
        errors={methods.formState.errors}
        type={isShowPass ? "text" : "password"}
        suffix={<button type="button" onClick={() => { setIsShowPass(!isShowPass) }} className="row"><Winicon src={`outline/user interface/${isShowPass ? "view" : "hide"}`} size={"1.4rem"} /></button>}
    />
}

export const hashPassword = async (password) => {
    const saltRounds = 10; // Số vòng lặp để tạo muối
    try {
        // Generate a salt and hash the password
        const salt = await bcrypt.genSalt(saltRounds);
        const hashedPassword = await bcrypt.hash(password, salt);
        return hashedPassword;
    } catch (error) {
        console.error("Hashing error:", error);
        return undefined
    }
}

// {Name:, Validate}
export async function validateForm({ list = [], formdata }) {
    validate.validators.customDate = customValidateDateTime
    validate.validators.myAsyncValidator = myAsyncValidator
    validate.options = { fullMessages: false }
    const myValidators = validateByType({ list: list })
    let res = validate(formdata, myValidators.validator)
    if (!res && Object.keys(myValidators.asyncValidator).length) {
        try {
            res = await validate.async(formdata, myValidators.asyncValidator)
        } catch (error) {
            res = error
        }
    }
    return res
}

function validateByType({ list = [] }) {
    let validator = {}
    let asyncValidator = {}
    list.forEach(e => {
        let eValidateConfig = {}
        e.Validate?.forEach(el => {
            switch (el.type) {
                case ValidateType.email:
                    eValidateConfig.email = { message: el.message ?? 'Không đúng định dạng email' }
                    break;
                case ValidateType.minLength:
                    eValidateConfig.length = { ...(eValidateConfig.length ?? {}), minimum: el.value, tooShort: el.message ?? `Tối thiểu ${el.value} ký tự` }
                    break;
                case ValidateType.maxLength:
                    eValidateConfig.length = { ...(eValidateConfig.length ?? {}), maximum: el.value, tooLong: el.message ?? `Tối da ${el.value} ký tự` }
                    break;
                case ValidateType.number:
                    eValidateConfig.format = { pattern: "[0-9]+", flags: "i", message: el.message ?? `Chỉ cho phép ký tự số` }
                    break;
                case ValidateType.phone:
                    eValidateConfig.format = { pattern: "(84|0[3|5|7|8|9])+([0-9]{8})\b", flags: "g", message: el.message ?? `Số điện thoại không hợp lệ` }
                    break;
                case ValidateType.date:
                    eValidateConfig.customDate = { dateOnly: true, message: el.message ?? `Không đúng định dạng dd/mm/yyyy` }
                    break;
                case ValidateType.dateTime:
                    eValidateConfig.customDate = { message: el.message ?? `Không đúng định dạng dd/mm/yyyy hh:mm` }
                    break;
                case ValidateType.earliestDate:
                    eValidateConfig.customDate = { dateOnly: true, earliest: el.value, tooEarly: el.message ?? `Không được trước ${Ultis.datetoString(new Date(el.value))}` }
                    break;
                case ValidateType.latestDate:
                    eValidateConfig.customDate = { dateOnly: true, latest: el.value, tooLate: el.message ?? `Không được sau ${Ultis.datetoString(new Date(el.value))}` }
                    break;
                case ValidateType.earliestTime:
                    eValidateConfig.customDate = { earliest: el.value, tooEarly: el.message ?? `Không được trước ${Ultis.datetoString(new Date(el.value))}` }
                    break;
                case ValidateType.latestTime:
                    eValidateConfig.customDate = { latest: el.value, tooLate: el.message ?? `Không được sau ${Ultis.datetoString(new Date(el.value))}` }
                    break;
                case ValidateType.greaterThan:
                    eValidateConfig.numericality = { ...(eValidateConfig.numericality ?? {}), greaterThan: el.value, notGreaterThan: el.message ?? `Giá trị phải lớn hơn ${el.value}` }
                    break;
                case ValidateType.greaterThanOrEqualTo:
                    eValidateConfig.numericality = { ...(eValidateConfig.numericality ?? {}), greaterThanOrEqualTo: el.value, notGreaterThan: el.message ?? `Giá trị không được nhỏ hơn ${el.value}` }
                    break;
                case ValidateType.lessThan:
                    eValidateConfig.numericality = { ...(eValidateConfig.numericality ?? {}), lessThan: el.value, notLessThan: el.message ?? `Giá trị phải nhỏ hơn ${el.value}` }
                    break;
                case ValidateType.lessThanOrEqualTo:
                    eValidateConfig.numericality = { ...(eValidateConfig.numericality ?? {}), lessThanOrEqualTo: el.value, notLessThanOrEqualTo: el.message ?? `Giá trị không được lớn hơn ${el.value}` }
                    break;
                case ValidateType.async:
                    asyncValidator[e.Name] = { myAsyncValidator: { url: el.value } }
                    break;
                default:
                    break;
            }
        })
        validator[e.Name] = eValidateConfig
    })
    return {
        validator: validator,
        asyncValidator: asyncValidator
    }
}

function customValidateDateTime(value, options) {
    try {
        const parseValue = typeof value === 'string' ? Ultis.stringToDate(value, options.dateOnly ? 'dd/mm/yyyy' : 'dd/mm/yyyy hh:mm') : (new Date(value))
        if (options.earliest) {
            try {
                var _earliest = typeof options.earliest === 'string' ? Ultis.stringToDate(value, options.dateOnly ? 'dd/mm/yyyy' : 'dd/mm/yyyy hh:mm') : (new Date(options.earliest))
            } catch (error) {
                console.log(error)
            }
        }
        if (options.latest) {
            try {
                var _latest = typeof options.latest === 'string' ? Ultis.stringToDate(value, options.dateOnly ? 'dd/mm/yyyy' : 'dd/mm/yyyy hh:mm') : (new Date(options.latest))
            } catch (error) {
                console.log(error)
            }
        }
        if (isNaN(parseValue)) {
            return options.message;
        } else if (_earliest) {
            if (options.dateOnly && differenceInCalendarDays(parseValue, _earliest) < 0) {
                return options.tooEarly
            } else if (!options.dateOnly && differenceInMinutes(parseValue, _earliest) < 0) {
                return options.tooEarly
            }
        } else if (_latest) {
            if (options.dateOnly && differenceInCalendarDays(parseValue, _latest) < 0) {
                return options.tooLate
            } else if (!options.dateOnly && differenceInMinutes(parseValue, _latest) < 0) {
                return options.tooLate
            }
        }
    } catch (error) {
        return options.message;
    }
    return
};


async function myAsyncValidator(value, options) {
    console.log("????????: ", value, " -----------: ", options)
    if (options.url) {
        const res = await BaseDA.post(options.url, {
            body: { value: value }
        })
        if (res) {
            if (res.code !== 200) return res.message
        }
    }
    return undefined
}

export const selectColList = []

export const regexResponsiveClass = /(remain|^col([0-9]|1[0-9]|2[0-4]))/;

export function getTableConfig(item, data) {
    switch (item.DataType) {
        case FEDataType.GID:
            var _minW = '8rem'
            if (data) var _value = data[item.Name]
            break;
        case FEDataType.STRING:
            var _minW = '32rem'
            if (data) var _value = data[item.Name]
            break;
        case FEDataType.BOOLEAN:
            var _minW = '12rem'
            if (data) var _value = data[item.Name] ? "true" : "false"
            break;
        case FEDataType.NUMBER:
            var _minW = '12rem'
            if (data) var _value = data[item.Name] ? typeof data[item.Name] === 'string' ? parseFloat(data[item.Name]) : data[item.Name] : undefined
            if (item.Form?.ComponentType === ComponentType.rate) {
                _minW = '8rem'
                _value = <Rating value={_value} />
            }
            break;
        case FEDataType.DATE:
            var _minW = '16rem'
            if (data) var _value = data[item.Name] ? Ultis.datetoString(new Date(typeof data[item.Name] === 'string' ? parseInt(data[item.Name]) : data[item.Name])) : undefined
            break;
        case FEDataType.DATETIME:
            var _minW = '24rem'
            if (data) var _value = data[item.Name] ? Ultis.datetoString(new Date(typeof data[item.Name] === 'string' ? parseInt(data[item.Name]) : data[item.Name]), "dd/MM/yyyy hh:mm") : undefined
            break;
        case FEDataType.MONEY:
            var _minW = '20rem'
            if (data) var _value = data[item.Name] ? Ultis.money(data[item.Name] === 'string' ? parseInt(data[item.Name]) : data[item.Name]) : undefined
            break;
        case FEDataType.PASSWORD:
            var _minW = '10rem'
            if (data) var _value = data[item.Name] ? "************" : undefined
            break;
        case FEDataType.HTML:
            var _minW = '28rem'
            if (data) var _value = <Text className={`body-3 ${styles['html-value-cell']}`} style={{ '--max-line': 2, width: "100%", maxHeight: "8.8rem" }} html={data[item.Name]} />
            break;
        case FEDataType.FILE:
            var _minW = '12rem'
            break;
        default:
            break;
    }
    if (item.Form?.Options?.length && data) {
        if (item.Form.ComponentType === ComponentType.select1) {
            _value = item.Form.Options.find(e => e.id === data[item.Name])?.name ?? _value
        } else {
            _value = item.Form.Options.filter(e => data[item.Name].includes(e.id)).map(e => e.name).join(",")
        }
    }
    return { "_minW": _minW, "_value": _value }
}