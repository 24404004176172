import { closePopup, Popup, Select1, showPopup, Text, TextField, Winicon } from "wini-web-components"
import { ColorPicker, getSizeValue, listBreakpoint, tilteBreakpoints } from "./funtions"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { regexResponsiveClass } from "../../../config"
import { OutlineRadius4Angle, OutlineRadiusAll } from "../../../../../assets/icon"
import { Ultis } from "../../../../../Utils"
import { DesignTokenType } from "../../../../wini/designToken/da"

export const cssRegex = /^(auto|fit-content|inherit|initial|unset|none|0|([\d.]+(px|em|rem|%|vh|dvh|vw|vmin|vmax|ex|ch|cm|mm|in|pt|pc))|#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})|(rgb|rgba|hsl|hsla)\(\s*(\d{1,3}%?\s*,\s*){2}\d{1,3}%?\s*(,\s*[\d.]+\s*)?\))$/;
export const borderWidthRegex = /\b(thin|medium|thick|\d+(\.\d+)?(px|em|rem|vw|vh|dvh)?)\b(\s+\b(thin|medium|thick|\d+(\.\d+)?(px|em|rem|vw|vh|dvh)?)\b){0,3}/;
export const onFocus = (ev, methods) => {
    if (methods) methods.setValue('_tmp', ev.target.value)
    ev.target.select()
}

export function OnChangeWH({ methods, onChange, selectedLayer, selectedElement, breakPoint, setBreakPoint }) {
    const layers = useSelector((store) => store.page.data?.layers)
    const _iframe = document.getElementById("iframe-render-page-view")
    const ref = useRef()

    useEffect(() => {
        if (selectedLayer.ParentId) {
            const parentDirection = window.getComputedStyle(_iframe.contentWindow.document.getElementById(selectedLayer.ParentId)).flexDirection
            const _clN = [..._iframe.contentWindow.document.getElementById(selectedLayer.Id).classList].find(e => regexResponsiveClass.test(e))
            if (_clN) {
                const _brp = [..._iframe.contentWindow.document.getElementById(selectedLayer.Id).classList].map(item => getSizeValue(item)).filter(Boolean);
                if (_brp.length > 0) {
                    setBreakPoint(true)
                    _brp.forEach(e => {
                        methods.setValue(`${e.brp}-brp`, e.value)
                    })
                } else {
                    setBreakPoint(false)
                    tilteBreakpoints.forEach(e => {
                        methods.setValue(`${e}-brp`, undefined)
                    })
                }
                if (!_clN.includes("-") && (parentDirection === "row" | _clN !== "remain")) {
                    methods.setValue('colValue', _clN)
                } else {
                    methods.setValue("width", selectedLayer.Setting.style?.width ?? "Fit")
                }
            } else {
                tilteBreakpoints.forEach(e => {
                    methods.setValue(`${e}-brp`, undefined)
                })
                methods.setValue('colValue', undefined)
                methods.setValue("width", selectedLayer.Setting.style?.width ?? "Fit")
            }
            if (_clN === "remain" && parentDirection === "column") {
                methods.setValue('rowValue', _clN)
            } else {
                methods.setValue('rowValue', undefined)
                methods.setValue("height", selectedLayer.Setting.style?.height ?? "Fit")
            }
        }
    }, [selectedLayer])

    const widthOptions = (ev) => {
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { left: ev.pageX, top: ev.pageY, borderRadius: '0.4rem' },
            content: <div className="col popup-actions" style={{ maxHeight: 'fit-content' }}>
                {window.getComputedStyle(selectedElement.parentElement.closest('.layer-item')).flexDirection === "row" && <button type="button" onClick={() => {
                    const _tmp = methods.getValues('colValue')
                    const { columnGap, gap } = layers.find(e => e.Id === selectedLayer.ParentId)?.Setting?.style
                    onChange({ '--gutter': columnGap ?? gap }, [...selectedLayer.Setting.className.split(" "), "remain"].join(" "))
                    closePopup(ref)
                }}>
                    <Text className="button-text-5">Flex</Text>
                </button>}
                <button type="button" onClick={() => {
                    onChange({ width: undefined })
                    closePopup(ref)
                }}>
                    <Text className="button-text-5">Fit</Text>
                </button>
                <button type="button" onClick={() => {
                    onChange({ width: selectedElement.offsetWidth + "px" })
                    closePopup(ref)
                }}>
                    <Text className="button-text-5">Fixed</Text>
                </button>
            </div >
        })
    }
    const heightOptions = (ev) => {
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { left: ev.pageX, top: ev.pageY, borderRadius: '0.4rem' },
            content: <div className="col popup-actions" style={{ maxHeight: 'fit-content' }}>
                {window.getComputedStyle(selectedElement.parentElement.closest('.layer-item')).flexDirection === "column" && selectedLayer.Setting?.className && <button type="button" onClick={() => {
                    console.log('====================================');
                    console.log(selectedLayer.Setting);
                    console.log('====================================');
                    onChange({}, [...selectedLayer.Setting?.className?.split(" "), "remain"].join(" "))
                    closePopup(ref)
                }}>
                    <Text className="button-text-5">Remain</Text>
                </button>}
                <button type="button" onClick={() => {
                    onChange({ height: undefined })
                    closePopup(ref)
                }}>
                    <Text className="button-text-5">Fit</Text>
                </button>
                <button type="button" onClick={() => {
                    onChange({ height: selectedElement.offsetHeight + "px" })
                    closePopup(ref)
                }}>
                    <Text className="button-text-5">Fixed</Text>
                </button>
            </div >
        })
    }

    return <div className="col" style={{ gap: '0.8rem' }}>
        <Text className="semibold1" style={{ color: '#999999' }}>Sizes</Text>
        <div className="row" style={{ gap: '0.8rem' }}>
            <Popup ref={ref} />
            <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                {methods.watch("colValue") ? <Select1
                    readOnly
                    className="regular1"
                    prefix={<Text className="regular1">W</Text>}
                    popupClassName="select-col"
                    value={methods.watch('colValue')}
                    options={['fixed', ...listBreakpoint].map(e => {
                        return {
                            id: e,
                            name: e
                        }
                    })}
                    onChange={(ev) => {
                        const _tmp = methods.getValues('colValue')
                        const { columnGap, gap } = layers.find(e => e.Id === selectedLayer.ParentId)?.Setting?.style
                        if (_tmp !== ev.id) {
                            if (ev.id == 'fixed') {
                                onChange({ width: selectedElement.offsetWidth + "px", '--gutter': undefined }, selectedLayer.Setting.className.split(" ").filter(e => e !== _tmp).join(" "))
                            } else {
                                onChange({ '--gutter': columnGap ?? gap }, selectedLayer.Setting.className.split(" ").map(e => e === _tmp ? ev.id : e).join(" "))
                            }
                        }
                    }}
                /> :
                    <TextField
                        onComplete={(ev) => ev.target.blur()}
                        className="regular1"
                        prefix={<Text className="regular1">W</Text>}
                        onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                        name="width"
                        register={methods.register('width', {
                            onBlur: (ev) => {
                                if (cssRegex.test(ev.target.value.trim())) {
                                    onChange({ width: ev.target.value.trim() })
                                } else {
                                    ev.target.value = methods.getValues("_tmp")
                                }
                                methods.setValue('_tmp', undefined)
                            }
                        })}
                        placeholder="width"
                        suffix={<button type="button" className="row icon-button16" onClick={widthOptions}> <Winicon src={"fill/arrows/down-arrow"} /></button>}
                    />}
            </div>
            <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                {methods.watch("rowValue") ? <Select1
                    readOnly
                    className="regular1"
                    prefix={<Text className="regular1">H</Text>}
                    popupClassName="select-col"
                    value={methods.watch('rowValue')}
                    options={['fixed', listBreakpoint[0]].map(e => {
                        return {
                            id: e,
                            name: e
                        }
                    })}
                    onChange={(ev) => {
                        if (ev.id !== "remain") {
                            onChange({ height: selectedElement.offsetHeight + "px" }, selectedLayer.Setting.className.split(" ").filter(e => e !== "remain").join(" "))
                        }
                    }}
                /> : <TextField
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="regular1">H</Text>}
                    className="regular1"
                    name="height"
                    suffix={<button type="button" className="row icon-button16" onClick={heightOptions}> <Winicon src={"fill/arrows/down-arrow"} /></button>}
                    onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                    register={methods.register('height', {
                        onBlur: (ev) => {
                            if (cssRegex.test(ev.target.value.trim())) {
                                onChange({ height: ev.target.value.trim() })
                            } else {
                                ev.target.value = methods.getValues("_tmp")
                            }
                            methods.setValue('_tmp', undefined)
                        }
                    })}
                    placeholder="height"
                />}
            </div>
        </div>
        {/* setBreakPoint layout */}
        {breakPoint && <div className="col" style={{ gap: '0.8rem', padding: '0.8rem 0.8rem', borderRadius: '0.8rem', border: '1px solid #444444' }}>
            <Text className="semibold1" style={{}}>Breakpoint Layout</Text>
            <div className='row' style={{ gap: '0.4rem', flexWrap: 'wrap' }}>
                {tilteBreakpoints.map((brp, index) => {
                    const _brpCols = ['none', ...listBreakpoint].map(e => e == "none" ? e : `${e}-${brp.toLowerCase()}`)
                    return <div key={brp} className="col8 col" style={{ gap: '0.5rem', flex: 1, '--gutter': '0.4rem' }}>
                        <Text className="semibold1" style={{ color: '#999999' }}>{brp}</Text>
                        <Select1
                            readOnly
                            style={{ flex: 1 }}
                            className="regular1"
                            popupClassName={`${brp}-brp`}
                            placeholder={'none'}
                            value={methods.watch(`${brp}-brp`)}
                            options={_brpCols.map(e => {
                                return {
                                    id: e,
                                    name: e?.split('-')[0]?.split('col').join('')
                                }
                            })}
                            onChange={(ev) => {
                                const { columnGap, gap } = layers.find(e => e.Id === selectedLayer.ParentId)?.Setting?.style
                                if (ev === 'none') {
                                    onChange({}, selectedLayer.Setting.className.split(" ").map(cls => cls !== _brpCols[index] ? cls : "").join(" "))
                                } else {
                                    onChange({ '--gutter': (columnGap ? columnGap : gap) }, (selectedLayer.Setting.className ?? "").split(" ").filter(cls => _brpCols.every(e => cls !== e)).join(" ") + ` ${ev.id}`)
                                }
                            }}
                        />
                    </div>
                })}
            </div>
        </div>}
    </div>

}

export function OnChangePosition({ methods, onChange, selectedLayer, selectedElement }) {
    const [position, setPosition] = useState('static')
    const tiltePositionss = ['static', 'relative', 'fixed', 'absolute', 'sticky'];

    useEffect(() => {
        if (selectedElement) {
            const _newStyle = window.getComputedStyle(selectedElement)
            methods.setValue('z-index', selectedLayer.Setting.style.zIndex ?? _newStyle.zIndex)
            if (selectedLayer.Setting.style?.position) {
                if (selectedLayer.Setting.style?.position !== "static") {
                    if (['top', 'right', 'bottom', 'left'].some((v) => selectedLayer.Setting.style[v] != undefined)) {
                        var _top = selectedLayer.Setting.style?.top ?? _newStyle['top'] ?? '0px'
                        var _right = selectedLayer.Setting.style?.right ?? _newStyle['right'] ?? '0px'
                        var _bottom = selectedLayer.Setting.style?.bottom ?? _newStyle['bottom'] ?? '0px'
                        var _left = selectedLayer.Setting.style?.left ?? _newStyle['left'] ?? '0px'
                        methods.setValue('position-top', _top)
                        methods.setValue('position-right', _right)
                        methods.setValue('position-bottom', _bottom)
                        methods.setValue('position-left', _left)
                    }
                    methods.setValue('position-style', selectedLayer.Setting.style.position)
                    setPosition(selectedLayer.Setting.style.position)
                } else {
                    methods.setValue('position-style', "static")
                    methods.setValue('position-top', undefined)
                    methods.setValue('position-right', undefined)
                    methods.setValue('position-bottom', undefined)
                    methods.setValue('position-left', undefined)
                }
            } else {
                methods.setValue('position-style', "static")
                methods.setValue('position-top', undefined)
                methods.setValue('position-right', undefined)
                methods.setValue('position-bottom', undefined)
                methods.setValue('position-left', undefined)
            }
        }
    }, [selectedLayer])

    return <div className="col" style={{ gap: '0.8rem', flex: 1 }}>
        <Text className="semibold1" style={{ color: '#999999' }}>Position style</Text>
        <Select1
            readOnly
            className="regular1"
            popupClassName="position-style"
            value={methods.watch('position-style')}
            options={tiltePositionss.map(e => {
                return {
                    id: e,
                    name: e
                }
            })}
            onChange={(ev) => {
                const _tmp = methods.getValues('position-style')
                if (_tmp !== ev.id) {
                    if (ev.id === 'static') {
                        methods.setValue('position-top', undefined)
                        methods.setValue('position-right', undefined)
                        methods.setValue('position-bottom', undefined)
                        methods.setValue('position-left', undefined)
                    }
                    onChange({ position: ev.id })
                }
            }}
        />
        {
            position != 'static' ?
                <div className="col" style={{ gap: '0.4rem', padding: '0.8rem 0.8rem', borderRadius: '0.8rem', border: '1px solid #444444' }}>
                    <Text className="semibold1" style={{ color: '#999999' }}>Position & zIndex</Text>
                    <TextField
                        onComplete={(ev) => ev.target.blur()}
                        prefix={<Text className="regular1">ZIndex</Text>}
                        className="regular1"
                        name="z-Index"
                        style={{ width: '41%' }}
                        register={methods.register('z-index', {
                            onBlur: (ev) => {
                                onChange({
                                    zIndex: ev.target.value.trim()
                                })
                            }
                        })}
                        onChange={(ev) => {
                            onChange({
                                zIndex: ev.target.value.trim()
                            })
                        }}
                        placeholder={`${methods.getValues('z-index') ?? 'auto'}`}
                        type="number"
                    />
                    <div className="row" style={{ gap: '0.8rem' }} >
                        <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%', gap: "0.4rem" }}>
                            <TextField
                                onComplete={(ev) => ev.target.blur()}
                                prefix={<Text className="regular1">T</Text>}
                                className="regular1"
                                name="position-top"
                                onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                                register={methods.register('position-top', {
                                    onBlur: (ev) => {
                                        if (cssRegex.test(ev.target.value.trim())) {
                                            onChange({
                                                position: methods.getValues('position-style'),
                                                top: ev.target.value.trim(),
                                            })
                                        } else {
                                            ev.target.value = methods.getValues("_tmp")
                                        }
                                        methods.setValue('_tmp', undefined)
                                    }
                                })}
                                placeholder="top"
                            />
                            <TextField
                                onComplete={(ev) => ev.target.blur()}
                                prefix={<Text className="regular1">R</Text>}
                                className="regular1"
                                name="position-right"
                                onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                                register={methods.register('position-right', {
                                    onBlur: (ev) => {
                                        if (cssRegex.test(ev.target.value.trim())) {
                                            onChange({
                                                position: methods.getValues('position-style'),
                                                right: ev.target.value.trim()
                                            })
                                        } else {
                                            ev.target.value = methods.getValues("_tmp")
                                        }
                                        methods.setValue('_tmp', undefined)
                                    }
                                })}
                                placeholder="right"
                            />
                        </div>
                        <OutlineRadius4Angle size={'3.2rem'} color={'#fff'} />
                        <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                            <TextField
                                onComplete={(ev) => ev.target.blur()}
                                prefix={<Text className="regular1">B</Text>}
                                className="regular1"
                                name="position-bottom"
                                onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                                register={methods.register('position-bottom', {
                                    onBlur: (ev) => {
                                        if (cssRegex.test(ev.target.value.trim())) {
                                            onChange({
                                                position: methods.getValues('position-style'),
                                                bottom: ev.target.value.trim()
                                            })
                                        } else {
                                            ev.target.value = methods.getValues("_tmp")
                                        }
                                        methods.setValue('_tmp', undefined)
                                    }
                                })}
                                placeholder="bottom"
                            />

                            <TextField
                                onComplete={(ev) => ev.target.blur()}
                                prefix={<Text className="regular1">L</Text>}
                                className="regular1"
                                name="position-left"
                                onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                                register={methods.register('position-left', {
                                    onBlur: (ev) => {
                                        if (cssRegex.test(ev.target.value.trim())) {
                                            onChange({
                                                position: methods.getValues('position-style'),
                                                left: ev.target.value.trim()
                                            })
                                        } else {
                                            ev.target.value = methods.getValues("_tmp")
                                        }
                                        methods.setValue('_tmp', undefined)
                                    }
                                })}
                                placeholder="left"
                            />
                        </div>
                    </div>

                </div>
                : <></>
        }
    </div>
}

export function OnChangeBorderStyle({ methods, onChange, selectedLayer, selectedElement, hasDesignToken, setDesignToken, designToken }) {
    const [radiusAll, setRadiusAll] = useState(true)
    const [borderWAll, setborderWAll] = useState(true)

    useEffect(() => {
        if (selectedElement) {
            const _newStyle = window.getComputedStyle(selectedElement)
            if (['borderTopLeftRadius', 'borderTopRightRadius', 'borderBottomLeftRadius', 'borderBottomRightRadius'].some((v) => selectedLayer.Setting.style[v] != undefined)) {
                var _vTL = selectedLayer.Setting.style?.borderTopLeftRadius ?? _newStyle['border-top-left-radius'] ?? '0px'
                var _vTR = selectedLayer.Setting.style?.borderTopLeftRadius ?? _newStyle['border-top-right-radius'] ?? '0px'
                var _vBL = selectedLayer.Setting.style?.borderBottomLeftRadius ?? _newStyle['border-bottom-left-radius'] ?? '0px'
                var _vBR = selectedLayer.Setting.style?.borderBottomRightRadius ?? _newStyle['border-bottom-right-radius'] ?? '0px'

                methods.setValue('border-top-left-radius', _vTL)
                methods.setValue('border-top-right-radius', _vTR)
                methods.setValue('border-bottom-left-radius', _vBL)
                methods.setValue('border-bottom-right-radius', _vBR)
                methods.setValue('border-radius', _vTL)
                if (radiusAll) setRadiusAll(false)
            } else {
                var _v = selectedLayer.Setting.style?.borderRadius ?? _newStyle['border-radius'] ?? '0px'
                methods.setValue('border-radius', _v)
                methods.setValue('border-top-left-radius', _v)
                methods.setValue('border-top-right-radius', _v)
                methods.setValue('border-bottom-left-radius', _v)
                methods.setValue('border-bottom-right-radius', _v)
                if (!radiusAll) setRadiusAll(true)
            }

            const _borderStyle = _newStyle.borderStyle
            methods.setValue('border-style', _borderStyle)

            if (selectedLayer.Setting.style?.borderColor?.startsWith("var")) {
                const _borderColor = Ultis.rgbToHex(_newStyle.borderColor)
                methods.setValue('border-color', _borderColor.substring(0, 7))
                methods.setValue('border-color-opacity', `${Ultis.hexToPercent(_borderColor.substring(7))}`)
                methods.setValue('border-color-id', selectedLayer.Setting.style?.borderColor.replace("var(--", "").replace(")", ""))
                setDesignToken(true)
            } else {
                const _borderColor = Ultis.rgbToHex(_newStyle.borderColor)
                methods.setValue('border-color', _borderColor.substring(0, 7))
                methods.setValue('border-color-opacity', `${Ultis.hexToPercent(_borderColor.substring(7))}`)
                setDesignToken(false)
            }


            if (['borderTopWidth', 'borderRightWidth', 'borderBottomWidth', 'borderLeftWidth'].some((v) => selectedLayer.Setting.style[v] != undefined)) {
                var _vT = selectedLayer.Setting.style?.borderTopWidth ?? _newStyle['border-top-width'] ?? '0px'
                var _vR = selectedLayer.Setting.style?.borderRightWidth ?? _newStyle['border-right-width'] ?? '0px'
                var _vB = selectedLayer.Setting.style?.borderBottomWidth ?? _newStyle['border-bottom-width'] ?? '0px'
                var _vL = selectedLayer.Setting.style?.borderLeftWidth ?? _newStyle['border-left-width'] ?? '0px'

                methods.setValue('border-top-width', _vT)
                methods.setValue('border-right-width', _vR)
                methods.setValue('border-bottom-width', _vB)
                methods.setValue('border-left-width', _vL)
                methods.setValue('border-radius', _vT)
                if (borderWAll) setborderWAll(false)
            } else {
                var _v = selectedLayer.Setting.style.borderWidth ?? _newStyle['border-width'] ?? '0px'
                methods.setValue('border-width', _v)
                methods.setValue('border-top-width', _v)
                methods.setValue('border-right-width', _v)
                methods.setValue('border-bottom-width', _v)
                methods.setValue('border-left-width', _v)
                if (!borderWAll) setborderWAll(true)
            }

        }
    }, [selectedLayer])

    return <div className="col">
        <div className="row" style={{ gap: '0.8rem', paddingBottom: '0.8rem' }}>
            <Text className="semibold1" style={{ color: '#999999' }}>Border radius</Text>
            <div className="row" style={{ borderRadius: '0.6rem', border: '1px solid #444444' }}>
                <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: radiusAll ? undefined : "#9c6334" }} onClick={() => setRadiusAll(false)}>
                    <OutlineRadius4Angle size={'1.1rem'} color={'#fff'} />
                </button>
                <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: radiusAll ? "#9c6334" : undefined }} onClick={() => setRadiusAll(true)}>
                    <OutlineRadiusAll size={'1.1rem'} color={'#fff'} />
                </button>
            </div>
        </div>
        {radiusAll ?
            <TextField
                onComplete={(ev) => ev.target.blur()}
                className="regular1"
                name="border-radius"
                onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                register={methods.register('border-radius', {
                    onBlur: (ev) => {
                        if (cssRegex.test(ev.target.value.trim())) {
                            onChange({
                                borderRadius: ev.target.value.trim(),
                                borderTopLeftRadius: undefined,
                                borderBottomLeftRadius: undefined,
                                borderTopRightRadius: undefined,
                                borderBottomRightRadius: undefined,
                            })
                        } else {
                            ev.target.value = methods.getValues("_tmp")
                        }
                        methods.setValue('_tmp', undefined)
                    }
                })}
                placeholder="Border radius"
            /> :
            <div className="row" style={{ gap: '0.8rem', padding: '0.8rem 0.8rem', borderRadius: '0.8rem', border: '1px solid #444444' }}>
                <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%', gap: "0.4rem" }}>
                    <TextField
                        onComplete={(ev) => ev.target.blur()}
                        prefix={<Text className="regular1">TL</Text>}
                        className="regular1"
                        name="border-top-left-radius"
                        onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                        register={methods.register('border-top-left-radius', {
                            onBlur: (ev) => {
                                if (cssRegex.test(ev.target.value.trim())) {
                                    onChange({
                                        borderRadius: undefined,
                                        borderTopLeftRadius: ev.target.value.trim()
                                    })
                                } else {
                                    ev.target.value = methods.getValues("_tmp")
                                }
                                methods.setValue('_tmp', undefined)
                            }
                        })}
                        placeholder="Top Left"
                    />
                    <TextField
                        onComplete={(ev) => ev.target.blur()}
                        prefix={<Text className="regular1">TR</Text>}
                        className="regular1"
                        name="border-top-right-radius"
                        onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                        register={methods.register('border-top-right-radius', {
                            onBlur: (ev) => {
                                if (cssRegex.test(ev.target.value.trim())) {
                                    onChange({
                                        borderRadius: undefined,
                                        borderTopRightRadius: ev.target.value.trim()
                                    })
                                } else {
                                    ev.target.value = methods.getValues("_tmp")
                                }
                                methods.setValue('_tmp', undefined)
                            }
                        })}
                        placeholder="Top Right"
                    />

                </div>
                <OutlineRadius4Angle size={'3.2rem'} color={'#fff'} />
                <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                    <TextField
                        onComplete={(ev) => ev.target.blur()}
                        prefix={<Text className="regular1">BR</Text>}
                        className="regular1"
                        name="border-bottom-right-radius"
                        onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                        register={methods.register('border-bottom-right-radius', {
                            onBlur: (ev) => {
                                if (cssRegex.test(ev.target.value.trim())) {
                                    onChange({
                                        borderRadius: undefined,
                                        borderBottomRightRadius: ev.target.value.trim()
                                    })
                                } else {
                                    ev.target.value = methods.getValues("_tmp")
                                }
                                methods.setValue('_tmp', undefined)
                            }
                        })}
                        placeholder="Bottom Right"
                    />
                    <TextField
                        onComplete={(ev) => ev.target.blur()}
                        prefix={<Text className="regular1">BL</Text>}
                        className="regular1"
                        name="border-bottom-left-radius"
                        onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                        register={methods.register('border-bottom-left-radius', {
                            onBlur: (ev) => {
                                if (cssRegex.test(ev.target.value.trim())) {
                                    onChange({
                                        borderRadius: undefined,
                                        borderBottomLeftRadius: ev.target.value.trim()
                                    })
                                } else {
                                    ev.target.value = methods.getValues("_tmp")
                                }
                                methods.setValue('_tmp', undefined)
                            }
                        })}
                        placeholder="Bottom Left"
                    />
                </div>
            </div>
        }

        <div className="row" style={{ gap: '0.8rem', paddingTop: '0.8rem' }}>
            <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                <Text className="semibold1" style={{ color: '#999999', paddingBottom: '1.05rem' }}>Border style</Text>
                <Select1
                    readOnly
                    className="regular1"
                    popupClassName="border-style"
                    value={methods.watch('border-style')}
                    options={['none', 'solid', 'dashed', 'dotted'].map(e => {
                        return {
                            id: e,
                            name: e
                        }
                    })}
                    onChange={(ev) => {
                        const _tmp = methods.getValues('border-style')
                        if (_tmp !== ev.id) {
                            onChange({ borderStyle: ev.id })
                        }
                    }}
                />
            </div>
            <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                <div className="row" style={{ gap: '0.8rem', paddingBottom: '0.8rem' }}>
                    <Text className="semibold1" style={{ color: '#999999' }}>Border width</Text>
                    <div className="row" style={{ borderRadius: '0.6rem', border: '1px solid #444444' }}>
                        <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: borderWAll ? undefined : "#9c6334" }} onClick={() => setborderWAll(false)}>
                            <OutlineRadius4Angle size={'1.1rem'} color={'#fff'} />
                        </button>
                        <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: borderWAll ? "#9c6334" : undefined }} onClick={() => setborderWAll(true)}>
                            <OutlineRadiusAll size={'1.1rem'} color={'#fff'} />
                        </button>
                    </div>
                </div>
                <TextField
                    disabled={!borderWAll}
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="regular1">BW</Text>}
                    className="regular1"
                    name="border-width"
                    onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                    register={methods.register('border-width', {
                        onBlur: (ev) => {
                            if (borderWidthRegex.test(ev.target.value.trim())) {
                                onChange({ borderWidth: ev.target.value.trim() })
                            } else {
                                ev.target.value = methods.getValues("_tmp")
                            }
                            methods.setValue('_tmp', undefined)
                        }
                    })}
                    placeholder="Border Width"
                />
            </div>
        </div>
        {!borderWAll && <div className="row" style={{ gap: '0.8rem', padding: '0.8rem', marginTop: '0.8rem', borderRadius: '0.8rem', border: '1px solid #444444' }}>
            <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%', gap: "0.4rem" }}>
                <TextField
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="regular1">T</Text>}
                    className="regular1"
                    name="border-top-width"
                    onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                    register={methods.register('border-top-width', {
                        onBlur: (ev) => {
                            if (borderWidthRegex.test(ev.target.value.trim())) {
                                onChange({
                                    borderWidth: undefined,
                                    borderTopWidth: ev.target.value.trim()
                                })
                            } else {
                                ev.target.value = methods.getValues("_tmp")
                            }
                            methods.setValue('_tmp', undefined)
                        }
                    })}
                    placeholder="Top"
                />
                <TextField
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="regular1">R</Text>}
                    className="regular1"
                    name="border-right-width"
                    onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                    register={methods.register('border-right-width', {
                        onBlur: (ev) => {
                            if (borderWidthRegex.test(ev.target.value.trim())) {
                                onChange({
                                    borderWidth: undefined,
                                    borderRightWidth: ev.target.value.trim()
                                })
                            } else {
                                ev.target.value = methods.getValues("_tmp")
                            }
                            methods.setValue('_tmp', undefined)
                        }
                    })}
                    placeholder="Right"
                />
            </div>
            <OutlineRadius4Angle size={'3.2rem'} color={'#fff'} />
            <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                <TextField
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="regular1">B</Text>}
                    className="regular1"
                    name="border-bottom-width"
                    onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                    register={methods.register('border-bottom-width', {
                        onBlur: (ev) => {
                            if (borderWidthRegex.test(ev.target.value.trim())) {
                                onChange({
                                    borderWidth: undefined,
                                    borderBottomWidth: ev.target.value.trim()

                                })
                            } else {
                                ev.target.value = methods.getValues("_tmp")
                            }
                            methods.setValue('_tmp', undefined)
                        }
                    })}
                    placeholder="Bottom"
                />
                <TextField
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="regular1">L</Text>}
                    className="regular1"
                    name="border-left-width"
                    onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                    register={methods.register('border-left-width', {
                        onBlur: (ev) => {
                            if (borderWidthRegex.test(ev.target.value.trim())) {
                                onChange({
                                    borderWidth: undefined,
                                    borderLeftWidth: ev.target.value.trim()
                                })
                            } else {
                                ev.target.value = methods.getValues("_tmp")
                            }
                            methods.setValue('_tmp', undefined)
                        }
                    })}
                    placeholder="Left"
                />
            </div>
        </div>}
        <div className="col" style={{ gap: '0.4rem', flex: 1, paddingTop: '0.4rem' }}>
            <Text className="semibold1" style={{ color: '#999999' }}>Border color</Text>
            {hasDesignToken && designToken?.length > 0 ?
                <Select1
                    className="regular1"
                    placeholder={`Design Border Color`}
                    value={methods.watch('border-color-id')}
                    options={designToken.map((el) => {
                        return {
                            id: el.Id,
                            name: <div className='row' style={{ gap: '0.4rem', flex: 1, alignItems: 'center' }}>
                                <div style={{
                                    width: "1.4rem",
                                    height: "1.4rem",
                                    borderRadius: '0.2rem',
                                    border: "1px solid #444444", backgroundColor: `${el.Value?.lightMode ?? el.Value}`
                                }}></div> | <div style={{
                                    width: "1.4rem",
                                    height: "1.4rem",
                                    borderRadius: '0.2rem',
                                    border: "1px solid #444444", backgroundColor: `${el.Value?.darkMode ?? el.Value}`
                                }}></div>
                                <Text className="regular1" style={{ color: '#999999' }}>{el.Name}</Text>
                            </div>,
                            disabled: el.Type === DesignTokenType.group
                        }
                    })}
                    style={{}}
                    onChange={(v) => {
                        const _tmp = methods.getValues('border-color-id')
                        if (_tmp !== v.id) {
                            onChange({ borderColor: `var(--${v.id})` })
                        }
                    }}
                /> :
                methods.watch("border-color") ? <div style={{}}>
                    <ColorPicker
                        colorName={'border-color'}
                        colorOpcaityName={'border-color-opacity'}
                        style={{ width: '100%', height: 28, }}
                        register={methods.register}
                        colorValue={methods.watch("border-color") + Ultis.percentToHex(parseFloat(methods.watch('border-color-opacity')))}
                        onChange={({ colorValue, opacity }) => onChange({ borderColor: colorValue })}
                    />
                </div> : null}

        </div>

    </div>
}

export function OnChangePaddingStyle({ methods, onChange, selectedLayer, selectedElement, }) {
    const [paddingAll, setPaddingAll] = useState(false)

    useEffect(() => {
        if (selectedElement) {
            const _newStyle = window.getComputedStyle(selectedElement)

            if (['paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft'].some((v) => selectedLayer.Setting.style[v] != undefined)) {
                var _top = selectedLayer.Setting.style?.paddingTop ?? _newStyle['padding-top'] ?? '0px'
                var _right = selectedLayer.Setting.style?.paddingRight ?? _newStyle['padding-right'] ?? '0px'
                var _bottom = selectedLayer.Setting.style?.paddingBottom ?? _newStyle['padding-bottom'] ?? '0px'
                var _left = selectedLayer.Setting.style?.paddingLeft ?? _newStyle['padding-left'] ?? '0px'
                methods.setValue('padding-top', _top)
                methods.setValue('padding-right', _right)
                methods.setValue('padding-bottom', _bottom)
                methods.setValue('padding-left', _left)
                methods.setValue('padding', _top)
                if (paddingAll) setPaddingAll(false)
            } else {
                var _v = selectedLayer.Setting.style?.padding ?? _newStyle['padding'] ?? '0px'

                methods.setValue('padding', _v)
                methods.setValue('padding-top', undefined)
                methods.setValue('padding-right', undefined)
                methods.setValue('padding-bottom', undefined)
                methods.setValue('padding-left', undefined)
                if (!paddingAll) setPaddingAll(true)
            }
        }
    }, [selectedLayer])

    return <div className="col">
        <div className="row" style={{ gap: '0.8rem', paddingBottom: '0.8rem' }}>
            <Text className="semibold1" style={{ color: '#999999' }}>Padding style</Text>
            <div className="row" style={{ borderRadius: '0.6rem', border: '1px solid #444444' }}>
                <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: paddingAll ? undefined : "#9c6334" }} onClick={() => setPaddingAll(false)}>
                    <OutlineRadius4Angle size={'1.1rem'} color={'#fff'} />
                </button>
                <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: paddingAll ? "#9c6334" : undefined }} onClick={() => setPaddingAll(true)}>
                    <OutlineRadiusAll size={'1.1rem'} color={'#fff'} />
                </button>
            </div>
        </div>
        {paddingAll ? <TextField
            onComplete={(ev) => ev.target.blur()}
            className="regular1"
            name="padding"
            onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
            register={methods.register('padding', {
                onBlur: (ev) => {
                    if (cssRegex.test(ev.target.value.trim())) {
                        onChange({
                            padding: ev.target.value.trim(),
                            paddingTop: undefined,
                            paddingRight: undefined,
                            paddingBottom: undefined,
                            paddingLeft: undefined
                        })
                    } else {
                        ev.target.value = methods.getValues("_tmp")
                    }
                    methods.setValue('_tmp', undefined)

                }
            })}
            placeholder="Padding container"
        /> : <div className="row" style={{ gap: '0.8rem', }}>
            <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                <TextField
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="regular1">T</Text>}
                    className="regular1"
                    name="padding-top"
                    onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                    register={methods.register('padding-top', {
                        onBlur: (ev) => {
                            if (cssRegex.test(ev.target.value.trim())) {
                                onChange({
                                    padding: undefined,
                                    paddingTop: ev.target.value.trim(),
                                })
                            } else {
                                ev.target.value = methods.getValues("_tmp")
                            }
                            methods.setValue('_tmp', undefined)

                        }
                    })}
                    placeholder="top"

                />

                <TextField
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="regular1">R</Text>}
                    className="regular1"
                    name="padding-right"
                    onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                    register={methods.register('padding-right', {
                        onBlur: (ev) => {
                            if (cssRegex.test(ev.target.value.trim())) {
                                onChange({
                                    padding: undefined,
                                    paddingRight: ev.target.value.trim(),
                                })
                            } else {
                                ev.target.value = methods.getValues("_tmp")
                            }
                            methods.setValue('_tmp', undefined)

                        }
                    })}
                    placeholder="right"

                />
            </div>
            <OutlineRadius4Angle size={'3.2rem'} color={'#fff'} />
            <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>

                <TextField
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="regular1">B</Text>}
                    className="regular1"
                    name="padding-bottom"
                    onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                    register={methods.register('padding-bottom', {
                        onBlur: (ev) => {
                            if (cssRegex.test(ev.target.value.trim())) {
                                onChange({
                                    padding: undefined,
                                    paddingBottom: ev.target.value.trim(),
                                })
                            } else {
                                ev.target.value = methods.getValues("_tmp")
                            }
                            methods.setValue('_tmp', undefined)

                        }
                    })}
                    placeholder="bottom"

                />
                <TextField
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Text className="regular1">L</Text>}
                    className="regular1"
                    name="padding-left"
                    onFocus={(ev) => { methods.setValue('_tmp', ev.target.value) }}
                    register={methods.register('padding-left', {
                        onBlur: (ev) => {
                            if (cssRegex.test(ev.target.value.trim())) {
                                onChange({
                                    padding: undefined,
                                    paddingLeft: ev.target.value.trim(),
                                })
                            } else {
                                ev.target.value = methods.getValues("_tmp")
                            }
                            methods.setValue('_tmp', undefined)

                        }
                    })}
                    placeholder="left"
                />
            </div>
        </div>}
    </div>
}