import { useSelector } from "react-redux"
import { ComponentType } from "../../wini/table/da"
import SettingText from "./componentProperties/text"
import SettingImg from "./componentProperties/img"
import SettingContainer from "./componentProperties/container"
import SettingButton from "./componentProperties/button"
import SettingTextField from "./componentProperties/textfield"
import SettingSwitch from "./componentProperties/switch"
import SettingCheckBox from "./componentProperties/checkbox"
import SettingRadio from "./componentProperties/radio"
import SettingTextArea from "./componentProperties/textarea"
import SettingChart from "./componentProperties/chart"
import SettingForm from "./componentProperties/form"
import SettingCard from "./componentProperties/card"
import { useEffect, useState } from "react"

export default function PropertiesTab({ methods }) {
    const { layers, layout } = useSelector((store) => store.page.data)
    const [selected, setSelected] = useState()

    useEffect(() => {
        if (layers?.length && layout?.length) {
            const _id = methods.getValues("selectedId")
            if (_id) setSelected([...layers, ...layout].find(e => e.Id === _id))
            else setSelected(undefined)
        }
    }, [layers?.length, layout?.length, methods.watch("selectedId")])

    const renderSettingView = () => {
        switch (selected?.Type) {
            case ComponentType.text:
                return <SettingText methods={methods} selected={selected} />
            case ComponentType.img:
                return <SettingImg methods={methods} selected={selected} />
            case ComponentType.container:
                return <SettingContainer methods={methods} selected={selected} />
            case ComponentType.checkbox:
                return <SettingCheckBox methods={methods} selectedLayer={selected} />
            case ComponentType.switch:
                return <SettingSwitch methods={methods} selectedLayer={selected} />
            case ComponentType.radio:
                return <SettingRadio methods={methods} selectedLayer={selected} />
            case ComponentType.textField:
                return <SettingTextField methods={methods} selectedLayer={selected} />
            case ComponentType.textArea:
                return <SettingTextArea methods={methods} selectedLayer={selected} />
            case ComponentType.form:
                return <SettingForm methods={methods} selectedLayer={selected} />
            case ComponentType.card:
                return <SettingCard methods={methods} selectedLayer={selected} />
            case ComponentType.button:
                return <SettingButton methods={methods} selectedLayer={selected} />
            case ComponentType.chart:
                return <SettingChart methods={methods} selectedLayer={selected} />
            default:
                return <div />
        }
    }

    return <div className="col" style={{ backgroundColor: '#14181b', flex: 1, overflow: 'hidden auto', scrollbarWidth: "none" }}>{renderSettingView()}</div>
}


