import React, { CSSProperties, useEffect, useState } from "react";
import { FormItem, FormType } from "../da";
import { FormCard } from "../../../project-component/cards";
import { useForm } from "react-hook-form";
import { TableController } from "../../wini/table/controller";
import { DataController } from "../controller";
import { ComponentType, FEDataType } from "../../wini/table/da";
import { Ultis } from "../../../Utils";


export default function RenderFormByType(props: { id?: string, formItem: FormItem, className?: string, style?: CSSProperties, isActive?: boolean, data?: { [p: string]: any }, columns?: Array<any> }) {
    const methodOptions = useForm({ shouldFocusError: false })
    const methods = useForm({ shouldFocusError: false })
    const [cols, setCols] = useState<Array<any>>([])
    const [rels, setRels] = useState<Array<any>>([])
    const _colController = new TableController("column")
    const _relController = new TableController("rel")

    const _onSubmit = (ev: any) => { }

    const _onError = (ev: any) => { }

    useEffect(() => {
        if (props.formItem) {
            if (props.columns) {
                const _cols = props.columns.filter((e: any) => e.type === "col" && (props.formItem.Props as any)[e.Name] >= 0).map((e: any) => {
                    let _tmp = { ...e, Form: e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : { ...e.Form } : { Required: true } }
                    _tmp.Form.Sort = (props.formItem.Props as any)[e.Name]
                    return _tmp
                })
                const _rels = props.columns.filter((e: any) => e.type === "rel" && (props.formItem.Props as any)[e.Column] >= 0).map((e: any) => {
                    let _tmp = { ...e, Form: e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : { ...e.Form } : { Required: true } }
                    _tmp.Form.Sort = (props.formItem.Props as any)[e.Column]
                    return _tmp
                })
                setCols(_cols)
                setRels(_rels)
            } else {
                _relController.getListSimple({ page: 1, size: 100, query: `@TableFK:{${props.formItem.TbName}} @Column:{${Object.keys(props.formItem.Props).filter(p => (props.formItem.Props as any)[p] >= 0).join(" | ")}}` }).then(res => {
                    if (res.code === 200) setRels(res.data.map((e: any) => {
                        let _tmp = { ...e, Form: e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : { ...e.Form } : { Required: true } }
                        _tmp.Form.Sort = (props.formItem.Props as any)[e.Name]
                        return _tmp
                    }))
                })
                _colController.getListSimple({ page: 1, size: 100, query: `@TableName:{${props.formItem.TbName}} @Name:{${Object.keys(props.formItem.Props).filter(p => (props.formItem.Props as any)[p] >= 0).join(" | ")}}` }).then(res => {
                    if (res.code === 200) {
                        setCols(res.data.map((e: any) => {
                            let _tmp = { ...e, Form: e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : { ...e.Form } : { Required: true } }
                            _tmp.Form.Sort = (props.formItem.Props as any)[e.Column]
                            return _tmp
                        }))
                    }
                })
            }
        }
    }, [props.formItem, props.columns])

    useEffect(() => {
        if (rels.length && props.isActive)
            rels.forEach((_rel) => {
                const dataController = new DataController(_rel.TablePK)
                dataController.getListSimple({ page: 1, size: 50, query: _rel.Query ?? "*" }).then((res) => {
                    methodOptions.setValue(`${_rel.TablePK}_Options`, res?.data ?? [])
                })
            })
    }, [rels.length, props.isActive])

    useEffect(() => {
        if (props.data && props.isActive) {
            const dataItem = props.data
            Object.keys(props.data).forEach(prop => {
                const _col = cols.find(e => e.Name === prop)
                const _rel = rels.find(e => e.Column === prop)
                if (_col) {
                    switch (_col.DataType) {
                        case FEDataType.GID:
                            methods.setValue(prop, dataItem[prop])
                            break;
                        case FEDataType.STRING:
                            methods.setValue(prop, dataItem[prop])
                            break;
                        case FEDataType.BOOLEAN:
                            methods.setValue(prop, dataItem[prop])
                            break;
                        case FEDataType.NUMBER:
                            methods.setValue(prop, typeof dataItem[prop] === 'string' ? parseFloat(dataItem[prop]) : dataItem[prop])
                            break;
                        case FEDataType.DATE:
                            methods.setValue(prop, Ultis.datetoString(new Date(typeof dataItem[prop] === 'string' ? parseInt(dataItem[prop]) : dataItem[prop])))
                            break;
                        case FEDataType.DATETIME:
                            methods.setValue(prop, Ultis.datetoString(new Date(typeof dataItem[prop] === 'string' ? parseInt(dataItem[prop]) : dataItem[prop]), 'dd/mm/yyyy hh:mm:ss'))
                            break;
                        case FEDataType.MONEY:
                            methods.setValue(prop, Ultis.money(dataItem[prop]))
                            break;
                        case FEDataType.PASSWORD:
                            methods.setValue(prop, dataItem[prop])
                            break;
                        default:
                            break;
                    }
                } else if (_rel) {
                    const _tmpParse = dataItem[prop]?.length ? dataItem[prop].split(",") : []
                    methods.setValue(prop, _rel.ComponentType === ComponentType.selectMultiple ? _tmpParse : _tmpParse[0])
                } else {
                    methods.setValue(prop, dataItem[prop])
                }
            })
        } else {
            cols.filter((e) => e.DefaultValue != undefined).forEach((_col) => {
                switch (_col.DataType) {
                    case FEDataType.GID:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    case FEDataType.STRING:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    case FEDataType.BOOLEAN:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    case FEDataType.NUMBER:
                        methods.setValue(_col.Name, typeof _col.DefaultValue === 'string' ? parseFloat(_col.DefaultValue) : _col.DefaultValue)
                        break;
                    case FEDataType.DATE:
                        methods.setValue(_col.Name, Ultis.datetoString(new Date(typeof _col.DefaultValue === 'string' ? parseInt(_col.DefaultValue) : _col.DefaultValue)))
                        break;
                    case FEDataType.DATETIME:
                        methods.setValue(_col.Name, Ultis.datetoString(new Date(typeof _col.DefaultValue === 'string' ? parseInt(_col.DefaultValue) : _col.DefaultValue), 'dd/mm/yyyy hh:mm:ss'))
                        break;
                    case FEDataType.MONEY:
                        methods.setValue(_col.Name, Ultis.money(_col.DefaultValue))
                        break;
                    case FEDataType.PASSWORD:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    default:
                        break;
                }
            })
        }
    }, [props.data])

    switch (props.formItem.Type) {
        case FormType.formFullLeftLabelCol:
            return <FormCard
                id={props.id ?? props.formItem.Id}
                labelPosition="left"
                methods={methods}
                options={methodOptions.watch()}
                className={props.className}
                style={props.style}
                cols={cols}
                rels={rels}
                name={props.formItem.Name}
                description={props.formItem.Description}
                onSubmit={methods.handleSubmit(_onSubmit, _onError)}
            />
        case FormType.formWithoutHeaderLeftLabelCol:
            return <FormCard
                id={props.id ?? props.formItem.Id}
                hideHeader
                labelPosition="left"
                methods={methods}
                options={methodOptions.watch()}
                className={props.className}
                style={props.style}
                cols={cols}
                rels={rels}
                name={props.formItem.Name}
                description={props.formItem.Description}
                onSubmit={methods.handleSubmit(_onSubmit, _onError)}
            />
        case FormType.formFullTopLabelCol:
            return <FormCard
                id={props.id ?? props.formItem.Id}
                labelPosition="top"
                methods={methods}
                options={methodOptions.watch()}
                className={props.className}
                style={props.style}
                cols={cols}
                rels={rels}
                name={props.formItem.Name}
                description={props.formItem.Description}
                onSubmit={methods.handleSubmit(_onSubmit, _onError)}
            />
        case FormType.formWithoutHeaderTopLabelCol:
            return <FormCard
                id={props.id ?? props.formItem.Id}
                hideHeader
                labelPosition="top"
                methods={methods}
                options={methodOptions.watch()}
                className={props.className}
                style={props.style}
                cols={cols}
                rels={rels}
                name={props.formItem.Name}
                description={props.formItem.Description}
                onSubmit={methods.handleSubmit(_onSubmit, _onError)}
            />
        default:
            <div />
    }
}