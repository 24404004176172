import { forwardRef, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { Button, closePopup, Popup, showPopup, Text, TextField, Winicon } from "wini-web-components"
import { BaseDA } from "../../../../../da/baseDA"
import ConfigApi from "../../../../../da/configApi"
import { cssRegex, onFocus } from "../functions/common"
import { ColorPicker } from "../functions/funtions"
import { Ultis } from "../../../../../Utils"

export const IconProperties = ({ selected, onChange }) => {
    const methods = useForm({ shouldFocusError: false })
    const [isOpen, setIsOpen] = useState(true)
    const [isOpenSize, setIsOpenSize] = useState(true)
    const [isOpenColor, setIsOpenColor] = useState(true)
    const [nodeElement, setNode] = useState()
    const ref = useRef()

    useEffect(() => {
        const node = document.body.querySelector(`div[class*="overview-container"] *[id="${selected.Id}"]`)
        if (node.id !== nodeElement?.id) setNode(node)
        if (node) {
            const _nodeStyle = window.getComputedStyle(node)
            methods.setValue('src', selected.Setting.src)
            methods.setValue('size', selected.Setting.size ?? _nodeStyle.getPropertyValue('--size'))
            const _colorValue = _nodeStyle.getPropertyValue('--color')
            let _color = _colorValue.startsWith("#") ? _colorValue : Ultis.rgbToHex(_colorValue)
            if (_color.length < 8) _color += "ff"
            if (_colorValue.startsWith("var")) {
                methods.setValue('color', _color)
                methods.setValue('color-value', _color.substring(0, 7))
                methods.setValue('color-opacity', `${Ultis.hexToPercent(_color.substring(7))}`)
                methods.setValue('opacity', parseFloat(_nodeStyle.opacity))
                methods.setValue('color-id', selected.Setting.style?.color.replace("var(--", "").replace(")", ""))
            } else {
                methods.setValue('color', _color)
                methods.setValue('color-value', _color.substring(0, 7))
                methods.setValue('color-opacity', `${Ultis.hexToPercent(_color.substring(7))}`)
                methods.setValue('opacity', parseFloat(_nodeStyle.opacity))
                methods.setValue('color-id', undefined)
            }
        }
    }, [selected])

    return <>
        <Popup ref={ref} />
        <div className="col" style={{ borderBottom: 'var(--neutral-main-border-color)' }}>
            <div className="row" style={{ padding: '0.8rem 1.2rem', gap: '0.4rem' }}>
                <Text className="semibold1" style={{ flex: 1 }}>Icon</Text>
                <Winicon onClick={() => { setIsOpen(!isOpen) }} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/arrows/${isOpen ? "up" : "down"}-arrow`} />
            </div>
            <div className="col" style={{ padding: '0 1.2rem 0.8rem', gap: '0.8rem', display: isOpen ? 'flex' : 'none' }}>
                <TextField
                    onComplete={(ev) => ev.target.blur()}
                    className="regular1"
                    style={{ padding: "0.6rem 1.2rem", gap: "1.2rem", height: "fit-content" }}
                    name="src"
                    placeholder="src"
                    onFocus={(ev) => { ev.target.select() }}
                    prefix={<Winicon src={methods.watch('src')} size={'1.6rem'} onClick={(ev) => {
                        const _rect = ev.target.closest(".regular1").getBoundingClientRect()
                        showPopup({
                            ref: ref,
                            clickOverlayClosePopup: true,
                            hideButtonClose: true,
                            style: { left: _rect.x, top: _rect.bottom, width: _rect.width },
                            content: <PopupSelectIcon ref={ref} onSelect={(icon) => {
                                onChange({ ...selected, Setting: { ...selected.Setting, src: icon } })
                            }} />
                        })
                    }} />}
                    register={methods.register('src', {
                        onBlur: (ev) => {
                            onChange(['color/', 'fill/', 'outline/'].some(v => ev.target.value.startsWith(v)) ? { ...selected, Setting: { ...selected.Setting, src: ev.target.value } } : { ...selected, Setting: { ...selected.Setting, link: ev.target.value } })
                        }
                    })}
                />
            </div>
        </div>
        <div className="col" style={{ borderBottom: 'var(--neutral-main-border-color)' }}>
            <div className="row" style={{ padding: '0.8rem 1.2rem', gap: '0.4rem' }}>
                <Text className="semibold1" style={{ flex: 1 }}>Size</Text>
                <Winicon onClick={() => { setIsOpenSize(!isOpenSize) }} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/arrows/${isOpenSize ? "up" : "down"}-arrow`} />
            </div>
            <div className="col" style={{ padding: '0 1.2rem 0.8rem', gap: '0.8rem', display: isOpenSize ? 'flex' : 'none' }}>
                <TextField
                    style={{ flex: 1 }}
                    onComplete={(ev) => ev.target.blur()}
                    prefix={<Winicon src="outline/development/3d-29" size='1.2rem' />}
                    suffix={<Winicon src="outline/arrows/down-arrow" size='1.2rem' onClick={(ev) => {
                        const _rect = ev.target.closest(".regular1").getBoundingClientRect()
                        showPopup({
                            ref: ref,
                            clickOverlayClosePopup: true,
                            hideButtonClose: true,
                            style: { left: _rect.x + _rect.width / 2, top: _rect.bottom, width: _rect.width / 2 },
                            content: <div className="col popup-actions" style={{ maxHeight: "fit-content" }}>
                                {["1rem", "1.4rem", "1.6rem", "2rem", "2.4rem", "3.2rem", "4rem", "4.8rem"].map(e => {
                                    return <button key={e} type="button" className="row" onClick={() => {
                                        onChange({ ...selected, Setting: { ...selected.Setting, size: e } })
                                        closePopup(ref)
                                    }}><Text className="button-text-3">{e}</Text></button>
                                })}
                            </div>
                        })
                    }} />}
                    className="regular1"
                    name="size"
                    placeholder="size"
                    onFocus={(ev) => onFocus(ev, methods)}
                    register={methods.register('size', {
                        onBlur: (ev) => {
                            const _newV = ev.target.value.trim().toLowerCase()
                            if (cssRegex.test(_newV)) {
                                let _tmp = { ...selected, Setting: { ...selected.Setting, size: _newV } }
                                if (_newV === "auto" || _newV === "none") delete _tmp.Setting.style.size
                                onChange(_tmp)
                            } else ev.target.value = methods.getValues("_tmp")
                            methods.setValue('_tmp', null)
                        }
                    })}
                />
            </div>
        </div>
        {!methods.watch("src")?.startsWith("color/") && <div className="col" style={{ borderBottom: 'var(--neutral-main-border-color)' }}>
            <div className="row" style={{ padding: '0.8rem 1.2rem', gap: '0.4rem' }}>
                <Text className="semibold1" style={{ flex: 1 }}>Color</Text>
                <Winicon onClick={() => { setIsOpenColor(!isOpenColor) }} size={'1.4rem'} style={{ padding: "0.2rem" }} src={`outline/arrows/${isOpenColor ? "up" : "down"}-arrow`} />
            </div>
            <div className="col" style={{ padding: '0 1.2rem 0.8rem', gap: '0.8rem', display: isOpenColor ? 'flex' : 'none' }}>
                <ColorPicker
                    style={{ width: "100%" }}
                    colorName={'color-value'}
                    colorOpcaityName={'color-opacity'}
                    register={methods.register}
                    opacity={methods.watch("opacity")}
                    colorValue={methods.watch("color")}
                    onChange={({ colorValue, opacity }) => {
                        onChange({ ...selected, Setting: { ...selected.Setting, color: colorValue, style: { ...selected.Setting.style, opacity: opacity } } })
                    }}
                />
            </div>
        </div>}
    </>
}

const PopupSelectIcon = forwardRef(function PopupSelectIcon({ onSelect }, ref) {
    const methods = useForm({ shouldFocusError: false, defaultValues: { color: [], fill: [], outline: [], 'color-open': true, 'fill-open': true, 'outline-open': true } })

    return <div className="col" style={{ width: "100%", backgroundColor: "var(--neutral-absolute-background-color)", borderRadius: "0.8rem" }}>
        <div className="row" style={{ padding: "0.8rem 1.2rem 0.8rem 1.6rem", borderBottom: "var(--neutral-main-border-color)" }}>
            <Text className="semibold2" style={{ flex: 1 }}>Select icon</Text>
            <Winicon size={'2rem'} src={`outline/user interface/e-remove`} onClick={() => { closePopup(ref) }} />
        </div>
        <div className="col" style={{ padding: "0.8rem 1.6rem" }}>
            <div className="row" style={{ gap: "0.8rem" }}>
                <TextField
                    placeholder="Search icon"
                    className="placeholder-2"
                    style={{ padding: "0.5rem 1rem", flex: 1 }}
                    onComplete={(ev) => ev.target.blur()}
                    onBlur={(ev) => {
                        BaseDA.get(ConfigApi.url + 'file/icon-library' + `?type=color&search=${ev.target.value}`).then(res => methods.setValue("color", res))
                        BaseDA.get(ConfigApi.url + 'file/icon-library' + `?type=fill&search=${ev.target.value}`).then(res => methods.setValue("fill", res))
                        BaseDA.get(ConfigApi.url + 'file/icon-library' + `?type=outline&search=${ev.target.value}`).then(res => methods.setValue("outline", res))
                    }}
                />
                <Winicon src="outline/user interface/setup-preferences" />
            </div>
            <div className="col" style={{ gap: "1.2rem", borderBottom: "var(--neutral-main-border-color)", padding: "1.2rem 0" }}>
                <div className="row">
                    <Text className="semibold1" style={{ flex: 1 }}>Color</Text>
                    <Winicon src={`fill/arrows/triangle-${methods.watch("color-open") ? "down" : "up"}`} size={"1.2rem"} onClick={() => { methods.setValue("color-open", !methods.getValues("color-open")) }} />
                </div>
                <div className="row" style={{ flexWrap: "wrap", gap: "1.6rem", display: methods.watch("color-open") ? "flex" : "none" }}>
                    {methods.watch("color").map((e, i) => <div key={e + "-" + i}  className="col4 row" style={{ justifyContent: "center", "--gutter": "1.6rem" }}><Winicon src={e} size={"1.8rem"} onClick={() => { methods.setValue("selected", e) }} /></div>)}
                </div>
            </div>
            <div className="col" style={{ gap: "1.2rem", borderBottom: "var(--neutral-main-border-color)", padding: "1.2rem 0" }}>
                <div className="row">
                    <Text className="semibold1" style={{ flex: 1 }}>Fill</Text>
                    <Winicon src={`fill/arrows/triangle-${methods.watch("fill-open") ? "down" : "up"}`} size={"1.2rem"} onClick={() => { methods.setValue("fill-open", !methods.getValues("fill-open")) }} />
                </div>
                <div className="row" style={{ flexWrap: "wrap", gap: "1.6rem", display: methods.watch("fill-open") ? "flex" : "none" }}>
                    {methods.watch("fill").map((e, i) => <div key={e + "-" + i}  className="col4 row" style={{ justifyContent: "center", "--gutter": "1.6rem" }}><Winicon src={e} size={"1.8rem"} onClick={() => { methods.setValue("selected", e) }} /></div>)}
                </div>
            </div>
            <div className="col" style={{ gap: "1.2rem", padding: "1.2rem 0" }}>
                <div className="row">
                    <Text className="semibold1" style={{ flex: 1 }}>Outline</Text>
                    <Winicon src={`fill/arrows/triangle-${methods.watch("outline-open") ? "down" : "up"}`} size={"1.2rem"} onClick={() => { methods.setValue("outline-open", !methods.getValues("outline-open")) }} />
                </div>
                <div className="row" style={{ flexWrap: "wrap", gap: "1.6rem", display: methods.watch("outline-open") ? "flex" : "none" }}>
                    {methods.watch("outline").map((e, i) => <div key={e + "-" + i}  className="col4 row" style={{ justifyContent: "center", "--gutter": "1.6rem" }}><Winicon src={e} size={"1.8rem"} onClick={() => { methods.setValue("selected", e) }} /></div>)}
                </div>
            </div>
        </div>
        <div className="row" style={{ padding: "0.8rem 1.6rem", borderTop: "var(--neutral-main-border-color)" }}>
            <div style={{ flex: 1 }} />
            <Button
                className={methods.watch("selected")?.length ? "button-primary" : "button-disabled"}
                label="Apply"
                onClick={() => {
                    closePopup(ref)
                    onSelect(methods.getValues("selected"))
                }}
            />
        </div>
    </div>
})